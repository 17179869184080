import { useState } from 'react';
import styled from 'styled-components';

import { Button } from 'components/button';
import { Columns, Column } from 'components/columns';
import DataDisplay from 'components/dataDisplay';
import { Field, Input, useFormikContext } from 'components/formik';
import Icon from 'components/icon';
import { currencyIcon } from 'utils/helpers/miscHelper';
import { currencyFormat, decimalFormat } from 'utils/helpers/numberHelper';

const FormGeneral = (props) => {
  const { appXTenant } = props;
  const {
    tenantName,
    currency,
    isPriceOverride,
    originalMonthlyFee,
    versionNumber
  } = appXTenant;
  const [showDiscountPrice, setShowDiscountPrice] = useState(isPriceOverride);
  const { setFieldValue } = useFormikContext();

  const handleRemoveDiscount = () => {
    setFieldValue('monthlyFee', decimalFormat(originalMonthlyFee));
    setShowDiscountPrice(false);
  };

  return (
    <>
      <DataDisplay label="Tenant">{tenantName}</DataDisplay>
      <Columns>
        <Column span={6}>
          <DataDisplay label="Price">
            {currency} {currencyFormat(originalMonthlyFee, currency)}
          </DataDisplay>
        </Column>
        <Column span={6}>
          <DataDisplay label="Version">{versionNumber}</DataDisplay>
        </Column>
      </Columns>
      {!showDiscountPrice && (
        <StyledAddButton
          onClick={() => setShowDiscountPrice(true)}
          startIcon="add"
          variant="text"
          size="small"
        >
          Add Discount Price
        </StyledAddButton>
      )}
      {showDiscountPrice && (
        <StyledDiscount>
          <Field
            label="Discount Price"
            component={Input}
            name="monthlyFee"
            startIcon={currencyIcon(currency)}
          />
          <Icon icon="trash" onClick={handleRemoveDiscount} />
        </StyledDiscount>
      )}
    </>
  );
};

const StyledAddButton = styled(Button)`
  padding: 8px 0px;
`;

const StyledDiscount = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  > div {
    flex: 1;
  }
`;

export default FormGeneral;
