import { Modal as UIModal } from '@commerce7/admin-ui';

import ModalApiError from './components/ApiError';
import ModalApiLoading from './components/ApiLoading';
import { ModalApiStyles, ModalChildrenStyles } from './components/styles';

const { ModalBody, ModalFooter } = UIModal;

const Modal = (props) => {
  const { children } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <UIModal {...props}>
      <ModalApiStyles>
        <ModalApiLoading />
        <ModalApiError />
      </ModalApiStyles>
      <ModalChildrenStyles>{children}</ModalChildrenStyles>
    </UIModal>
  );
};

export { Modal, ModalBody, ModalFooter };
