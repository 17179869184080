import styled from 'styled-components';

const UserDropdownMenuItem = (props) => {
  const { children } = props;
  return <StyledUserDropdownMenuItem>{children}</StyledUserDropdownMenuItem>;
};

const StyledUserDropdownMenuItem = styled.li`
  > a,
  > button {
    border-radius: ${({ theme }) => theme.c7__ui.borderRadius};
    color: ${({ theme }) => theme.c7__ui.fontColor};
    font-size: ${({ theme }) => theme.c7__ui.fontSizeBase};

    border: none;
    width: 100%;
    cursor: pointer;
    display: flex;
    white-space: nowrap;
    align-items: center;
    padding: 6px 32px 6px 6px;
    column-gap: 15px;
    transition: all 0.2s ease-in-out;
    background: transparent;
    height: 36px;

    &:hover,
    &:focus {
      background: ${({ theme }) => theme.c7__ui.secondaryBackgroundColor};
    }
  }
`;

export default UserDropdownMenuItem;
