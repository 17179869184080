import styled from 'styled-components';

import { useFormikContext } from 'components/formik';
import Text from 'components/text';

import { HOOK_TYPE, OBJECT_TYPE } from '../../../utils/enums';

const Example = () => {
  const { values } = useFormikContext();
  const { objectType, hookType } = values;

  const image = getImage(hookType, objectType);

  return (
    <>
      <StyledText small>Example</StyledText>
      <img src={`/assets/images/displayExamples/${image}.png`} alt={image} />
    </>
  );
};

const getImage = (hookType, objectType) => {
  switch (hookType) {
    case HOOK_TYPE.CONTEXT_MENU:
      if (objectType === OBJECT_TYPE.POS_CART_SUMMARY) {
        return 'contextMenuPOS';
      }
      return 'contextMenu';
    case HOOK_TYPE.TAB_MENU:
      return 'tab';
    case HOOK_TYPE.REPORT:
      return 'reportiFrame';
    case HOOK_TYPE.CARD:
      return 'card';
    case HOOK_TYPE.PAGE:
      if (objectType === OBJECT_TYPE.FRONTEND_PROFILE) {
        return 'pageiFrameFrontend';
      }
      if (
        [
          OBJECT_TYPE.FRONTEND_RECEIPT_PRIMARY,
          OBJECT_TYPE.FRONTEND_RECEIPT_SECONDARY,
          OBJECT_TYPE.FRONTEND_DASHBOARD_PRIMARY,
          OBJECT_TYPE.FRONTEND_DASHBOARD_SECONDARY
        ].includes(objectType)
      ) {
        return 'iFrame';
      }
      return 'pageiFrame';
    case HOOK_TYPE.PRINT_RECEIPT:
      return 'printReceipt';
    default:
      throw new Error('Unexpected hookType');
  }
};

const StyledText = styled(Text)`
  margin-bottom: 8px;
  line-height: 1.15;
`;

export default Example;
