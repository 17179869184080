import styled from 'styled-components';

const ProgressStyles = styled.div`
  position: relative;
  height: 12px;
  margin: 17px 0;
  display: flex;
  align-items: stretch;
  overflow: hidden;

  ${({ theme }) => `
    background: ${theme.c7__ui.secondaryBackgroundColor};
    border: 1px solid ${theme.c7__ui.borderColor};
    border-radius: ${theme.c7__ui.borderRadius};
  `}

  &:after {
    content: '';
    background: ${({ theme }) => theme.c7__ui.borderColor};
    width: ${({ progress }) => `${progress}%`};
    transition: width 0.1s linear;
    height: 100%;
  }
`;

export { ProgressStyles };
