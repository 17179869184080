import DataDisplay from 'components/dataDisplay';
import { Field, Select, useFormikContext } from 'components/formik';

import { useCanEditCurrency } from '../../../../utils/hooks';

const PayoutCurrency = () => {
  const { canEditCurrency } = useCanEditCurrency();
  const { values } = useFormikContext();
  const { currency } = values;

  if (canEditCurrency) {
    return (
      <>
        <Field
          name="currency"
          label="Payout Currency"
          component={Select}
          description="Payouts will be made to this currency using yearly exchange rates"
          options={currencyOptions()}
        />
      </>
    );
  }

  return <DataDisplay label="Payout Currency">{currency}</DataDisplay>;
};

const currencyOptions = () => {
  const array = [
    { value: 'CAD', label: 'CAD' },
    { value: 'USD', label: 'USD' },
    { value: 'AUD', label: 'AUD' },
    { value: 'NZD', label: 'NZD' },
    { value: 'ZAR', label: 'ZAR' },
    { value: 'EUR', label: 'EUR' },
    { value: 'GBP', label: 'GBP' }
  ];
  return array;
};

export default PayoutCurrency;
