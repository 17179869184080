import { useDispatch } from 'react-redux';

import { Grid } from 'components/grid';
import NoRecords from 'components/noRecords';
import { setModal } from 'modal/redux/modalSlice';
import { useGetVersion } from 'version/utils/hooks';

import EditModal from './EditModal';
import AuthCredentials from './components/grid/AuthCredentials';
import { OBJECT_TRANSLATED, ACTION_TRANSLATED } from './utils/enums';
import { useListVersionWebHooks } from './utils/hooks';

const VersionWebHooksGrid = () => {
  const dispatch = useDispatch();
  const { isApproved } = useGetVersion();

  const rowHeaders = [
    { label: 'Object' },
    { label: 'Action' },
    { label: 'Url' },
    { label: 'Auth Credentials' }
  ];

  const rowBody = [
    {
      data: 'object',
      type: 'function',
      func: (object) => OBJECT_TRANSLATED[object]
    },
    {
      data: 'action',
      type: 'function',
      func: (action) => ACTION_TRANSLATED[action]
    },
    {
      data: 'url'
    },
    {
      data: 'username',
      type: 'component',
      props: 'webhook',
      component: AuthCredentials
    }
  ];

  const customNoRecords = (
    <NoRecords
      title="No webhooks found"
      description="Add webhooks for events that your app needs to listen to."
    />
  );

  const handleRowClick = (webhook) => {
    dispatch(setModal(<EditModal webhook={webhook} />));
  };

  return (
    <Grid
      rowHeaders={rowHeaders}
      rowBody={rowBody}
      listHook={useListVersionWebHooks}
      customNoRecords={customNoRecords}
      onRowClick={isApproved ? null : handleRowClick}
    />
  );
};

export default VersionWebHooksGrid;
