const getStats = (build) =>
  build.query({
    query: () => ({
      url: '/stats',
      method: 'GET'
    }),
    providesTags: () => {
      const tags = [{ type: 'Stats' }];
      return tags;
    }
  });

const statsApi = {
  getStats
};
export default statsApi;
