import { useRef } from 'react';
import styled from 'styled-components';

import { ContextMenu, ContextMenuItem } from 'components/contextMenu';
import FileUploader from 'components/fileUploader';
import { useFormikContext } from 'components/formik';
import Text from 'components/text';
import { appFromURL } from 'utils/helpers/apiHelper';

const IconUploader = () => {
  const fileUploaderRef = useRef();
  const { values, setFieldValue } = useFormikContext();
  const { icon } = values.hookData;

  const handleDelete = () => {
    setFieldValue('hookData.icon', '');
  };

  const handleUploadComplete = (data) => {
    const { directory, fileName } = data[0];
    const iconPath = `${directory}${fileName}`;
    setFieldValue('hookData.icon', iconPath);
  };

  const handleUpload = () => {
    fileUploaderRef.current.handleUploadClick();
  };

  return (
    <FileUploader
      fileSchema={fileSchema}
      onUploadComplete={handleUploadComplete}
      ref={fileUploaderRef}
    >
      <StyledLabel small>Icon</StyledLabel>
      <StyledUploader>
        {icon && (
          <StyledImageWrapper>
            <img src={icon} alt="logo" />
          </StyledImageWrapper>
        )}
        <div>
          <ContextMenu>
            {!icon && (
              <StyledContextMenuItem icon="upload" onClick={handleUpload}>
                Upload
              </StyledContextMenuItem>
            )}
            {icon && (
              <>
                <StyledContextMenuItem icon="upload" onClick={handleUpload}>
                  Replace
                </StyledContextMenuItem>
                <StyledContextMenuItem icon="trash" onClick={handleDelete}>
                  Delete
                </StyledContextMenuItem>
              </>
            )}
          </ContextMenu>
          <Text small secondary>
            Must be jpg or png.
          </Text>
        </div>
      </StyledUploader>
    </FileUploader>
  );
};

const StyledLabel = styled(Text)`
  line-height: 1.15;
  margin-bottom: 5px;
`;

const StyledUploader = styled.div`
  display: flex;
`;

const StyledImageWrapper = styled.div`
  width: 50px;
  margin-right: 10px;
  padding: 7px;
  border: 1px solid ${({ theme }) => theme.c7__ui.borderColor};
  border-radius: ${({ theme }) => theme.c7__ui.borderRadius};
`;

const StyledContextMenuItem = styled(ContextMenuItem)`
  height: 20px;
  margin-bottom: 7px;
`;

const fileSchema = {
  fileType: 'image',
  allowableMimeTypes: [
    'image/jpeg',
    'image/pjpeg',
    'image/png',
    'image/svg+xml'
  ],
  outputs: [
    {
      key: 'icon',
      destination: `${appFromURL()}/images/icon/`,
      height: 256,
      width: 256,
      resize: 'ratio'
    },
    { key: 'original', destination: `${appFromURL()}/images/original/` }
  ]
};

export default IconUploader;
