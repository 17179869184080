import { useState, useEffect } from 'react';

import {
  FilterHeaderStyles,
  FilterHeaderButton,
  FilterApplyButtonStyles,
  FilterRadioStyles
} from './styles';

const FilterByRadio = (props) => {
  const { value = '', filter, onChange } = props;
  const { options } = filter.value;
  const { key } = filter.key;
  const [checkedFilter, setCheckedFilter] = useState(getValue(value));

  useEffect(() => {
    setCheckedFilter(getValue(value));
  }, [value]);

  const handleClearFilter = (e) => {
    e.preventDefault();
    setCheckedFilter(null);
  };

  const handleOnChange = (option) => {
    setCheckedFilter(option);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onChange(key, checkedFilter);
  };

  return (
    <>
      <FilterHeaderStyles>
        <FilterHeaderButton onClick={handleClearFilter}>
          Clear filter
        </FilterHeaderButton>
      </FilterHeaderStyles>
      {options.map((filterOption) => {
        const checked = checkedFilter === filterOption.value;
        return (
          <FilterRadioStyles
            key={filterOption.value}
            id={`${key}-${filterOption.value}`}
            checked={checked}
            label={filterOption.label}
            onChange={() => handleOnChange(filterOption.value)}
          />
        );
      })}
      <FilterApplyButtonStyles onClick={handleSubmit}>
        Apply Filter
      </FilterApplyButtonStyles>
    </>
  );
};

const getValue = (value) => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value;
};
export default FilterByRadio;
