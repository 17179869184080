import styled from 'styled-components';

import Heading from 'components/heading';

import ApiError from './api/ApiError';
import { ApiErrorStyles } from './api/styles';

const LoginLayout = (props) => {
  const { children, title, className } = props;
  return (
    <PageStyles className={className}>
      <LogoWrapperStyles>
        <LogoStyles src="/assets/images/commerce7-logo.svg" alt="Commerce7" />
      </LogoWrapperStyles>
      <LoginStyles>
        <LoginContentStyles>
          <HeadingStyles>{title}</HeadingStyles>
          <ApiErrorStyles>
            <ApiError />
          </ApiErrorStyles>
          {children}
        </LoginContentStyles>
      </LoginStyles>
    </PageStyles>
  );
};

const PageStyles = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  padding: 105px 0 50px 0;
  background-image: url(/assets/images/shapes-login.svg);
  background-color: #20272b;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: left bottom;
`;

const LogoWrapperStyles = styled.div`
  margin: 0 auto 45px;
  text-align: center;
  ${({ theme }) => `
    @media ${theme.c7__ui.breakpoints.mediumUp} {
      margin: 0 auto 90px;
    }
  `}
`;

const LogoStyles = styled.img`
  max-width: 225px;
`;

const LoginStyles = styled.div`
  padding: 0;
  margin: auto;
  width: 575px;
  max-width: 90%;
  border-radius: 5px;
  background-color: ${({ theme }) =>
    theme.c7__ui.mode === 'light' ? 'rgb(255, 255, 255)' : '#293238'};
`;

const LoginContentStyles = styled.div`
  padding: 40px 50px 50px 50px;

  ${({ theme }) => `
  @media ${theme.c7__ui.breakpoints.smallOnly} {
    padding: 30px 25px 25px 25px;
  }
`}
`;

const HeadingStyles = styled(Heading)`
  margin-bottom: 35px;
`;

export default LoginLayout;
