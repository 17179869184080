export const APP_STATUS = {
  DEVELOPMENT: 'DEVELOPMENT',
  LIVE: 'LIVE'
};

export const APP_STATUS_TRANSLATED = {
  DEVELOPMENT: 'In Development',
  LIVE: 'Live'
};

export const TYPE = {
  APP: 'APP',
  INTEGRATION: 'INTEGRATION',
  PRIVATE: 'PRIVATE'
};

export const TYPE_TRANSLATED = {
  APP: 'Full Application',
  INTEGRATION: 'Integration',
  PRIVATE: 'Private App'
};

export const PAYMENT_HANDLED_BY = {
  COMMERCE7: 'COMMERCE7',
  EXTERNAL: 'EXTERNAL'
};

export const PAYMENT_HANDLED_BY_TRANSLATED_FOR_DB = {
  COMMERCE7: 'Commerce7',
  EXTERNAL: 'External'
};
