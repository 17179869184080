import { SearchTools } from 'components/grid';
import Heading from 'components/heading';
import { Page, PageContent } from 'components/page';
import { Pagination } from 'components/pagination';
import TitleAction from 'components/titleAction';
import { useListVersions } from 'version/utils/hooks';

import Grid from './components/list/Grid';
import { filters } from './utils/filters';
import { useListAppXTenants } from './utils/hooks';

const List = () => {
  const { data: versions, isLoading } = useListVersions();

  if (isLoading) return null;

  const versionNumbers = versions.map((version) => version.versionNumber);

  return (
    <Page title="Installs">
      <PageContent>
        <TitleAction>
          <Heading level={1}>Installs</Heading>
        </TitleAction>
        <SearchTools label="by tenant" filters={filters(versionNumbers)} />
        <Grid />
        <Pagination listHook={useListAppXTenants} />
      </PageContent>
    </Page>
  );
};

export default List;
