import styled from 'styled-components';

import useQuery from 'utils/hooks/useQuery';
import useWindowSize from 'utils/hooks/useWindowSize';

import Filter from './Filter';
import HiddenFilters from './HiddenFilters';

const Filters = (props) => {
  const { filters, onFilter } = props;
  const { width } = useWindowSize();
  const query = useQuery();

  const handleOnChange = (key, value) => onFilter({ key, value });

  if (!filters) {
    return null;
  }

  const filterKeys = Object.keys(filters);
  const maxFiltersToShow = getMaxFiltersToShow(width);

  const { visibleFilterKeys, hiddenFilterKeys } = getFilterKeys(
    filterKeys,
    maxFiltersToShow
  );

  return (
    <FiltersWrapperStyles>
      {visibleFilterKeys.map((filterKey) => {
        const filter = filters[filterKey];
        const value = query[filter.key.key] || '';
        return (
          <Filter
            key={filterKey}
            filter={filter}
            value={value}
            onChange={handleOnChange}
          />
        );
      })}
      {hiddenFilterKeys.length > 0 && (
        <HiddenFilters
          hiddenFilterKeys={hiddenFilterKeys}
          visibleFilterKeys={visibleFilterKeys}
          filters={filters}
          onChange={handleOnChange}
        />
      )}
    </FiltersWrapperStyles>
  );
};

const XLARGE = 1536;
const LARGE = 1280;

const getMaxFiltersToShow = (windowWidth) => {
  if (windowWidth >= XLARGE) return 2;
  if (windowWidth >= LARGE) return 1;
  return 0;
};

const getFilterKeys = (filterKeys, maxFiltersToShow) => {
  const visibleFilterKeys = [];
  let hiddenFilterKeys = [];

  filterKeys.forEach((filterKey, index) => {
    if (index < maxFiltersToShow) visibleFilterKeys.push(filterKey);
    else hiddenFilterKeys.push(filterKey);
  });

  if (hiddenFilterKeys.length === 1) {
    visibleFilterKeys.push(hiddenFilterKeys[0]);
    hiddenFilterKeys = [];
  }
  return { hiddenFilterKeys, visibleFilterKeys };
};

const FiltersWrapperStyles = styled.div`
  border-radius: ${({ theme }) => theme.c7__ui.borderRadius};
  border: 1px solid ${({ theme }) => theme.c7__ui.borderColor};
  background: ${({ theme }) => theme.c7__ui.backgroundColor};
  display: flex;
  transition: all 0.3s ease-in-out;
  width: 100%;
  @media ${({ theme }) => theme.c7__ui.breakpoints.mediumUp} {
    width: auto;
  }
`;

export default Filters;
