import styled from 'styled-components';

import Heading from 'components/heading';
import Text from 'components/text';
import { useGetVersion } from 'version/utils/hooks';

import AddForm from './components/AddForm';
import List from './components/List';

const TestTenants = () => {
  const { isApproved, isLoading } = useGetVersion();

  if (isApproved || isLoading) return null;

  return (
    <>
      <StyledHeading level={3}>Test Your App</StyledHeading>
      <Text secondary>
        Your app will display in the App Store for any tenants added below. You
        will need to be an Admin Owner on the tenant to install it.
      </Text>
      <AddForm />
      <List />
    </>
  );
};

const StyledHeading = styled(Heading)`
  margin-bottom: 5px;
`;

export default TestTenants;
