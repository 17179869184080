import { validateForUI } from 'utils/helpers/jsonValidator';

const createPrivateTenantSchema = {
  type: 'object',
  properties: {
    tenantId: { type: 'string' }
  },
  required: ['tenantId'],
  additionalProperties: false
};

export const validateAddPrivateTenant = (params) => {
  const errors = validateForUI(createPrivateTenantSchema, params);
  return errors;
};
