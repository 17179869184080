import styled from 'styled-components';

import Icon from 'components/icon';
import Text from 'components/text';

const TitleStyles = styled(Text)`
  margin-bottom: 5px;
  display: block;
`;

const DescriptionStyles = styled(Text)`
  margin-bottom: 20px;

  @media ${({ theme }) => theme.c7__ui.breakpoints.mediumUp} {
    padding-right: 20px;
    margin-bottom: 0;
  }

  * {
    font-size: ${({ theme }) => theme.c7__ui.fontSizeBase};
  }
`;

const LinkToDocsStyles = styled.a`
  display: inline-flex;
  align-items: start;
`;

const LinkToDocsIconStyles = styled(Icon)`
  ${({ theme }) => `
    transform: translateY(3px);
    margin-left: 2px;
    path {
      fill: ${theme.mode === 'light' ? `#00679d` : `#0192d0`};
    }
  `}
`;

export {
  TitleStyles,
  DescriptionStyles,
  LinkToDocsStyles,
  LinkToDocsIconStyles
};
