import styled from 'styled-components';

import { useGetApp } from 'app/utils/hooks';
import Breadcrumb from 'components/breadcrumb';
import { FormDescription, LinkToDocs } from 'components/formInstructions';
import Heading from 'components/heading';
import { Page, PageContent, PageSidebar } from 'components/page';

import EditForm from './components/edit/EditForm';
import { useGetAppTempListing } from './utils/hooks';

const Edit = () => {
  const { app, isLoading: appLoading } = useGetApp();
  const { isLoading: listingLoading, created } = useGetAppTempListing();

  if (appLoading || listingLoading) return null;

  let title = 'Create Listing';
  if (created) {
    title = 'Edit Listing';
  }

  const breadcrumbs = [{ to: '/', label: app.title }, { label: 'Listing' }];

  return (
    <Page title="Create Listing">
      <PageContent>
        <Breadcrumb breadcrumbs={breadcrumbs} />
        <Heading level={1}>{title}</Heading>
        <StyledFormDescription>
          Add details for the App Store. After reading your listing, customers
          should have a clear understanding of what your app is for, how it
          works, and why they should install it. Treat this as your chance to
          give your best sales pitch.{' '}
          <LinkToDocs url="https://developer.commerce7.com/docs/create-yourapp-listing" />{' '}
        </StyledFormDescription>
        <EditForm />
      </PageContent>
      <PageSidebar isEmpty />
    </Page>
  );
};

const StyledFormDescription = styled(FormDescription)`
  margin-bottom: 30px;
`;

export default Edit;
