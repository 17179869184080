import { BrowserRouter, Routes, Route } from 'react-router-dom';

import NotFound from '404/NotFound';
import AppAdd from 'app/Add';
import AppView from 'app/View';
import EditListing from 'appTempListing/Edit';
import AppXAccountList from 'appXAccount/List';
import { Layout, DashboardLayout } from 'components/layout';
import DashboardView from 'dashboard/View';
import Login from 'login/Login';
import Logout from 'login/Logout';
import VersionRoutes from 'routes/versionRoutes';
import Support from 'support/Support';
import { appFromURL } from 'utils/helpers/apiHelper';

import AuthenticatedRoute from './AuthenticatedRoute';
import BillingRoutes from './routes/billingRoutes';
import InstallRoutes from './routes/installRoutes';

const DevCenterRoutes = () => {
  const appId = appFromURL();

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<AuthenticatedRoute />}>
          {getAuthRoutes(appId).map((route) => {
            const { element: Element, layout = Layout } = route;
            const LayoutComponent = layout;
            return (
              <Route
                key={route.path}
                exact={route.exact}
                path={route.path}
                element={
                  <LayoutComponent>
                    <Element />
                  </LayoutComponent>
                }
              />
            );
          })}
        </Route>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/logout" element={<Logout />} />
      </Routes>
    </BrowserRouter>
  );
};

const getAuthRoutes = (appId) => {
  const authRoutes = [
    {
      path: '/',
      exact: true,
      element: appFromURL() ? AppView : DashboardView,
      layout: appFromURL() ? Layout : DashboardLayout
    },
    { path: '/add', exact: true, element: AppAdd, layout: DashboardLayout },
    { path: '/account', exact: true, element: AppXAccountList },
    { path: '/listing/:id', exact: true, element: EditListing },
    { path: '/support', exact: true, element: Support },
    { path: '/version/:id/*', exact: true, element: VersionRoutes }
  ];
  if (appId) {
    authRoutes.push(
      { path: '/billing/*', exact: false, element: BillingRoutes },
      { path: '/install/*', exact: true, element: InstallRoutes }
    );
  }
  authRoutes.push({
    path: '*',
    element: NotFound,
    layout: DashboardLayout
  });
  return authRoutes;
};

export default DevCenterRoutes;
