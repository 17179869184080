import { useEscKeydown } from '@commerce7/frontend-utils';
import { useRef, useState } from 'react';
import styled from 'styled-components';

import useOnClickOutside from 'utils/hooks/useOnClickOutside';

import About from './userDropdown/About';
import Admin from './userDropdown/Admin';
import DarkModeToggle from './userDropdown/DarkModeToggle';
import SignOut from './userDropdown/SignOut';
import UserAvatar from './userDropdown/UserAvatar';
import UserDropdownMenu from './userDropdown/UserDropdownMenu';

const UserDropdown = () => {
  const userDropdownRef = useRef();
  const [isDropdownVisible, toggleDropdownVisibility] = useState(false);

  useOnClickOutside(userDropdownRef, () => toggleDropdownVisibility(false));
  useEscKeydown(() => toggleDropdownVisibility(false));

  return (
    <UserDropdownWrapperStyles ref={userDropdownRef}>
      <UserAvatar
        toggleDropdownVisibility={toggleDropdownVisibility}
        isDropdownVisible={isDropdownVisible}
      />
      <UserDropdownMenu visible={isDropdownVisible}>
        <Admin />
        <About />
        <SignOut />
        <DarkModeToggle />
      </UserDropdownMenu>
    </UserDropdownWrapperStyles>
  );
};

const UserDropdownWrapperStyles = styled.div`
  position: relative;
`;

export default UserDropdown;
