export const OBJECT_TYPE = {
  ORDER_LIST: 'ORDER_LIST',
  ORDER_DETAIL: 'ORDER_DETAIL',
  PRODUCT_LIST: 'PRODUCT_LIST',
  PRODUCT_DETAIL: 'PRODUCT_DETAIL',
  CLUB_MEMBERSHIP_DETAIL: 'CLUB_MEMBERSHIP_DETAIL',
  CUSTOMER_LIST: 'CUSTOMER_LIST',
  CUSTOMER_DETAIL: 'CUSTOMER_DETAIL',
  POS_CART_SUMMARY: 'POS_CART_SUMMARY',
  ORDER_REPORTS: 'ORDER_REPORTS',
  FINANCE_REPORTS: 'FINANCE_REPORTS',
  CUSTOMER_REPORTS: 'CUSTOMER_REPORTS',
  CLUB_REPORTS: 'CLUB_REPORTS',
  INVENTORY_REPORTS: 'INVENTORY_REPORTS',
  MARKETING_MENU: 'MARKETING_MENU',
  STORE_MENU: 'STORE_MENU',
  REPORT_MENU: 'REPORT_MENU',
  POS_MENU: 'POS_MENU',
  RESERVATION_MENU: 'RESERVATION_MENU',
  FRONTEND_RECEIPT_PRIMARY: 'FRONTEND_RECEIPT_PRIMARY',
  FRONTEND_RECEIPT_SECONDARY: 'FRONTEND_RECEIPT_SECONDARY',
  FRONTEND_PROFILE: 'FRONTEND_PROFILE',
  FRONTEND_DASHBOARD_PRIMARY: 'FRONTEND_DASHBOARD_PRIMARY',
  FRONTEND_DASHBOARD_SECONDARY: 'FRONTEND_DASHBOARD_SECONDARY',
  CART_DETAIL: 'CART_DETAIL',
  CORPORATE_ORDER_DETAIL: 'CORPORATE_ORDER_DETAIL',
  POS_PRINTER_RECEIPT: 'POS_PRINTER_RECEIPT',
  POS_PRINT_TICKET: 'POS_PRINT_TICKET'
};

export const OBJECT_TYPE_TRANSLATED = {
  ORDER_LIST: 'Order List',
  ORDER_DETAIL: 'Order Detail',
  PRODUCT_LIST: 'Product List',
  PRODUCT_DETAIL: 'Product Detail',
  CLUB_MEMBERSHIP_DETAIL: 'Club Membership Detail',
  CUSTOMER_LIST: 'Customer List',
  CUSTOMER_DETAIL: 'Customer Detail',
  POS_CART_SUMMARY: 'POS Cart Summary',
  ORDER_REPORTS: 'Order Reports',
  FINANCE_REPORTS: 'Finance Reports',
  CUSTOMER_REPORTS: 'Customer Reports',
  CLUB_REPORTS: 'Club Reports',
  INVENTORY_REPORTS: 'Inventory Reports',
  MARKETING_MENU: 'Marketing Menu',
  STORE_MENU: 'Store Menu',
  REPORT_MENU: 'Report Menu',
  POS_MENU: 'POS Menu',
  RESERVATION_MENU: 'Reservation Menu',
  FRONTEND_RECEIPT_PRIMARY: 'Frontend Receipt Primary',
  FRONTEND_RECEIPT_SECONDARY: 'Frontend Receipt Secondary',
  FRONTEND_PROFILE: 'Frontend Profile',
  FRONTEND_DASHBOARD_PRIMARY: 'Frontend Dashboard Primary',
  FRONTEND_DASHBOARD_SECONDARY: 'Frontend Dashboard Secondary',
  CART_DETAIL: 'Cart Detail',
  CORPORATE_ORDER_DETAIL: 'Corporate Order Detail',
  POS_PRINTER_RECEIPT: 'POS Print Receipt',
  POS_PRINT_TICKET: 'POS Print Ticket'
};

export const HOOK_TYPE = {
  CONTEXT_MENU: 'CONTEXT_MENU',
  TAB_MENU: 'TAB_MENU',
  REPORT: 'REPORT',
  CARD: 'CARD',
  PAGE: 'PAGE',
  PRINT_RECEIPT: 'PRINT_RECEIPT'
};

export const HOOK_TYPE_TRANSLATED = {
  CONTEXT_MENU: 'Context Menu',
  TAB_MENU: 'Tab Menu',
  REPORT: 'Report',
  CARD: 'Card',
  PAGE: 'Page',
  PRINT_RECEIPT: 'Print Receipt'
};
