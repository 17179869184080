import { Page, PageContent, PageSidebar } from 'components/page';
import TestTenants from 'versionTestTenant/TestTenants';

import VersionHeader from './components/common/Header';
import SettingsList from './components/settings/List';

const Settings = () => (
  <Page title="Version Settings">
    <PageContent>
      <VersionHeader />
      <SettingsList />
    </PageContent>
    <PageSidebar>
      <TestTenants />
    </PageSidebar>
  </Page>
);

export default Settings;
