import { Stepper } from '@commerce7/admin-ui';
import { NavLink } from 'react-router-dom';

const { Step: UIStep } = Stepper;

const Step = (props) => {
  const { to, ...rest } = props;

  const customProps = {};
  if (to) {
    customProps.component = NavLink;
    customProps.to = to;
  }

  return (
    <UIStep
      // eslint-disable-next-line
      {...customProps}
      // eslint-disable-next-line
      {...rest}
    />
  );
};

export default Step;
