export const TOOLBAR =
  'styleselect | cut copy paste | bold italic | alignleft aligncenter alignright | bullist numlist | link image | hr | code';
export const PLUGINS =
  'code link hr image lists media paste print preview table template';

export const DARK_CONTENT = 'commerce7-dark';
export const LIGHT_CONTENT = 'commerce7-light';

export const DARK_SKIN = 'commerce7-dark';
export const LIGHT_SKIN = 'commerce7-light';

export const DEFAULT_EDITOR_HEIGHT = 500;
