import { OBJECT_TYPE, OBJECT_TYPE_TRANSLATED } from './enums';

export const transformData = (values) => {
  const data = {
    objectType: values.objectType,
    hookType: values.hookType,
    hookData: {}
  };

  if (values.hookData.icon) {
    data.hookData.icon = values.hookData.icon;
  }
  if (values.hookData.contextMenuType) {
    data.hookData.contextMenuType = values.hookData.contextMenuType;
  }
  if (values.hookData.url) {
    data.hookData.url = values.hookData.url;
  }
  if (values.hookData.title) {
    data.hookData.title = values.hookData.title;
  }
  if (values.hookData.iFrameHeight) {
    data.hookData.iFrameHeight = values.hookData.iFrameHeight;
  }
  return data;
};

export const getObjectTypeOptions = () => {
  const groups = ['Page', 'Page Embed', 'Report', 'Frontend V2', 'POS'];
  const options = [];

  groups.forEach((group) => {
    const groupOptions = {
      label: group,
      options: []
    };
    const objectTypes = getObjectTypesForGroup(group);
    objectTypes.forEach((objectType) => {
      groupOptions.options.push({
        label: OBJECT_TYPE_TRANSLATED[objectType],
        value: objectType
      });
    });
    options.push(groupOptions);
  });

  return options;
};

export const getObjectTypesForGroup = (group) => {
  switch (group) {
    case 'Page':
      return [
        OBJECT_TYPE.MARKETING_MENU,
        OBJECT_TYPE.STORE_MENU,
        OBJECT_TYPE.REPORT_MENU,
        OBJECT_TYPE.POS_MENU,
        OBJECT_TYPE.RESERVATION_MENU
      ];
    case 'Page Embed':
      return [
        OBJECT_TYPE.ORDER_LIST,
        OBJECT_TYPE.ORDER_DETAIL,
        OBJECT_TYPE.CART_DETAIL,
        OBJECT_TYPE.PRODUCT_LIST,
        OBJECT_TYPE.PRODUCT_DETAIL,
        OBJECT_TYPE.CUSTOMER_DETAIL,
        OBJECT_TYPE.CORPORATE_ORDER_DETAIL,
        OBJECT_TYPE.CLUB_MEMBERSHIP_DETAIL
      ];
    case 'Report':
      return [
        OBJECT_TYPE.ORDER_REPORTS,
        OBJECT_TYPE.FINANCE_REPORTS,
        OBJECT_TYPE.CUSTOMER_REPORTS,
        OBJECT_TYPE.CLUB_REPORTS,
        OBJECT_TYPE.INVENTORY_REPORTS
      ];
    case 'Frontend V2':
      return [
        OBJECT_TYPE.FRONTEND_PROFILE,
        OBJECT_TYPE.FRONTEND_RECEIPT_PRIMARY,
        OBJECT_TYPE.FRONTEND_RECEIPT_SECONDARY,
        OBJECT_TYPE.FRONTEND_DASHBOARD_PRIMARY,
        OBJECT_TYPE.FRONTEND_DASHBOARD_SECONDARY
      ];
    case 'POS':
      return [
        OBJECT_TYPE.POS_CART_SUMMARY,
        OBJECT_TYPE.POS_PRINTER_RECEIPT,
        OBJECT_TYPE.POS_PRINT_TICKET
      ];
    default:
      throw new Error('Unexpected group');
  }
};
