import { validateForUI } from 'utils/helpers/jsonValidator';

import { OBJECT_TYPE, REQUEST_TYPE } from './enums';

const properties = {
  objectType: {
    type: 'string',
    enum: Object.keys(OBJECT_TYPE)
  },
  requestType: { type: 'string', enum: Object.keys(REQUEST_TYPE) }
};

const versionSecurityBulkUpdateSchema = {
  type: 'array',
  items: {
    type: 'object',
    properties,
    required: ['objectType', 'requestType'],
    additionalProperties: false
  },
  uniqueItems: true,
  minItems: 0
};

export const validateBulkUpdate = (params) =>
  validateForUI(
    versionSecurityBulkUpdateSchema,
    params,
    customValidate(params)
  );

const customValidate = (params) => {
  const errorArray = [];

  if (Array.isArray(params)) {
    const objectTypeArray = [];
    let index = 0;
    params.forEach((securityItem) => {
      if (objectTypeArray.includes(securityItem.objectType)) {
        const error = {
          field: `objectType[${index}]`,
          message: `${securityItem.objectType} can only be in the array one time`
        };
        errorArray.push(error);
      }
      index += 1;
      objectTypeArray.push(securityItem.objectType);
    });
  }

  return errorArray;
};
