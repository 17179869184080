import { useListBillingsQuery } from 'api';
import useQuery from 'utils/hooks/useQuery';

export const useListBillings = () => {
  const query = useQuery();
  const { data: billings, isLoading, error } = useListBillingsQuery(query);
  return {
    data: billings?.data,
    total: billings?.total,
    error,
    isLoading
  };
};
