import styled from 'styled-components';

const TitleAction = (props) => {
  const { children, className } = props;
  return <StyledHeading className={className}>{children}</StyledHeading>;
};

const StyledHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0 20px 0;

  h1,
  h2 {
    margin: 0;
  }
`;

export default TitleAction;
