import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import {
  FormItemWrapperStyles,
  FormItemLabelStyles,
  FormItemErrorStyles,
  FormItemRequiredStyles
} from '../common/styles';

import { TOOLBAR, PLUGINS, DEFAULT_EDITOR_HEIGHT } from './utils/constants';
import { getTinyMceStyles } from './utils/helpers';

const Html = (props) => {
  const themeContext = useContext(ThemeContext);

  const {
    errorMessage,
    id,
    disabled,
    label,
    height,
    onBlur,
    onChange,
    placeholder,
    required,
    value,
    tinymceConfig
  } = props;

  const handleEditorChange = (content) => {
    onChange({ target: { value: content, id, name: id } });
  };

  const handleBlur = (e) => {
    if (onBlur) {
      onBlur({ target: { value: e.target.getContent(), id, name: id } });
    }
  };

  const styles = getTinyMceStyles(themeContext.c7__ui.mode);

  return (
    <FormItemWrapperStyles>
      {label && (
        <FormItemLabelStyles>
          {label} {required && <FormItemRequiredStyles />}
        </FormItemLabelStyles>
      )}
      <Editor
        id={id}
        tinymceScriptSrc="/assets/tinymce/tinymce.min.js"
        textareaName={id}
        disabled={disabled}
        value={value}
        height={height}
        onEditorChange={handleEditorChange}
        onBlur={handleBlur}
        init={{
          convert_urls: false,
          plugins: PLUGINS,
          height,
          skin: styles.skin,
          content_css: styles.content,
          content_style: tinymceConfig.content_style,
          menubar: false,
          branding: false,
          statusbar: false,
          toolbar: tinymceConfig.toolbar,
          placeholder,
          valid_children: '+body[style]'
        }}
      />
      {errorMessage && (
        <FormItemErrorStyles>{errorMessage}</FormItemErrorStyles>
      )}
    </FormItemWrapperStyles>
  );
};

Html.defaultProps = {
  disabled: false,
  errorMessage: null,
  id: null,
  label: null,
  onBlur: () => null,
  onChange: null,
  height: DEFAULT_EDITOR_HEIGHT,
  placeholder: null,
  required: false,
  tinymceConfig: {
    toolbar: TOOLBAR,
    content_style: null
  }
};

Html.propTypes = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string,
  label: PropTypes.string,
  height: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string.isRequired,
  tinymceConfig: PropTypes.shape({
    toolbar: PropTypes.string,
    content_style: PropTypes.string
  })
};

export default Html;
