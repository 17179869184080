import { PAYMENT_HANDLED_BY } from 'app/utils/enums';
import { useGetApp } from 'app/utils/hooks';
import Text from 'components/text';
import { currencyFormat } from 'utils/helpers/numberHelper';

const Price = (props) => {
  const { version } = props;
  const { monthlyFee } = version;
  const { app } = useGetApp();
  const { currency, paymentHandledBy } = app;

  if (paymentHandledBy === PAYMENT_HANDLED_BY.EXTERNAL) {
    return <Text>Custom</Text>;
  }

  return <Text>{currencyFormat(monthlyFee, currency)}</Text>;
};

export default Price;
