import { useState, useEffect } from 'react';

import {
  Field,
  Input,
  useFormikContext,
  HiddenPassword
} from 'components/formik';

const Password = (props) => {
  const { setUpdateInstallCredentials } = props;
  const formikContext = useFormikContext();

  const { values, initialValues } = formikContext;

  const hasUsername = !!values.username;
  const [showPasswordInput, setShowPasswordInput] = useState(!hasUsername);

  const usernameChanged = initialValues.username !== values.username;

  useEffect(() => {
    if (setUpdateInstallCredentials) {
      if (usernameChanged || showPasswordInput) {
        setUpdateInstallCredentials(true);
      }
    }
  }, [usernameChanged, showPasswordInput, setUpdateInstallCredentials]);

  return (
    <>
      {(showPasswordInput || usernameChanged) && (
        <Field
          name="password"
          label="Password (Optional)"
          component={Input}
          type="password"
        />
      )}
      {!showPasswordInput && !usernameChanged && (
        <HiddenPassword onEdit={() => setShowPasswordInput(true)} />
      )}
    </>
  );
};

export default Password;
