import { dateFormatDateOnly, firstDayOfMonth } from 'utils/helpers/dateHelper';

export const filters = {
  invoiceDate: {
    type: 'date',
    key: {
      label: 'Invoice Date',
      key: 'invoiceDate'
    },
    value: {
      operators: [
        'Greater Than or Equal To',
        'Less Than or Equal To',
        'Between'
      ],
      default: `${dateFormatDateOnly(firstDayOfMonth())}`,
      type: 'date'
    }
  }
};
