import { Table as UITable } from '@commerce7/admin-ui';

import DraggableTr from './DraggableTr';

const { Thead, Tbody, Th, Tr, Td, Tfoot } = UITable;

const Table = (props) => {
  const { children } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <UITable {...props}>{children}</UITable>;
};

export {
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  //   Tsummary,
  Tfoot,
  DraggableTr
  //   ErrorTr
};
