import { useState } from 'react';
import styled from 'styled-components';

import { useCreateVersionTestTenantMutation } from 'api';
import { Button } from 'components/button';
import { Formik, Field, Input } from 'components/formik';
import { useGetVersion } from 'version/utils/hooks';

import { validateCreate } from '../utils/validators';

const AddForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [createVersionTestTenant] = useCreateVersionTestTenantMutation();
  const { version } = useGetVersion();

  const handleSubmit = async (values) => {
    const data = transformData(values);
    setSubmitting(true);
    await createVersionTestTenant({
      data,
      versionId: version.id
    });
    setSubmitting(false);
  };

  return (
    <StyledAddForm>
      <Formik
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={getInitialValues()}
      >
        <StyledInput>
          <Field
            label="Tenant ID"
            component={Input}
            name="testTenantId"
            placeholder="spectra-winery"
          />
          <Button type="submit" variant="secondary" loading={submitting}>
            Add
          </Button>
        </StyledInput>
      </Formik>
    </StyledAddForm>
  );
};

const transformData = (values) => {
  const data = {
    testTenantId: values.testTenantId
  };
  return data;
};

const validate = (values) => {
  const data = transformData(values);
  const errors = validateCreate(data);
  return errors;
};

const getInitialValues = () => {
  const initialValues = {
    testTenantId: ''
  };
  return initialValues;
};

const StyledAddForm = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.c7__ui.borderColor};
`;

const StyledInput = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 400px;

  > div {
    flex: 1;
    margin-right: 10px;
  }

  > button {
    margin-top: 21px;
  }
`;

export default AddForm;
