import { useNavigate, useParams } from 'react-router-dom';

import { useUpdateAppTempListingMutation } from 'api';
import { Formik, FormFooter } from 'components/formik';

import { useGetAppTempListing } from '../../utils/hooks';
import { validateUpdate } from '../../utils/validators';

import FormGeneral from './editForm/FormGeneral';

const EditForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [updateAppTempListing] = useUpdateAppTempListingMutation();

  const { appTempListing } = useGetAppTempListing();

  const handleSubmit = async (values, formik) => {
    const data = transformData(values);
    const { error } = await updateAppTempListing({
      appTempListingId: params.id,
      data
    });
    formik.setSubmitting(false);
    if (!error) {
      navigate('/');
    }
  };

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={getInitialValues(appTempListing)}
    >
      <FormGeneral />
      <FormFooter saveButtonText="Save Listing" onCancel={handleCancel} />
    </Formik>
  );
};

const transformData = (values) => {
  const data = {
    isSubscriptionToAnotherSoftwareVendorRequired:
      values.isSubscriptionToAnotherSoftwareVendorRequired,
    category: values.category,
    title: values.title,
    screenShots: [],
    salesDescription: values.salesDescription || null,
    description: values.description,
    supportPhone: values.supportPhone,
    instructions: values.instructions,
    websiteUrl: values.websiteUrl || null,
    documentationUrl: values.documentationUrl || null,
    supportEmail: values.supportEmail || null,
    logo: values.logo || null,
    darkModeLogo: values.darkModeLogo || null
  };
  values.screenShots.forEach((screenShot) => {
    if (screenShot.image) {
      data.screenShots.push({
        image: screenShot.image,
        caption: screenShot.caption
      });
    }
  });

  return data;
};

const validate = (values) => {
  const data = transformData(values);
  const errors = validateUpdate(data);
  return errors;
};

const getInitialValues = (appTempListing) => {
  let { screenShots } = appTempListing;
  if (!screenShots || screenShots === '[]') {
    screenShots = [];
  }
  const initialValues = {
    title: appTempListing.title,
    category: appTempListing.category || 'Other',
    logo: appTempListing.logo,
    darkModeLogo: appTempListing.darkModeLogo,
    salesDescription: appTempListing.salesDescription,
    websiteUrl: appTempListing.websiteUrl,
    description: appTempListing.description,
    screenShots,
    supportEmail: appTempListing.supportEmail,
    supportPhone: appTempListing.supportPhone,
    documentationUrl: appTempListing.documentationUrl,
    instructions: appTempListing.instructions,
    isSubscriptionToAnotherSoftwareVendorRequired:
      appTempListing.isSubscriptionToAnotherSoftwareVendorRequired
  };
  return initialValues;
};

export default EditForm;
