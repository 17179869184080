import { Column } from 'components/columns';
import Heading from 'components/heading';
import TitleAction from 'components/titleAction';
import PrivateTenants from 'privateTenant/PrivateTenants';
import Grid from 'version/Grid';

import AddVersionButton from './versions/AddVersionButton';
import AppSecretKey from './versions/AppSecretKey';

const Versions = () => (
  <Column span={6}>
    <TitleAction>
      <Heading>Versions</Heading>
      <AddVersionButton />
    </TitleAction>
    <Grid />
    <AppSecretKey />
    <PrivateTenants />
  </Column>
);

export default Versions;
