import { addFlashMessage } from 'flashMessage/redux/flashMessageSlice';
import { removeAllApiErrors } from 'layout/redux/layoutSlice';

const listVersionWebHooks = (build) =>
  build.query({
    query: (versionId) => ({
      url: `/version/${versionId}/web-hook`,
      method: 'GET'
    }),
    providesTags: () => {
      const tags = [{ type: 'VersionWebHook', id: 'LIST' }];
      return tags;
    }
  });

const createVersionWebHook = (build) =>
  build.mutation({
    query: ({ data, versionId }) => ({
      url: `/version/${versionId}/web-hook`,
      method: 'POST',
      data
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({ message: 'API Webhook Created!', type: 'success' })
      );
    },
    invalidatesTags: () => [{ type: 'VersionWebHook', id: 'LIST' }]
  });

const updateVersionWebHook = (build) =>
  build.mutation({
    query: ({ data, versionId, webhookId }) => ({
      url: `/version/${versionId}/web-hook/${webhookId}`,
      method: 'PUT',
      data
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({ message: 'API Webhook Updated!', type: 'success' })
      );
    },
    invalidatesTags: () => [{ type: 'VersionWebHook', id: 'LIST' }]
  });

const deleteVersionWebHook = (build) =>
  build.mutation({
    query: ({ versionId, webhookId }) => ({
      url: `/version/${versionId}/web-hook/${webhookId}`,
      method: 'DELETE'
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({ message: 'API Webhook Deleted!', type: 'success' })
      );
    },
    invalidatesTags: () => [{ type: 'VersionWebHook', id: 'LIST' }]
  });

const versionWebHookApiTemp = {
  listVersionWebHooks,
  createVersionWebHook,
  updateVersionWebHook,
  deleteVersionWebHook
};
export default versionWebHookApiTemp;
