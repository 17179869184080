import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useCreateVersionSettingMutation } from 'api';
import { Formik, ModalFormFooter } from 'components/formik';
import { Modal, ModalBody } from 'components/modal';
import { setModal } from 'modal/redux/modalSlice';

import CodeFill from './components/addModal/CodeFill';
import FormGeneral from './components/common/FormGeneral';
import { DATA_TYPE } from './utils/enums';
import { validateCreate } from './utils/validators';

const AddModal = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [createVersionSetting] = useCreateVersionSettingMutation();

  const handleSubmit = async (values, formik) => {
    const data = transformData(values);
    const { error } = await createVersionSetting({
      data,
      versionId: params.id
    });
    formik.setSubmitting(false);
    if (!error) {
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(setModal(null));
  };

  return (
    <Modal title="Add Field" visible onClose={handleClose}>
      <Formik
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={getInitialValues()}
      >
        <ModalBody>
          <CodeFill />
          <FormGeneral />
        </ModalBody>
        <ModalFormFooter saveButtonText="Add Field" />
      </Formik>
    </Modal>
  );
};

const transformData = (values) => {
  const data = {
    title: values.title,
    dataType: values.dataType,
    isRequired: values.isRequired,
    sortOrder: values.sortOrder,
    code: values.code
  };
  if (
    [DATA_TYPE.RADIO, DATA_TYPE.CHECKBOX, DATA_TYPE.SELECT].includes(
      values.dataType
    )
  ) {
    data.options = [];
    values.options.forEach((option) => {
      if (option) {
        data.options.push(option);
      }
    });
  }
  return data;
};

const validate = (values) => {
  const data = transformData(values);
  const errors = validateCreate(data);
  return errors;
};

const getInitialValues = () => {
  const initialValues = {
    title: '',
    dataType: DATA_TYPE.STRING,
    isRequired: false,
    sortOrder: 0,
    code: '',
    options: ['']
  };
  return initialValues;
};

export default AddModal;
