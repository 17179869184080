import Tag from 'components/tag';

import DateTag from './DateTag';
import TextTag from './TextTag';

const FilterTag = (props) => {
  const { filter, handleRemoveFilter } = props;
  const handleOnClick = (e) => {
    e.preventDefault();
    handleRemoveFilter(filter.key.key);
  };

  const label = getFilterLabel(filter);

  return <Tag onDelete={handleOnClick}>{label}</Tag>;
};

const getFilterLabel = (filter) => {
  const { key, value, type } = filter;
  switch (type) {
    case 'text':
    case 'checkbox':
    case 'radio':
    case 'select':
      return <TextTag value={value} label={key.label} filter={filter} />;
    case 'date':
      return <DateTag value={value} label={key.label} />;
    default:
      return null;
  }
};

export default FilterTag;
