import { useDispatch } from 'react-redux';

import { Grid } from 'components/grid';
import NoRecords from 'components/noRecords';
import { setModal } from 'modal/redux/modalSlice';

import { useListAppXTenants } from '../../utils/hooks';

import Price from './grid/Price';
import TenantStatus from './grid/TenantStatus';
import UpdateTenantModal from './grid/UpdateTenantModal';

const InstallGrid = () => {
  const dispatch = useDispatch();

  const rowHeaders = [
    { label: 'Tenant' },
    { label: 'Installed By' },
    { label: 'Email' },
    { label: 'Tenant Status' },
    { label: 'Version', align: 'right' },
    { label: 'Price', align: 'right' }
  ];

  const rowBody = [
    {
      data: 'tenantName'
    },
    {
      data: 'installedByName'
    },
    {
      data: 'installedByEmail'
    },
    {
      data: 'tenantStatus',
      type: 'component',
      props: 'appXTenant',
      component: TenantStatus
    },
    {
      data: 'versionNumber',
      align: 'right'
    },
    {
      data: 'price',
      type: 'component',
      props: 'appXTenant',
      component: Price,
      align: 'right'
    }
  ];

  const handleRowClick = (appXTenant) => {
    dispatch(setModal(<UpdateTenantModal appXTenant={appXTenant} />));
  };

  const customNoRecords = <NoRecords title="No Installs Found" />;

  return (
    <Grid
      rowHeaders={rowHeaders}
      rowBody={rowBody}
      listHook={useListAppXTenants}
      customNoRecords={customNoRecords}
      onRowClick={handleRowClick}
    />
  );
};

export default InstallGrid;
