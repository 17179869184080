import { Columns, Column } from 'components/columns';
import { Field, Select } from 'components/formik';
import LineBreak from 'components/lineBreak';

import { getObjectTypeOptions } from '../../utils/helpers';
import { useClearUnusedFields } from '../../utils/hooks';

import DisplayType from './formGeneral/DisplayType';
import Example from './formGeneral/Example';
import ExtensionFormSwitch from './formGeneral/ExtensionFormSwitch';

const FormGeneral = () => {
  useClearUnusedFields();

  return (
    <>
      <Field
        label="Location"
        name="objectType"
        options={getObjectTypeOptions()}
        component={Select}
      />
      <Columns>
        <Column span={6}>
          <DisplayType />
        </Column>
        <Column span={6}>
          <Example />
        </Column>
      </Columns>
      <LineBreak />
      <ExtensionFormSwitch />
    </>
  );
};

export default FormGeneral;
