import styled from 'styled-components';

import { Columns } from 'components/columns';
import { Page } from 'components/page';
import Region from 'components/region';
import { isShutDown } from 'utils/helpers/sharedFeatureFlags';

import Dashboard from './components/view/Dashboard';
import Resources from './components/view/Resources';

const View = () => {
  // eslint-disable-next-line no-console
  console.log(isShutDown('tyson-louths-demo-winery'));

  return (
    <Page title="Dashboard">
      <StyledRegion>
        <Columns>
          <Dashboard />
          <Resources />
        </Columns>
      </StyledRegion>
    </Page>
  );
};

const StyledRegion = styled(Region)`
  margin-top: 60px;
`;

export default View;
