// eslint-disable-next-line import/no-cycle
import { addFlashMessage } from 'flashMessage/redux/flashMessageSlice';
import { removeAllApiErrors } from 'layout/redux/layoutSlice';

const listPrivateTenants = (build) =>
  build.query({
    query: () => ({
      url: '/private-tenant',
      method: 'GET'
    }),
    providesTags: () => {
      const tags = [{ type: 'PrivateTenant', id: 'LIST' }];
      return tags;
    }
  });

const createPrivateTenant = (build) =>
  build.mutation({
    query: ({ data }) => ({
      url: `/private-tenant`,
      method: 'POST',
      data
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      try {
        await queryFulfilled;
        dispatch(
          addFlashMessage({
            message: 'Client Added.',
            type: 'success'
          })
        );
      } catch (err) {
        // fail silently - error shows in top banner
      }
    },
    invalidatesTags: () => [{ type: 'PrivateTenant', id: 'LIST' }]
  });

const deletePrivateTenant = (build) =>
  build.mutation({
    query: ({ tenantId }) => ({
      url: `/private-tenant/${tenantId}`,
      method: 'DELETE'
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      try {
        await queryFulfilled;
      } catch (err) {
        // fail silently - error shows in top banner
      }
      dispatch(
        addFlashMessage({
          message: 'Client Deleted.',
          type: 'success'
        })
      );
    },
    invalidatesTags: () => [{ type: 'PrivateTenant', id: 'LIST' }]
  });

const privateTenantApi = {
  listPrivateTenants,
  createPrivateTenant,
  deletePrivateTenant
};
export default privateTenantApi;
