import styled from 'styled-components';

import { Button } from 'components/button';
import Card from 'components/card';
import Heading from 'components/heading';
import Text from 'components/text';

const AdminUICard = () => {
  const link = 'https://admin-ui-docs.commerce7.com/';
  return (
    <StyledLinkCard href={link} target="_blank" rel="noreferrer">
      <StyledCard>
        <StyledImageWrapper>
          <img src="/assets/images/admin-ui-card.png" alt=" " />
        </StyledImageWrapper>
        <StyledContent>
          <StyledHeading level={3}>Admin UI Library</StyledHeading>
          <Text>
            Use our growing library of components to build your app quicker and
            create a consistent experience for clients.
          </Text>
          <Button endIcon="newTab" variant="link">
            View Docs
          </Button>
        </StyledContent>
      </StyledCard>
    </StyledLinkCard>
  );
};

const StyledLinkCard = styled.a`
  display: block;
  margin-bottom: 20px;
`;

const StyledCard = styled(Card)`
  padding: 0px;
  display: flex;
  overflow: hidden;

  flex-direction: column;

  background-color: ${({ theme }) => theme.c7__ui.backgroundColor};

  @media ${({ theme }) => theme.c7__ui.breakpoints.largeUp} {
    flex-direction: row;
  }

  &:hover {
    box-shadow: ${({ theme }) => theme.c7__ui.boxShadow};
    transform: translateY(-1px);
  }
`;

const StyledImageWrapper = styled.div`
  flex: 2;
  padding: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-bottom: 1px solid ${({ theme }) => theme.c7__ui.borderColor};
  border-right: none;

  img {
    max-width: 180px;
  }

  @media ${({ theme }) => theme.c7__ui.breakpoints.largeUp} {
    border-bottom: none;
    border-right: 1px solid ${({ theme }) => theme.c7__ui.borderColor};
  }

  background-color: #f4f2ff;
`;

const StyledContent = styled.div`
  flex: 3;

  padding: 20px;
`;

const StyledHeading = styled(Heading)`
  font-size: 18px;
  margin-bottom: 5px;
`;

export default AdminUICard;
