import { Radio } from '@commerce7/admin-ui';
import { getIn } from 'formik';

const FormikRadio = (props) => {
  const { label, field, form, onChange, value, description } = props;
  const { onBlur, onChange: onFieldChange, name } = field;
  const { errors, touched, values } = form;

  const handleOnChange = (e) => {
    const newEvent = e;
    e.target.name = name;

    if (onChange) {
      onChange(newEvent);
    }
    return onFieldChange(newEvent);
  };

  const errorMessage = errors[name] && touched[name] ? errors[name] : null;

  const id = `${name}-${value.replace(/\s+/g, '')}`;
  const formikValue = getIn(values, name);

  return (
    <Radio
      label={label}
      onBlur={onBlur}
      onChange={handleOnChange}
      checked={value === formikValue}
      value={value}
      id={id}
      errorMessage={errorMessage}
      description={description}
    />
  );
};

export default FormikRadio;
