import { validateForUI } from 'utils/helpers/jsonValidator';

const properties = {
  accountId: { type: 'string', format: 'uuid' },
  role: { type: 'string', enum: ['Admin Owner', 'Admin User'] }
};

const appXAccountUpdateSchema = {
  type: 'object',
  properties,
  additionalProperties: false
};

const inviteSchema = {
  type: 'object',
  properties: {
    email: { type: 'string', format: 'email' },
    role: { type: 'string', enum: ['Admin Owner', 'Admin User'] }
  },
  required: ['email', 'role'],
  additionalProperties: false
};

export const validateInvite = (appXAccount) =>
  validateForUI(inviteSchema, appXAccount);

export const validateUpdate = (appXAccount) =>
  validateForUI(appXAccountUpdateSchema, appXAccount);
