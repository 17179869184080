import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Grid from 'app/Grid';
import { useListAllApps } from 'app/utils/hooks';
import { LinkButton } from 'components/button';
import { SearchTools } from 'components/grid';
import Heading from 'components/heading';
import TitleAction from 'components/titleAction';

const Apps = () => {
  const { hasApps } = useListAllApps();

  if (!hasApps) return null;

  return (
    <>
      <StyledTitleAction>
        <Heading>Your Apps</Heading>
        <LinkButton startIcon="add" to="/add" component={Link}>
          Add App
        </LinkButton>
      </StyledTitleAction>
      <SearchTools label="by app name" />
      <Grid />
    </>
  );
};

const StyledTitleAction = styled(TitleAction)`
  margin: 40px 0 20px 0;
`;

export default Apps;
