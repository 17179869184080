import { ContextMenu as UIContextMenu } from '@commerce7/admin-ui';

const { ContextMenuItem, ContextMenuMoreActions } = UIContextMenu;

const ContextMenu = (props) => {
  const { children } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <UIContextMenu {...props}>{children}</UIContextMenu>;
};

export { ContextMenu, ContextMenuItem, ContextMenuMoreActions };
