import styled from 'styled-components';

import { Field, Radio, RadioGroup, Checkbox } from 'components/formik';

import { REQUEST_TYPE, OBJECT_TYPE_TRANSLATED } from '../../../utils/enums';

const AccessObject = (props) => {
  const { value, objectType } = props;

  const isSelected = value.selected;

  return (
    <>
      <StyledCheckboxWrapper isSelected={isSelected}>
        <Field
          name={`${objectType}.selected`}
          component={Checkbox}
          label={OBJECT_TYPE_TRANSLATED[`${objectType}`]}
        />
      </StyledCheckboxWrapper>
      {isSelected && (
        <StyledRadioGroupWrapper>
          <RadioGroup name={`${objectType}.requestType`}>
            <Field
              name={`${objectType}.requestType`}
              component={Radio}
              value={REQUEST_TYPE.READ}
              label="Read"
            />
            <Field
              name={`${objectType}.requestType`}
              component={Radio}
              value={REQUEST_TYPE.FULL}
              label="Full (Create, Read, Edit, Delete)"
            />
          </RadioGroup>
        </StyledRadioGroupWrapper>
      )}
    </>
  );
};

const StyledRadioGroupWrapper = styled.div`
  padding-left: 22px;

  fieldSet {
    margin-bottom: 17px;
  }
`;

const StyledCheckboxWrapper = styled.div`
  div {
    margin-bottom: ${({ isSelected }) => (isSelected ? '8' : '15')}px;
  }
`;

export default AccessObject;
