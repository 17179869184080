import styled from 'styled-components';

import Alert from 'components/alert';
import { useFormikContext } from 'components/formik';

const FormErrors = () => {
  const formikContext = useFormikContext();

  const errors = Object.keys(formikContext.errors);
  const errorMessage = errors.length > 0 && formikContext.submitCount > 0;

  if (!errorMessage) {
    return null;
  }

  return (
    <ErrorMessageStyles variant="error">
      Please fix the form error{errors.length > 1 && 's'} above.
    </ErrorMessageStyles>
  );
};

const ErrorMessageStyles = styled(Alert)`
  display: flex;
  margin-top: 10px;
  color: ${({ theme }) => (theme.mode === 'dark' ? '#fff' : 'rgb(41,50,56)')};
}
`;

export default FormErrors;
