import { getIn } from 'formik';

import { useFormikContext } from 'components/formik';

export const useCharactersRemaining = (name, totalCharacters = 255) => {
  const { values } = useFormikContext();
  const value = getIn(values, name);
  if (value === null) return `255 characters remaining`;
  const remainingCharacters = totalCharacters - value.length;
  return `${remainingCharacters} characters remaining`;
};
