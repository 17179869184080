import Tag from 'components/tag';

import { STATUS_TRANSLATED, STATUS } from '../../utils/enums';

const Status = (props) => {
  const { version } = props;
  const { status } = version;

  let variant = 'default';
  if (status === STATUS.APPROVED) {
    variant = 'success';
  }

  return <Tag variant={variant}>{STATUS_TRANSLATED[status]}</Tag>;
};

export default Status;
