import styled from 'styled-components';

import Spinner from 'components/spinner';

const LoadingLayout = () => (
  <LoadingWrapperStyles>
    <Spinner />
  </LoadingWrapperStyles>
);

const LoadingWrapperStyles = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.c7__ui.backgroundColor};
`;

export default LoadingLayout;
