import { c7Colors } from '@commerce7/admin-ui';
import styled from 'styled-components';

const AlternatePageHeader = (props) => {
  const { children } = props;
  return <AlternatePageHeaderStyles>{children}</AlternatePageHeaderStyles>;
};

const AlternatePageHeaderStyles = styled.div`
  background-color: ${({ theme }) =>
    alternatePageHeader[theme.c7__ui.mode].backgroundColor};
  margin: -20px -20px 20px;
  padding: 20px 20px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.c7__ui.borderColor};

  @media ${({ theme }) => theme.c7__ui.breakpoints.largeUp} {
    margin: -30px -30px 30px;
    padding: 30px 30px 20px;
  }
`;

const alternatePageHeader = {
  light: {
    backgroundColor: c7Colors.gray200
  },
  dark: {
    backgroundColor: c7Colors.slate200
  }
};

export default AlternatePageHeader;
