import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

// @common
const useQuery = () => {
  const location = useLocation();

  return queryString.parse(location.search);
};

export default useQuery;
