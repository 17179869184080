import { useDispatch } from 'react-redux';

import { useGetAppTempListing } from 'appTempListing/utils/hooks';
import { Button } from 'components/button';
import { setModal } from 'modal/redux/modalSlice';
import { STATUS } from 'version/utils/enums';
import { useGetLatestVersion } from 'version/utils/hooks';

import SubmitAppModal from './submitAppButton/SubmitAppModal';

const SubmitAppButton = () => {
  const dispatch = useDispatch();
  const { complete } = useGetAppTempListing();
  const { latestVersion, isLoading } = useGetLatestVersion();

  if (isLoading) return null;

  const { status } = latestVersion;

  if (status !== STATUS.PENDING) return null;

  const handleOpenSubmitAppModal = async () => {
    dispatch(setModal(<SubmitAppModal />));
  };

  return (
    <Button disabled={!complete} onClick={handleOpenSubmitAppModal}>
      Submit App
    </Button>
  );
};

export default SubmitAppButton;
