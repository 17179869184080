/* eslint-disable no-console */
/* eslint-disable import/no-mutable-exports */
let isShutDown;
let getShutdownWindow;

(async () => {
  try {
    // eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
    const sharedFeatureFlags = await import('shared/utils/featureFlags');
    if (sharedFeatureFlags) {
      isShutDown = sharedFeatureFlags.isShutDown;
      getShutdownWindow = sharedFeatureFlags.getShutdownWindow;
    }
  } catch (error) {
    console.log(error);
  }
})();

export { isShutDown, getShutdownWindow };
