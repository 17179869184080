import DataDisplay from 'components/dataDisplay';
import { Field, Input, useFormikContext } from 'components/formik';
import { normalizeDecimal } from 'utils/helpers/formHelper';
import { currencyIcon } from 'utils/helpers/miscHelper';
import { currencyFormat } from 'utils/helpers/numberHelper';

import { useGetVersion } from '../../../../utils/hooks';

const MonthlyFee = () => {
  const { isApproved } = useGetVersion();
  const { values } = useFormikContext();
  const { version } = useGetVersion();
  const { monthlyFee } = version;
  const { currency } = values;

  if (isApproved) {
    return (
      <>
        <DataDisplay label="Monthly Fee">
          {currencyFormat(monthlyFee, currency)}
        </DataDisplay>
      </>
    );
  }

  return (
    <Field
      name="monthlyFee"
      label="Monthly Fee"
      startIcon={currencyIcon(currency)}
      component={Input}
      normalize={normalizeDecimal}
    />
  );
};

export default MonthlyFee;
