import Heading from 'components/heading';
import TitleAction from 'components/titleAction';

import AlertBanner from './components/view/AlertBanner';
import AppListing from './components/view/AppListing';
import ListingsActionsButton from './components/view/ListingsActionsButton';

const View = () => (
  <>
    <TitleAction>
      <Heading>App Listing</Heading>
      <ListingsActionsButton />
    </TitleAction>
    <AlertBanner />
    <AppListing />
  </>
);

export default View;
