import styled from 'styled-components';

import NoRecords from 'components/noRecords';
import { Page } from 'components/page';
import Region from 'components/region';

const NotFound = () => (
  <Page title="Page not found">
    <StyledRegion>
      <NoRecords
        title="Not Found"
        description="Sorry you found our 404 page - that should not have happened!"
      />
    </StyledRegion>
  </Page>
);

const StyledRegion = styled(Region)`
  margin: 0 auto;
`;

export default NotFound;
