// @common
export const imageFile = (image, size) => image.replace('original', size);

// @common
export const pathForMime = (path, mime) =>
  path.replace(getFileExtension(path), mime);

// @common
export const getFileExtension = (file) => {
  if (file.indexOf('.') > 0) {
    return file.split('.').pop().toLowerCase();
  }
  return '';
};

// @common
export const IMAGE_SIZES = {
  ORIGINAL: 'original',
  THUMB: 'thumb',
  FULL: 'full'
};
