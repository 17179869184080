import { useState, useEffect } from 'react';

import {
  FilterHeaderStyles,
  FilterHeaderButton,
  FilterApplyButtonStyles,
  FilterSelectStyles
} from './styles';

const FilterBySelect = (props) => {
  const { value = '', filter, onChange } = props;
  const { options, defaultValue } = filter.value;
  const { key } = filter.key;
  const [selectValue, setSelectValue] = useState(value || defaultValue);

  useEffect(() => {
    setSelectValue(value || defaultValue);
  }, [value, defaultValue]);

  const handleClearFilter = (e) => {
    e.preventDefault();
    setSelectValue('');
  };

  const handleOnChange = (e) => {
    setSelectValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onChange(key, selectValue);
  };

  return (
    <>
      <FilterHeaderStyles>
        <FilterHeaderButton variant="transparent" onClick={handleClearFilter}>
          Clear filter
        </FilterHeaderButton>
      </FilterHeaderStyles>
      <FilterSelectStyles
        options={options}
        value={selectValue}
        onChange={handleOnChange}
        label={filter.label}
      />
      <FilterApplyButtonStyles onClick={handleSubmit} variant="outline">
        Apply Filter
      </FilterApplyButtonStyles>
    </>
  );
};

export default FilterBySelect;
