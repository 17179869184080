import { getIn } from 'formik';

import { Columns, Column } from 'components/columns';
import DataDisplay from 'components/dataDisplay';
import { Field, Input, useFormikContext } from 'components/formik';
import {
  currencyFormat,
  convertCurrencyStringToCents
} from 'utils/helpers/numberHelper';

import { countries } from '../../../../utils/helpers';
import { useGetVersion } from '../../../../utils/hooks';

import CurrencyDisplay from './common/CurrencyDisplay';

const CurrencyField = (props) => {
  const { country } = props;
  const { isApproved } = useGetVersion();
  const { values } = useFormikContext();

  const countryInfo = countries[country];
  const { name, currency } = countryInfo;

  const priceForCountry = getIn(values, name);

  if (isApproved) {
    return (
      <Columns>
        <Column span={6}>
          <CurrencyDisplay country={country} />
        </Column>
        <Column span={6}>
          <DataDisplay label="Monthly Fee">
            {currencyFormat(
              convertCurrencyStringToCents(priceForCountry),
              currency
            )}
          </DataDisplay>
        </Column>
      </Columns>
    );
  }

  return (
    <Columns>
      <Column span={6}>
        <CurrencyDisplay country={country} />
      </Column>
      <Column span={6}>
        <Field
          name={countries[country].name}
          component={Input}
          label="Monthly Fee"
          startIcon={countries[country].currencyIcon}
        />
      </Column>
    </Columns>
  );
};

export default CurrencyField;
