import queryString from 'query-string';

// @common
export const concatUrlString = (qString, page) => {
  let urlString;
  if (qString) {
    urlString = qString;
  }
  if (page && page > 1) {
    if (urlString) {
      urlString += `&page=${page}`;
    } else {
      urlString = `page=${page}`;
    }
  }
  return urlString;
};

// @common
export const urlPushQuery = (listUrl, qString) => {
  let url = listUrl;
  if (qString) {
    url = `${listUrl}?${qString}`;
  }
  return url;
};

// @common
export const queryParser = (searchString) => {
  const queryObj = queryString.parse(searchString);
  const { page } = queryString.parse(searchString);
  delete queryObj.page;
  return { queryObj, page };
};
