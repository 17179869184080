import styled from 'styled-components';

import AppHeading from './pageHeader/AppHeading';
import Logo from './pageHeader/Logo';
import MobileMenuToggle from './pageHeader/MobileMenuToggle';
import UserDropdown from './pageHeader/UserDropdown';

const PageHeader = (props) => {
  const { hideMobileMenuToggle } = props;
  return (
    <StyledPageHeader>
      <StyledHeadingWrapper>
        {!hideMobileMenuToggle && <MobileMenuToggle />}
        <Logo />
        <AppHeading />
      </StyledHeadingWrapper>
      <UserDropdown />
    </StyledPageHeader>
  );
};

const StyledHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledPageHeader = styled.header`
  background-color: ${({ theme }) => theme.c7__ui.colors.blue600};
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  @media print {
    display: none;
  }
`;

export default PageHeader;
