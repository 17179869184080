import styled from 'styled-components';

import { LinkButton } from 'components/button';
import Heading from 'components/heading';
import { DisplayIcon } from 'components/icon';
import Text from 'components/text';

import SupportItem from './SupportItem';

const SupportItems = () => (
  <StyledSupportItems>
    <SupportItem>
      <div>
        <DisplayIcon icon="document" />
      </div>
      <div>
        <div>
          <Heading level={3}>App Development Docs</Heading>
          <Text>
            Not sure where to start? Use our app development documentation to
            see step-by-step instructions on how to add an app.
          </Text>
        </div>
        <LinkButton
          href="https://dev-center-docs.commerce7.com/docs"
          target="_blank"
        >
          View Docs
        </LinkButton>
      </div>
    </SupportItem>
    <SupportItem>
      <div>
        <DisplayIcon icon="chat" />
      </div>
      <div>
        <div>
          <Heading level={3}>Partner Slack</Heading>
          <Text>
            Have a question or running into a problem? Use our partner slack to
            get answers to your questions fast. Don’t have access yet? Email{' '}
            <a href="mailto:support@commerce7.com">support@commerce7.com</a> to
            request it.
          </Text>
        </div>
        <LinkButton href="https://commerce7partners.slack.com/" target="_blank">
          Open Slack
        </LinkButton>
      </div>
    </SupportItem>
    <SupportItem>
      <div>
        <DisplayIcon icon="document" />
      </div>
      <div>
        <div>
          <Heading level={3}>Commerce7 Documentation</Heading>
          <Text>
            Need help learning how to use the Commerce7 Admin, POS, or
            Reservation tools? Read our customer documentation to understand how
            our tools work.
          </Text>
        </div>
        <LinkButton href="https://documentation.commerce7.com/" target="_blank">
          Search Knowledge Base
        </LinkButton>
      </div>
    </SupportItem>
  </StyledSupportItems>
);

const StyledSupportItems = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(385px, 1fr));
  gap: 20px;
  align-items: stretch;
  margin-bottom: 20px;
`;

export default SupportItems;
