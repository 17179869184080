// import { addFlashMessage } from 'flashMessage/redux/flashMessageSlice';
// import { removeAllApiErrors } from 'layout/redux/layoutSlice';

const listBillings = (build) =>
  build.query({
    query: (query) => ({
      url: `/billing`,
      method: 'GET',
      params: query
    }),
    providesTags: () => {
      const tags = [{ type: 'Billing', id: 'LIST' }];
      return tags;
    }
  });

const billingApi = {
  listBillings
};
export default billingApi;
