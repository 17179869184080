import styled from 'styled-components';

import Icon from 'components/icon';
import Text from 'components/text';

const HiddenPassword = (props) => {
  const { onEdit, label = 'Password' } = props;
  return (
    <>
      <StyledPasswordLabelText block small>
        {label}
      </StyledPasswordLabelText>
      <Text>
        &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
      </Text>
      <StyledIcon icon="edit" onClick={onEdit} />
    </>
  );
};

const StyledPasswordLabelText = styled(Text)`
  line-height: 1.15;
`;

const StyledIcon = styled(Icon)`
  margin-left: 10px;
  transform: translateY(5px);
`;

export default HiddenPassword;
