import { dateFormat } from './dateHelper';
import {
  convertCurrencyStringToCents,
  convertStringToInteger
} from './numberHelper';
import { constantToTitleCase } from './stringHelper';

// @common
export const getOperatorOptions = (operators = []) =>
  operators.map((operator) => ({
    label: operator,
    value: operator
  }));

// @common
export const getOperatorValueFromQueryString = (value = '', filter) => {
  if (value.includes('gt:')) {
    return 'Greater Than';
  }
  if (value.includes('gte:')) {
    return 'Greater Than or Equal To';
  }
  if (value.includes('lt:')) {
    return 'Less Than';
  }
  if (value.includes('lte:')) {
    return 'Less Than or Equal To';
  }
  if (value.includes('btw:')) {
    return 'Between';
  }

  const { operators } = filter.value;
  return operators[0];
};

// @common
export const getValuesFromQueryString = (value = '', filter) => {
  const formattedValue = getFormattedValueFromQueryString(value);

  const value1 = filter.value.default;
  const value2 = filter.value.default2;

  return {
    value1: formattedValue[0] || value1,
    value2: formattedValue[1] || value2
  };
};

// @common
export const getDateValuesFromQueryString = (value = '', filter) => {
  const formattedValue = getFormattedValueFromQueryString(value);

  let startDate = filter.value.default;
  let endDate = filter.value.default2;

  if (formattedValue[0]) {
    startDate = dateFormat(formattedValue[0]);
  }
  if (formattedValue[1]) {
    endDate = dateFormat(formattedValue[1]);
  }

  return {
    startDate,
    endDate
  };
};

// @common
const getFormattedValueFromQueryString = (value = '') =>
  value.replace(/gt:|gte:|lt:|lte:|btw:|in:/gi, '').split('|');

// @common
export const formatValue = (value, type) => {
  if (type === 'currency') {
    const newText = value.replace(/[^0-9.]/g, '');
    return convertCurrencyStringToCents(newText);
  }
  if (type === 'integer') {
    return convertStringToInteger(value);
  }

  return value ? constantToTitleCase(value) : '';
};

// @common
export const getInFilterLabel = (value = '') => {
  const maxVisibleFilters = 3;
  const allFilters = value.replace('in:', '').split(',');
  const visibleFilters = allFilters.filter(
    (_, index) => index < maxVisibleFilters
  );
  const hasMoreFilters = allFilters.length > maxVisibleFilters;

  let formattedValue = '';

  visibleFilters.forEach((filter, index) => {
    let splitString = '';
    let moreString = '';
    const showMaxFilterMessage =
      hasMoreFilters && index === maxVisibleFilters - 1;

    if (index > 0) {
      if (index === visibleFilters.length - 1 && !showMaxFilterMessage) {
        splitString = ' or';
      } else {
        splitString = ',';
      }
    }

    if (showMaxFilterMessage) {
      const moreFiltersLength = allFilters.length - visibleFilters.length;

      moreString = ` or ${moreFiltersLength} more`;
    }

    let formattedFilter = filter ? constantToTitleCase(filter) : '';

    if (filter === 'true') {
      formattedFilter = 'Yes';
    }
    if (filter === 'false') {
      formattedFilter = 'No';
    }

    formattedValue = `${formattedValue}${splitString} ${formattedFilter}${moreString}`;
  });

  return formattedValue;
};
