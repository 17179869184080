import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useUpdateAppMutation } from 'api';
import { Formik, ModalFormFooter } from 'components/formik';
import { Modal, ModalBody } from 'components/modal';
import Text from 'components/text';
import { setModal } from 'modal/redux/modalSlice';

import { useGetApp } from '../../../utils/hooks';
import { validateUpdate } from '../../../utils/validators';

import FormGeneral from './editAppModal/FormGeneral';

const EditAppModal = () => {
  const dispatch = useDispatch();
  const { app } = useGetApp();
  const [updateApp] = useUpdateAppMutation();

  const handleClose = () => {
    dispatch(setModal(null));
  };

  const handleSubmit = async (values, formik) => {
    const data = transformData(values);
    await updateApp({ appId: app.id, data });
    formik.setSubmitting(false);
    handleClose();
  };

  return (
    <Modal title="Edit App Details" visible onClose={handleClose}>
      <Formik
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={getInitialValues(app)}
      >
        <ModalBody>
          <StyledText>
            The app title can be updated in your app listing.
          </StyledText>
          <FormGeneral />
        </ModalBody>
        <ModalFormFooter saveButtonText="Save App" />
      </Formik>
    </Modal>
  );
};

const transformData = (values) => {
  const data = {
    developerName: values.developerName,
    developerEmail: values.developerEmail?.trim()
  };
  return data;
};

const validate = (values) => {
  const data = transformData(values);
  const errors = validateUpdate(data);
  return errors;
};

const getInitialValues = (app) => {
  const initialValues = {
    developerName: app.developerName,
    developerEmail: app.developerEmail
  };
  return initialValues;
};

const StyledText = styled(Text)`
  margin-bottom: 30px;
`;
export default EditAppModal;
