import DataDisplay from 'components/dataDisplay';
import { Field, Input, useFormikContext } from 'components/formik';
import { normalizeDecimal } from 'utils/helpers/formHelper';
import { currencyIcon } from 'utils/helpers/miscHelper';
import { currencyFormat } from 'utils/helpers/numberHelper';

import { useGetVersion } from '../../../../utils/hooks';

const SetupFee = () => {
  const { isApproved } = useGetVersion();
  const { values } = useFormikContext();
  const { setupFee, currency } = values;

  if (isApproved) {
    return (
      <DataDisplay label="Setup Fee">
        {currencyFormat(setupFee, currency)}
      </DataDisplay>
    );
  }

  return (
    <Field
      name="setupFee"
      label="Setup Fee"
      startIcon={currencyIcon(currency)}
      component={Input}
      normalize={normalizeDecimal}
    />
  );
};

export default SetupFee;
