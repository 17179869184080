import { useState, useEffect } from 'react';

import Text from 'components/text';
import {
  getOperatorOptions,
  getOperatorValueFromQueryString,
  getValuesFromQueryString,
  formatValue
} from 'utils/helpers/filterHelper';
import { currencyFormat } from 'utils/helpers/numberHelper';

import {
  FilterApplyButtonStyles,
  FilterInputStyles,
  FilterSelectStyles
} from './styles';

const FilterByText = (props) => {
  const { filter, value = '', onChange } = props;
  const { key } = filter.key;
  const [values, setValues] = useState(getFilterValues(value, filter));
  const [error, setError] = useState(null);

  useEffect(() => {
    setValues(getFilterValues(value, filter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleOperatorChange = (e) => {
    setValues({ ...values, operator: e.target.value });
  };

  const onValueChange = (e) => {
    setValues({ ...values, value1: e.target.value });
    setError(null);
  };

  const onValue2Change = (e) => {
    setValues({ ...values, value2: e.target.value });
    setError(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);

    const { value1, value2, operator } = values;

    if (!value1) {
      return setError('Value is required');
    }

    const formattedValue1 = formatValue(value1, filter.value.type);

    switch (operator) {
      case 'Less Than':
        return onChange(key, `lt:${formattedValue1}`);
      case 'Less Than or Equal To':
        return onChange(key, `lte:${formattedValue1}`);
      case 'Greater Than':
        return onChange(key, `gt:${formattedValue1}`);
      case 'Greater Than or Equal To':
        return onChange(key, `gte:${formattedValue1}`);
      case 'Between': {
        if (!value2) {
          return setError('Value is required');
        }
        const formattedValue2 = formatValue(value2, filter.value.type);
        return onChange(key, `btw:${formattedValue1}|${formattedValue2}`);
      }
      default:
        return null;
    }
  };

  const { operator, value1, value2 } = values;
  const operators = getOperatorOptions(filter.value.operators);
  const inputType = getInputType(filter);

  return (
    <>
      <FilterSelectStyles
        label=""
        options={operators}
        value={operator}
        onChange={handleOperatorChange}
      />
      <FilterInputStyles
        label=""
        placeholder="Value"
        value={value1}
        type={inputType}
        onChange={onValueChange}
      />
      {operator === 'Between' && (
        <FilterInputStyles
          label=""
          placeholder="Value 2"
          value={value2}
          type={inputType}
          onChange={onValue2Change}
        />
      )}
      {error && (
        <Text error small>
          {error}
        </Text>
      )}
      <FilterApplyButtonStyles onClick={handleSubmit}>
        Apply Filter
      </FilterApplyButtonStyles>
    </>
  );
};

const getFilterValues = (value, filter) => {
  const operator = getOperatorValueFromQueryString(value, filter);
  let { value1, value2 } = getValuesFromQueryString(value, filter);

  if (filter.value.type === 'currency') {
    if (value1 && !value1.includes('$')) {
      value1 = currencyFormat(value1);
    }
    if (value2 && !value2.includes('$')) {
      value2 = currencyFormat(value2);
    }
  }

  return { operator, value1, value2 };
};

const getInputType = (filter) => {
  if (filter.value.type === 'integer') {
    return 'number';
  }

  return 'text';
};

export default FilterByText;
