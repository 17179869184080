import { DescriptionStyles } from './styles';

const FormInstructionsDescription = (props) => {
  const { children, className } = props;
  return (
    <DescriptionStyles secondary className={className}>
      {children}
    </DescriptionStyles>
  );
};

export default FormInstructionsDescription;
