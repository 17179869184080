import styled from 'styled-components';

import { Column, Columns } from 'components/columns';
import { Checkbox, Field, Input } from 'components/formik';
import Heading from 'components/heading';
import Region from 'components/region';
import Text from 'components/text';
import { isSettingUrl } from 'utils/helpers/featureFlags';

import Password from './formGeneral/Password';
import PaymentHandledBy from './formGeneral/PaymentHandledBy';
import PricingBanner from './formGeneral/PricingBanner';
import PricingFields from './formGeneral/PricingFields';

const FormGeneral = (props) => {
  const { setUpdateInstallCredentials } = props;

  return (
    <Region>
      <StyledText secondary small>
        Commerce7 will POST the tenantId to these URLs on installing and
        uninstalling the app.
      </StyledText>
      <Columns>
        <Column span={6}>
          <Field
            name="installUrl"
            label="Install URL (Optional)"
            component={Input}
            placeholder="https://url.com"
          />
        </Column>
        <Column span={3}>
          <Field
            name="installUsername"
            label="Username (Optional)"
            component={Input}
            description="Basic auth to secure the install"
          />
        </Column>
        <Column span={3}>
          <Password setUpdateInstallCredentials={setUpdateInstallCredentials} />
        </Column>
      </Columns>
      <Columns>
        <Column span={6}>
          <Field
            name="uninstallUrl"
            label="Uninstall URL (Optional)"
            component={Input}
            placeholder="https://url.com"
          />
        </Column>
      </Columns>
      <Field
        name="isDeveloperRequiredForInstallation"
        label="Developer is required to complete installation"
        component={Checkbox}
        description="Lets the client know that they need to wait for you to complete setup before installation is complete."
      />
      {isSettingUrl() && (
        <>
          <Heading level={3}>Settings</Heading>
          <Columns>
            <Column span={6}>
              <Field
                name="settingUrl"
                label="Settings URL (Optional)"
                component={Input}
                placeholder="https://url.com"
              />
            </Column>
          </Columns>
        </>
      )}
      <Heading level={3}>Pricing</Heading>
      <PricingBanner />
      <PaymentHandledBy />
      <PricingFields />
    </Region>
  );
};

const StyledText = styled(Text)`
  margin-bottom: 23px;
`;

export default FormGeneral;
