import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Formik } from 'components/formik';
import Text from 'components/text';
import { removeAllApiErrors } from 'layout/redux/layoutSlice';

import { LoginLayout } from '../../components/layout';
import { login } from '../redux/loginSlice';
import { validate2fa } from '../utils/validators';

import FormGeneral from './2fa/FormGeneral';
import FormErrors from './FormErrors';
import SaveButton from './login/SaveButton';

const TwoFactorAuthentication = (props) => {
  const { email, password } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(removeAllApiErrors());
  }, [dispatch]);

  const handleSubmit = (values, formik) => {
    const data = transformData(values);
    data.email = email;
    data.password = password;

    const onSuccess = () => {
      formik.setSubmitting(false);
      navigate('/');
    };

    const onError = () => {
      formik.setSubmitting(false);
    };

    dispatch(login(data, onSuccess, onError));
  };

  return (
    <StyledLoginLayout title="Enter your code">
      <Formik
        initialValues={getInitialValues()}
        validate={validate}
        onSubmit={handleSubmit}
        formType="authentication"
      >
        <StyledText block>
          Enter the 6-digit code from your authenticator app.
        </StyledText>
        <FormGeneral />
        <FormErrors />
        <ActionStyles>
          <SaveButton label="Continue" />
        </ActionStyles>
        <Text block strong>
          Unable to login?
        </Text>
        <Text block>
          Contact someone on your team with Admin Owner access and they&apos;ll
          be able to remove two-factor authentication from your account.
        </Text>
      </Formik>
    </StyledLoginLayout>
  );
};

const transformData = (values) => {
  const data = {
    token: values.token
  };
  return data;
};

const validate = (values) => {
  const data = transformData(values);
  const errors = validate2fa(data);
  return errors;
};

const getInitialValues = () => {
  const initialValues = {
    email: '',
    password: ''
  };
  return initialValues;
};

const ActionStyles = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
`;

const StyledText = styled(Text)`
  margin-bottom: 20px;
`;

const StyledLoginLayout = styled(LoginLayout)`
  h2 {
    margin-bottom: 10px;
  }
`;

export default TwoFactorAuthentication;
