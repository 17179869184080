import { c7Colors } from '@commerce7/admin-ui';
import styled from 'styled-components';

import Icon from 'components/icon';
import Text from 'components/text';

import { useGetAppTempListing } from '../../../utils/hooks';

const AppListingChecklist = () => {
  const { appTempListing } = useGetAppTempListing();

  const {
    logo,
    salesDescription,
    description,
    screenShots,
    websiteUrl,
    supportEmail
  } = appTempListing;

  const atLeast3Screenshots = screenShots.length >= 3;

  return (
    <StyledAppListingChecklist>
      <StyledChecklistItem>
        <StyledIcon
          icon={logo ? 'checkCircle' : 'closeCircle'}
          completed={!!logo}
        />
        <Text>Logo</Text>
      </StyledChecklistItem>

      <StyledChecklistItem>
        <StyledIcon
          icon={salesDescription ? 'checkCircle' : 'closeCircle'}
          completed={!!salesDescription}
        />
        <Text>Summary</Text>
      </StyledChecklistItem>

      <StyledChecklistItem>
        <StyledIcon
          icon={description ? 'checkCircle' : 'closeCircle'}
          completed={!!description}
        />
        <Text>Description</Text>
      </StyledChecklistItem>

      <StyledChecklistItem>
        <StyledIcon
          icon={atLeast3Screenshots ? 'checkCircle' : 'closeCircle'}
          completed={atLeast3Screenshots}
        />
        <Text>3 Images</Text>
      </StyledChecklistItem>

      <StyledChecklistItem>
        <StyledIcon
          icon={websiteUrl ? 'checkCircle' : 'closeCircle'}
          completed={!!websiteUrl}
        />
        <div>
          <Text block>Website URL</Text>
          {websiteUrl && <Text small>{websiteUrl}</Text>}
        </div>
      </StyledChecklistItem>

      <StyledChecklistItem>
        <StyledIcon
          icon={supportEmail ? 'checkCircle' : 'closeCircle'}
          completed={!!supportEmail}
        />
        <div>
          <Text block>Support Email</Text>
          {supportEmail && <Text small>{supportEmail}</Text>}
        </div>
      </StyledChecklistItem>
    </StyledAppListingChecklist>
  );
};

const StyledIcon = styled(Icon)`
  transform: translateY(2px);

  path {
    fill: ${({ theme, completed }) =>
      completed
        ? iconColors[theme.c7__ui.mode].complete
        : iconColors[theme.c7__ui.mode].incomplete};
  }
`;

const iconColors = {
  light: {
    complete: c7Colors.green200,
    incomplete: c7Colors.red300
  },
  dark: {
    complete: c7Colors.green200,
    incomplete: c7Colors.red200
  }
};

const StyledAppListingChecklist = styled.div`
  padding: 0 20px;
`;

const StyledChecklistItem = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
`;
export default AppListingChecklist;
