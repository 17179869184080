import { useDispatch } from 'react-redux';

import { useUpdateAppSecretMutation } from 'api';
import Alert from 'components/alert';
import { Formik, ModalFormFooter } from 'components/formik';
import { Modal, ModalBody } from 'components/modal';
import { setModal } from 'modal/redux/modalSlice';
import { appFromURL } from 'utils/helpers/apiHelper';

import { validateUpdateAppSecret } from '../../../../utils/validators';
import SecretKey from '../../../common/SecretKey';

const ChangeSecretKeyModal = () => {
  const dispatch = useDispatch();
  const [updateAppSecret] = useUpdateAppSecretMutation();

  const handleSubmit = async (values, formik) => {
    const data = transformData(values);
    const { error } = await updateAppSecret({ id: appFromURL(), data });
    formik.setSubmitting(false);
    if (!error) {
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(setModal(null));
  };

  return (
    <Modal title="Change App Secret Key" visible onClose={handleClose}>
      <Formik
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={getInitialValues()}
      >
        <ModalBody>
          <Alert variant="warning" icon="warning">
            If you add a new key, the old one will instantly stop working.
          </Alert>
          <SecretKey />
        </ModalBody>
        <ModalFormFooter saveButtonText="Save App Secret Key" />
      </Formik>
    </Modal>
  );
};

const transformData = (values) => {
  const data = {
    appSecret: values.appSecret
  };
  return data;
};

const validate = (values) => {
  const data = transformData(values);
  const errors = validateUpdateAppSecret(data);
  return errors;
};

const getInitialValues = () => {
  const initialValues = {
    appSecret: ''
  };
  return initialValues;
};

export default ChangeSecretKeyModal;
