import { useNavigate } from 'react-router-dom';

import { Grid } from 'components/grid';
import { dateFormat } from 'utils/helpers/dateHelper';

import EditIcon from './components/grid/EditIcon';
import Price from './components/grid/Price';
import Status from './components/grid/Status';
import VersionNumber from './components/grid/VersionNumber';
import { useListSortedVersions } from './utils/hooks';

const VersionGrid = () => {
  const navigate = useNavigate();

  const rowHeaders = [
    { label: 'Version Number' },
    { label: 'Price', align: 'right' },
    { label: 'Date Added' },
    { label: '' },
    { label: ' ' }
  ];
  const rowBody = [
    {
      data: 'versionNumber',
      type: 'component',
      component: VersionNumber,
      props: 'version'
    },
    {
      data: 'monthlyFee',
      type: 'component',
      align: 'right',
      component: Price,
      props: 'version'
    },
    {
      data: 'createdAt',
      type: 'function',
      func: dateFormat
    },
    {
      data: 'status',
      type: 'component',
      component: Status,
      props: 'version'
    },
    {
      data: 'edit',
      type: 'component',
      component: EditIcon
    }
  ];

  const handleRowClick = (object) => {
    navigate(`/version/${object.id}/web-hooks`);
  };

  return (
    <Grid
      rowHeaders={rowHeaders}
      rowBody={rowBody}
      listHook={useListSortedVersions}
      onRowClick={handleRowClick}
    />
  );
};

export default VersionGrid;
