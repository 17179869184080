import styled from 'styled-components';

import FlashMessage from 'flashMessage/FlashMessage';

import ApiError from './api/ApiError';
import ApiLoading from './api/ApiLoading';
import PageHeader from './components/layout/PageHeader';
import PageSidebar from './components/layout/PageSidebar';

const Layout = (props) => {
  const { children } = props;

  return (
    <>
      <PageHeader />
      <PageSidebar />
      <StyledPage>
        <ApiLoading />
        <ApiError />
        <StyledPageMain>{children}</StyledPageMain>
      </StyledPage>
      <FlashMessage />
    </>
  );
};

const StyledPage = styled.main`
  position: relative;

  padding: 60px 0 0 0;
  transition: background-color 0.3s ease-in-out, margin 0.5s ease-in-out;

  @media ${({ theme }) => theme.c7__ui.breakpoints.largeUp} {
    margin-left: 260px;
  }
`;

const StyledPageMain = styled.div`
  min-height: calc(100vh - 60px);
  display: block;

  @media ${({ theme }) => theme.c7__ui.breakpoints.largeUp} {
    display: flex;
    align-items: stretch;
  }

  // For full page forms, like the product page
  // > form {
  //   width: 100%;
  //   min-height: calc(100vh - 60px);
  //   display: block;

  //   @media ${({ theme }) => theme.c7__ui.breakpoints.largeUp} {
  //     display: flex;
  //     align-items: stretch;
  //   }
  // }
`;

export default Layout;
