import styled from 'styled-components';

const FormActions = (props) => {
  const { children } = props;
  return <StyledFormAction>{children}</StyledFormAction>;
};

const StyledFormAction = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export default FormActions;
