/* globals window */

import axios from 'axios';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import {
  addAxiosRequest,
  removeAxiosRequest,
  addApiError
} from 'layout/redux/layoutSlice';
import { loginLink } from 'utils/helpers/routingHelper';

export const axiosWrapper = (dispatch) => {
  axios.interceptors.request.use(
    (config) => {
      dispatch(addAxiosRequest(config.uuid));
      return config;
    },
    (error) => {
      dispatch(removeAxiosRequest(error.config.uuid));
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      dispatch(removeAxiosRequest(response.config.uuid));
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        if (!window.location.pathname.includes('login')) {
          window.location.href = loginLink();
        }
      }
      if (error.response?.data?.key === 'tokenRequired') {
        return Promise.reject(error);
      }
      dispatch(addApiError(formatApiError(error)));
      dispatch(removeAxiosRequest(error.config.uuid));
      return Promise.reject(error);
    }
  );
};

// @common
const formatApiError = (apiResponse) => {
  let output = '';
  if (apiResponse.response?.data?.errors) {
    output = apiResponse.response.data.errors.reduce((string, err) => {
      let newString = string;
      newString += `The field: "${err.field}" errored because "${err.message}" .`;
      return newString;
    }, '');
    output = `Error from server: ${apiResponse.response.data.message}. ${output}`;
  } else if (apiResponse.response?.data?.message) {
    output = `Error from server: ${apiResponse.response.data.message}. ${output}`;
  } else if (apiResponse.message === 'Network Error') {
    output =
      'Network error.  Either internet connection is unavailable or the remote server is unavailable';
  } else {
    output =
      'Error from server: Something went wrong, please reach out to support@commerce7.com. Click the refresh button in your browser to remove this error.';
  }
  return output;
};

export const axiosHeader = () => {
  const appId = appFromURL();
  if (appId) {
    return {
      headers: { Authorization: Cookies.get(`token`), appId, uuid: uuidv4() }
    };
  }
  return { headers: { Authorization: Cookies.get(`token`) }, uuid: uuidv4() };
};

export const publicAxiosHeader = () => {
  const app = appFromURL();
  if (app) {
    return { headers: { app }, uuid: uuidv4() };
  }
  return null;
};

export const axiosHeaderRTKQuery = () => {
  const appId = appFromURL();

  const noCache = {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0'
  };

  if (appId) {
    return {
      Authorization: Cookies.get('token'),
      ...noCache,
      appId
    };
  }
  return {
    Authorization: Cookies.get('token'),
    ...noCache
  };
};

// @common
export const getGlobalRootUrl = () => {
  switch (process.env.APP_MODE) {
    case 'production':
      return 'https://global-api.commerce7.com/v1';
    case 'frontend':
      return 'https://global-api.commerce7.com/v1';
    default:
      return 'http://localhost:3001/v1';
  }
};

export const getRootUrl = () => {
  switch (process.env.APP_MODE) {
    case 'production':
      return 'https://dev-center-api.commerce7.com/v2';
    case 'frontend':
      return 'https://dev-center-api.commerce7.com/v2';
    default:
      return 'http://localhost:3003/v2';
  }
};

export const appFromURL = () => {
  const host = window.location.hostname;
  if (host === 'localhost') {
    throw new Error('Please use platform.dev7.io');
  }
  const urlParts = host.split('.');
  if (
    urlParts[0] === 'dev-center' ||
    urlParts[0] === 'dev-center-v2' ||
    urlParts[0] === 'platform'
  ) {
    return null;
  }

  return urlParts[0];
};

export const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, data, params }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers: axiosHeaderRTKQuery(),
        uuid: uuidv4()
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message
        }
      };
    }
  };
