import styled from 'styled-components';

import { useFormikContext } from 'components/formik';
import Icon from 'components/icon';

const DeleteButton = (props) => {
  const { name, onDelete } = props;
  const { setFieldValue } = useFormikContext();

  const handleDelete = (e) => {
    e.preventDefault();
    if (onDelete) {
      onDelete();
    } else {
      setFieldValue(name, '');
    }
  };
  return (
    <StyledDeleteButton onClick={handleDelete} type="button">
      <Icon icon="trash" size={16} />
    </StyledDeleteButton>
  );
};

const StyledDeleteButton = styled.button`
  position: absolute;
  right: 10px;
  bottom: 10px;
  border: none;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.c7__ui.colors.red300};
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    transform: translateX(-1px);
    path {
      fill: white;
    }
  }
`;

export default DeleteButton;
