import NoRecords from 'components/noRecords';
import Tag from 'components/tag';

import { OBJECT_TYPE_TRANSLATED, REQUEST_TYPE_TRANSLATED } from './utils/enums';
import { useListVersionSecurities } from './utils/hooks';

const List = () => {
  const { data, total, isLoading } = useListVersionSecurities();

  if (isLoading) return null;

  if (total === 0) {
    return (
      <NoRecords
        title="No API access found"
        description="Add permissions to access client data that your app will require."
      />
    );
  }

  return (
    <>
      {data.map((security) => (
        <Tag key={security.objectType}>
          {OBJECT_TYPE_TRANSLATED[security.objectType]}:{' '}
          {REQUEST_TYPE_TRANSLATED[security.requestType]}
        </Tag>
      ))}
    </>
  );
};

export default List;
