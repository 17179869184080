import { addFlashMessage } from 'flashMessage/redux/flashMessageSlice';
import { removeAllApiErrors } from 'layout/redux/layoutSlice';

const listVersionSecurities = (build) =>
  build.query({
    query: (versionId) => ({
      url: `/version/${versionId}/security`,
      method: 'GET'
    }),
    providesTags: () => {
      const tags = [{ type: 'VersionSecurity', id: 'LIST' }];
      return tags;
    }
  });

const updateVersionSecurityBulk = (build) =>
  build.mutation({
    query: ({ data, versionId }) => ({
      url: `/version/${versionId}/security/bulk`,
      method: 'PUT',
      data
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({ message: 'API Access Updated!', type: 'success' })
      );
    },
    invalidatesTags: () => [{ type: 'VersionSecurity', id: 'LIST' }]
  });

const versionSecurityApi = {
  listVersionSecurities,
  updateVersionSecurityBulk
};
export default versionSecurityApi;
