import { useLocation, useNavigate } from 'react-router-dom';

import { urlPushQuery, concatUrlString } from 'utils/helpers/queryHelper';

export const useHandleSearch = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleSearch = (qString, page) => {
    const urlString = concatUrlString(qString, page);
    navigate(urlPushQuery(location.pathname, urlString));
  };

  return handleSearch;
};
