import NoRecords from 'components/noRecords';
import { Table, Thead, Tr, Th } from 'components/table';

const Empty = (props) => {
  const { rowHeaders, title = 'No Records Found', subtitle } = props;
  return (
    <>
      <Table>
        {rowHeaders?.length && (
          <Thead>
            <Tr>
              {rowHeaders.map((header) => (
                <Th key={header.label} align={header.align}>
                  {header.label}
                </Th>
              ))}
            </Tr>
          </Thead>
        )}
      </Table>
      <NoRecords
        data-testid="grid-no-records"
        title={title}
        description={subtitle}
      />
    </>
  );
};

export default Empty;
