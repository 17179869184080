export const DATA_TYPE = {
  STRING: 'STRING',
  TEXTAREA: 'TEXTAREA',
  INTEGER: 'INTEGER',
  NUMBER: 'NUMBER',
  HTML: 'HTML',
  TRUE_FALSE: 'TRUE_FALSE',
  YES_NO: 'YES_NO',
  RADIO: 'RADIO',
  CHECKBOX: 'CHECKBOX',
  SELECT: 'SELECT',
  DATE_PICKER: 'DATE_PICKER'
};

export const DATA_TYPE_TRANSLATED = {
  STRING: 'String',
  TEXTAREA: 'TextArea',
  INTEGER: 'Integer',
  NUMBER: 'Number',
  HTML: 'HTML',
  TRUE_FALSE: 'True / False',
  YES_NO: 'Yes / No',
  RADIO: 'Radio',
  CHECKBOX: 'Checkbox',
  SELECT: 'Select',
  DATE_PICKER: 'Date Picker'
};
