import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useUpdateAppAccountMutation, useDeleteAppAccountMutation } from 'api';
import { Button } from 'components/button';
import { Formik, ModalFormFooter } from 'components/formik';
import { Modal, ModalBody } from 'components/modal';
import { setModal } from 'modal/redux/modalSlice';
import { appFromURL } from 'utils/helpers/apiHelper';

import { useIsMyAccount } from '../../utils/hooks';
import { validateUpdate } from '../../utils/validators';

import FormGeneral from './common/FormGeneral';

const EditAccountModal = (props) => {
  const { appXAccount } = props;
  const dispatch = useDispatch();
  const [updateAppAccount] = useUpdateAppAccountMutation();
  const [deleteAppAccount] = useDeleteAppAccountMutation();
  const [isDeleting, setIsDeleting] = useState(false);

  const isMyAccount = useIsMyAccount(appXAccount);

  const handleClose = () => {
    dispatch(setModal(null));
  };

  const handleSubmit = async (values, formik) => {
    const data = transformData(values);
    const { error } = await updateAppAccount({ id: appXAccount.id, data });
    formik.setSubmitting(false);
    if (!error) {
      handleClose();
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    const { error } = await deleteAppAccount(appXAccount.id);
    setIsDeleting(false);
    if (!error) {
      handleClose();
    }
  };

  return (
    <Modal title="Edit Account" visible onClose={handleClose}>
      <Formik
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={getInitialValues(appXAccount)}
      >
        <ModalBody>
          <FormGeneral appXAccount={appXAccount} isEdit />
        </ModalBody>
        <ModalFormFooter saveButtonText="Save Account">
          {!isMyAccount && (
            <Button
              startIcon="trash"
              variant="secondary"
              onClick={handleDelete}
              loading={isDeleting}
            >
              Remove Account
            </Button>
          )}
        </ModalFormFooter>
      </Formik>
    </Modal>
  );
};

const transformData = (values) => {
  const data = {
    role: values.role
  };
  return data;
};

const validate = (values) => {
  const data = transformData(values);
  const errors = validateUpdate(data);
  return errors;
};

const getInitialValues = (appXAccount) => {
  const { role } = appXAccount.apps.find((app) => app.appId === appFromURL());
  const initialValues = {
    role
  };
  return initialValues;
};

export default EditAccountModal;
