import { Select } from '@commerce7/admin-ui';
import get from 'lodash.get';

const FormikSelect = (props) => {
  const {
    label,
    options,
    onChange,
    placeholder,
    field,
    form,
    autoFocus,
    description
  } = props;
  const { onBlur, onChange: onFieldChange, value, name } = field;
  const { errors, touched } = form;

  const handleOnChange = (e) => {
    if (onChange) {
      onChange(e);
    }
    return onFieldChange(e);
  };

  let errorMessage;

  if (get(errors, name) && get(touched, name)) {
    errorMessage = get(errors, name);
  }

  return (
    <Select
      label={label}
      autoFocus={autoFocus}
      options={options}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={handleOnChange}
      value={value || ''}
      id={name}
      errorMessage={errorMessage}
      description={description}
    />
  );
};

export default FormikSelect;
