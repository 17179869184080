import { useState, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import { Button } from 'components/button';
import { Column, Columns } from 'components/columns';
import {
  Field,
  Select,
  RadioGroup,
  Radio,
  Input,
  useFormikContext
} from 'components/formik';

import {
  OBJECT,
  OBJECT_TRANSLATED,
  ACTION,
  ACTION_TRANSLATED
} from '../../utils/enums';

import Password from './formGeneral/Password';

const FormGeneral = (props) => {
  const { setUpdateInstallCredentials } = props;
  const firstUpdate = useRef(true);
  const { values, setFieldValue } = useFormikContext();
  const [showAdvanced, setShowAdvanced] = useState(!!values.username);

  const actionOptions = useMemo(
    () => getActionsArray(values.object),
    [values.object]
  );

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setFieldValue('action', actionOptions[0].value);
  }, [actionOptions, setFieldValue]);

  return (
    <>
      <Field
        name="object"
        label="Object"
        component={Select}
        options={objectOptions()}
      />
      <RadioGroup name="action" label="Action">
        {actionOptions.map((option) => (
          <Field
            key={option.value}
            name="action"
            component={Radio}
            value={option.value}
            label={option.label}
          />
        ))}
      </RadioGroup>
      <StyledFieldWrapper>
        <Field
          name="url"
          label="URL"
          placeholder="https://"
          component={Input}
          description="URL to receive the webhook payload when the action occurs"
        />
      </StyledFieldWrapper>
      <Button
        variant="link"
        endIcon={showAdvanced ? 'chevronUp' : 'chevronDown'}
        onClick={() => setShowAdvanced((previous) => !previous)}
      >
        Advanced
      </Button>
      {showAdvanced && (
        <>
          <StyledColumns>
            <Column span={6}>
              <Field
                name="username"
                label="Username (Optional)"
                component={Input}
                description="Basic auth to secure the webhook"
              />
            </Column>
            <Column span={6}>
              <Password
                setUpdateInstallCredentials={setUpdateInstallCredentials}
              />
            </Column>
          </StyledColumns>
        </>
      )}
    </>
  );
};

const objectOptions = () => {
  const array = [];
  Object.keys(OBJECT).forEach((object) => {
    array.push({ value: object, label: OBJECT_TRANSLATED[object] });
  });
  return array;
};

const getActionsArray = (object) => {
  const actionsArray = [];
  const allowedActions = getActionsForObject(object);
  allowedActions.forEach((action) => {
    actionsArray.push({ value: action, label: ACTION_TRANSLATED[action] });
  });
  return actionsArray;
};

const getActionsForObject = (object) => {
  switch (object) {
    case OBJECT.ALLOCATION:
    case OBJECT.CART:
    case OBJECT.CLUB:
    case OBJECT.CLUB_PACKAGE:
    case OBJECT.CLUB_SIGNUP:
    case OBJECT.COLLECTION:
    case OBJECT.COUPON:
    case OBJECT.CUSTOMER_ADDRESS:
    case OBJECT.CUSTOMER_CREDIT_CARD:
    case OBJECT.GIFT_CARD:
    case OBJECT.INVENTORY_LOCATION:
    case OBJECT.NOTE:
    case OBJECT.PRODUCT:
    case OBJECT.PROMOTION:
    case OBJECT.PROMOTION_SET:
    case OBJECT.TAG:
      return [ACTION.CREATE, ACTION.UPDATE, ACTION.DELETE];

    case OBJECT.CLUB_MEMBERSHIP:
    case OBJECT.CUSTOMER:
    case OBJECT.ORDER:
    case OBJECT.RESERVATION:
      return [ACTION.CREATE, ACTION.UPDATE, ACTION.DELETE, ACTION.BULK_UPDATE];

    case OBJECT.ORDER_FULFILLMENT:
      return [ACTION.CREATE, ACTION.DELETE];

    case OBJECT.TRANSACTION_EMAIL:
      return [ACTION.SEND];

    case OBJECT.QUERY:
      return [ACTION.EXECUTE];

    default:
      throw new Error('Unexpected object type');
  }
};

const StyledFieldWrapper = styled.div`
  > div {
    margin-bottom: 10px;
  }
`;

const StyledColumns = styled(Columns)`
  margin-top: 20px;
`;

export default FormGeneral;
