import { useGetStatsQuery } from 'api';
import { useListAllApps } from 'app/utils/hooks';
import { InfoCard, InfoCardGrid } from 'components/infoCard';

const AppStatsCards = () => {
  const { hasApps } = useListAllApps();
  const { data: stats, isLoading } = useGetStatsQuery();

  if (!hasApps || isLoading) return null;

  const { appCount, installCount } = stats;

  return (
    <InfoCardGrid>
      <InfoCard icon="application" label="Apps" title={appCount} />
      <InfoCard icon="download" label="Installs" title={installCount} />
    </InfoCardGrid>
  );
};

export default AppStatsCards;
