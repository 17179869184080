import { validateForUI } from 'utils/helpers/jsonValidator';

const properties = {
  testTenantId: {
    type: 'string'
  }
};

const versionTestTenantCreateSchema = {
  type: 'object',
  properties,
  required: ['testTenantId'],
  additionalProperties: false
};

// eslint-disable-next-line import/prefer-default-export
export const validateCreate = (params) =>
  validateForUI(versionTestTenantCreateSchema, params);
