import { appFromURL } from 'utils/helpers/apiHelper';

const Role = (props) => {
  const { appXAccount } = props;
  const app = appXAccount.apps.find((a) => a.appId === appFromURL());

  if (!app) return null;

  return <>{app.role}</>;
};

export default Role;
