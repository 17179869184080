import { useParams } from 'react-router-dom';

import { useListVersionSettingsQuery } from 'api';

export const useListVersionSettings = () => {
  const params = useParams();
  const versionId = params.id;
  const {
    data: versionSettings,
    isLoading,
    error
  } = useListVersionSettingsQuery(versionId);
  return {
    data: versionSettings?.data,
    total: versionSettings?.total,
    error,
    isLoading
  };
};
