import styled from 'styled-components';

const Sidebar = (props) => {
  const { children, isEmpty } = props;
  return <StyledSidebar isEmpty={isEmpty}>{children}</StyledSidebar>;
};

const StyledSidebar = styled.div`
  padding: 30px;
  display: ${({ isEmpty }) => (isEmpty ? 'none' : 'block')};
  background-color: ${({ theme }) => theme.c7__ui.secondaryBackgroundColor};
  border-top: 1px solid ${({ theme }) => theme.c7__ui.borderColor};

  [data-c7-form-item-wrapper],
  fieldset {
    margin-bottom: 15px;
  }

  @media ${({ theme }) => theme.c7__ui.breakpoints.largeUp} {
    width: 380px;
    min-width: 380px;
    display: block;
    border-left: 1px solid ${({ theme }) => theme.c7__ui.borderColor};
    border-top: none;
  }
`;

export default Sidebar;
