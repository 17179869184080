import styled from 'styled-components';

import Card from 'components/card';
import { Column } from 'components/columns';
import Heading from 'components/heading';
import { VividIcon } from 'components/icon';
import Text from 'components/text';

import AdminUICard from './resources/AdminUICard';

const Resources = () => (
  <Column span={4}>
    <Heading>Resources</Heading>
    <StyledLinkCard
      href="https://developer.commerce7.com/docs/app-development-center"
      target="_blank"
      rel="noreferrer"
    >
      <StyledCard>
        <VividIcon icon="hammer" color="orange" />
        <StyledHeading level={3}>App Development Docs</StyledHeading>
        <Text>
          Learn everything you need to know about creating an app in the dev
          center.
        </Text>
      </StyledCard>
    </StyledLinkCard>
    <StyledLinkCard
      href="https://developer.commerce7.com/docs/commerce7-apis"
      target="_blank"
      rel="noreferrer"
    >
      <StyledCard>
        <VividIcon icon="department" color="teal" />
        <StyledHeading level={3}>Api Docs</StyledHeading>
        <Text>
          Find all the available APIs and hooks that you can use to build what
          you need.
        </Text>
      </StyledCard>
    </StyledLinkCard>
    <AdminUICard />
  </Column>
);

const StyledCard = styled(Card)`
  padding: 20px;
  background-color: ${({ theme }) => theme.c7__ui.backgroundColor};

  &:hover {
    box-shadow: ${({ theme }) => theme.c7__ui.boxShadow};
    transform: translateY(-1px);
  }
`;

const StyledHeading = styled(Heading)`
  font-size: 18px;
  margin: 12px 0 5px 0;
`;

const StyledLinkCard = styled.a`
  display: block;
  margin-bottom: 20px;
`;

export default Resources;
