import { RadioGroup } from '@commerce7/admin-ui';
import get from 'lodash.get';

import useFormikContext from './useFormikContext';

const RadioButtonGroup = (props) => {
  const formik = useFormikContext();
  const { touched, errors } = formik;

  const { label, children, name, className } = props;

  let errorMessage;
  if (get(errors, name) && get(touched, name)) {
    errorMessage = get(errors, name);
  }

  return (
    <RadioGroup label={label} errorMessage={errorMessage} className={className}>
      {children}
    </RadioGroup>
  );
};

export default RadioButtonGroup;
