import { useFormikContext } from 'components/formik';

import { HOOK_TYPE } from '../../../utils/enums';

import CardForm from './extensionFormSwitch/CardForm';
import ContextMenuForm from './extensionFormSwitch/ContextMenuForm';
import PageForm from './extensionFormSwitch/PageForm';
import PrintReceiptForm from './extensionFormSwitch/PrintReceiptForm';
import TabMenuForm from './extensionFormSwitch/TabMenuForm';

const ExtensionFormSwitch = () => {
  const { values } = useFormikContext();

  switch (values.hookType) {
    case HOOK_TYPE.CONTEXT_MENU:
      return <ContextMenuForm />;

    case HOOK_TYPE.TAB_MENU:
      return <TabMenuForm />;

    case HOOK_TYPE.REPORT:
    case HOOK_TYPE.PAGE:
      return <PageForm />;

    case HOOK_TYPE.CARD:
      return <CardForm />;

    case HOOK_TYPE.PRINT_RECEIPT:
      return <PrintReceiptForm />;

    default:
      throw new Error('Unexpected hookType');
  }
};

export default ExtensionFormSwitch;
