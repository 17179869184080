import styled from 'styled-components';

import { useGetApp } from 'app/utils/hooks';
import { Columns } from 'components/columns';
import Heading from 'components/heading';
import { Page, AlternatePageHeader, PageContent } from 'components/page';
import Region from 'components/region';
import Text from 'components/text';

import AppStats from './components/view/AppStats';
import AppStatus from './components/view/AppStatus';
import ContextMenu from './components/view/ContextMenu';
import Listing from './components/view/Listing';
import SubmitAppButton from './components/view/SubmitAppButton';
import Versions from './components/view/Versions';

const View = () => {
  const { app, isLoading } = useGetApp();

  if (isLoading) return null;

  const { title, developerName, developerEmail } = app;

  return (
    <Page title="App">
      <PageContent>
        <AlternatePageHeader>
          <StyledHeader>
            <div>
              <StyledHeading level={1}>{title}</StyledHeading>
              <Text block>By {developerName}</Text>
              <Text secondary>{developerEmail}</Text>
              <ContextMenu />
            </div>
            <SubmitAppButton />
          </StyledHeader>
          <AppStats />
        </AlternatePageHeader>
        <Region>
          <AppStatus />
          <Columns>
            <Versions />
            <Listing />
          </Columns>
        </Region>
      </PageContent>
    </Page>
  );
};

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 5px;
`;

export default View;
