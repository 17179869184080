import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  useUpdateVersionWebHookMutation,
  useDeleteVersionWebHookMutation
} from 'api';
import { Button } from 'components/button';
import { Formik, ModalFormFooter } from 'components/formik';
import { Modal, ModalBody } from 'components/modal';
import { setModal } from 'modal/redux/modalSlice';
import { useGetVersion } from 'version/utils/hooks';

import FormGeneral from './components/common/FormGeneral';
import { validateUpdate } from './utils/validators';

const EditModal = (props) => {
  const { webhook } = props;
  const [isDeleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  const { version } = useGetVersion();
  const [updateVersionWebHook] = useUpdateVersionWebHookMutation();
  const [deleteVersionWebHook] = useDeleteVersionWebHookMutation();

  // If the username has changed, or edit password button pressed,
  // we want to resubmit install password and username credentials. updateInstallCredentials
  // gets passed into the transform data to do this.
  const [updateInstallCredentials, setUpdateInstallCredentials] =
    useState(false);

  const handleSubmit = async (values, formik) => {
    const data = transformData(values, updateInstallCredentials);
    const { error } = await updateVersionWebHook({
      data,
      versionId: version.id,
      webhookId: webhook.id
    });
    formik.setSubmitting(false);
    if (!error) {
      handleClose();
    }
  };

  const handleDelete = async () => {
    setDeleting(true);
    const { error } = await deleteVersionWebHook({
      versionId: version.id,
      webhookId: webhook.id
    });
    setDeleting(true);
    if (!error) {
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(setModal(null));
  };

  return (
    <Modal title="Edit Webhook" visible onClose={handleClose}>
      <Formik
        onSubmit={handleSubmit}
        validate={(values) => validate(values, updateInstallCredentials)}
        initialValues={getInitialValues(webhook)}
      >
        <ModalBody>
          <FormGeneral
            setUpdateInstallCredentials={setUpdateInstallCredentials}
          />
        </ModalBody>
        <ModalFormFooter saveButtonText="Save Webhook">
          <Button
            variant="secondary"
            startIcon="trash"
            onClick={handleDelete}
            loading={isDeleting}
          >
            Delete Webhook
          </Button>
        </ModalFormFooter>
      </Formik>
    </Modal>
  );
};

const transformData = (values, updateInstallCredentials) => {
  const data = {
    object: values.object,
    action: values.action,
    url: values.url
  };
  if (values.username && updateInstallCredentials) {
    data.username = values.username;
    data.password = values.password;
  }
  if (!values.username) {
    data.username = null;
    data.password = null;
  }
  return data;
};

const validate = (values, updateInstallCredentials) => {
  const data = transformData(values, updateInstallCredentials);
  const errors = validateUpdate(data);
  return errors;
};

const getInitialValues = (webhook) => {
  const initialValues = {
    object: webhook.object,
    action: webhook.action,
    url: webhook.url,
    username: webhook.username,
    password: ''
  };
  return initialValues;
};

export default EditModal;
