import { validateForUI } from 'utils/helpers/jsonValidator';

import { OBJECT, ACTION } from './enums';

const properties = {
  object: {
    type: 'string',
    enum: Object.keys(OBJECT)
  },
  action: {
    type: 'string',
    enum: Object.keys(ACTION)
  },
  url: { type: 'string', format: 'uri' },
  username: { type: ['string', 'null'] },
  password: { type: ['string', 'null'] }
};

const versionWebHookCreateSchema = {
  type: 'object',
  properties,
  required: ['object', 'action', 'url'],
  additionalProperties: false
};

export const validateCreate = (params) =>
  validateForUI(versionWebHookCreateSchema, params, customValidate(params));

export const validateUpdate = (params) =>
  validateForUI(versionWebHookCreateSchema, params, customValidate(params));

const customValidate = (params) => {
  const errorArray = [];

  if (params.username && (!params.password || params.password.length === 0)) {
    errorArray.push({
      field: 'password',
      message: 'If adding a username, the password is required'
    });
  }
  if (params.password && (!params.username || params.username.length === 0)) {
    errorArray.push({
      field: 'username',
      message: 'If adding a password, the username is required'
    });
  }
  if (
    [
      OBJECT.ALLOCATION,
      OBJECT.CART,
      OBJECT.CLUB,
      OBJECT.CLUB_PACKAGE,
      OBJECT.CLUB_SIGNUP,
      OBJECT.COLLECTION,
      OBJECT.COUPON,
      OBJECT.CUSTOMER_ADDRESS,
      OBJECT.CUSTOMER_CREDIT_CARD,
      OBJECT.GIFT_CARD,
      OBJECT.INVENTORY_LOCATION,
      OBJECT.NOTE,
      OBJECT.PRODUCT,
      OBJECT.PROMOTION,
      OBJECT.PROMOTION_SET,
      OBJECT.TAG
    ].includes(params.object)
  ) {
    if (
      ![ACTION.CREATE, ACTION.UPDATE, ACTION.DELETE].includes(params.action)
    ) {
      errorArray.push({
        field: 'action',
        message: `Action must be Create, Update, or Delete if object is ${params.object}`
      });
    }
  }
  if (
    [
      OBJECT.CLUB_MEMBERSHIP,
      OBJECT.CUSTOMER,
      OBJECT.ORDER,
      OBJECT.RESERVATION
    ].includes(params.object)
  ) {
    if (
      ![
        ACTION.CREATE,
        ACTION.UPDATE,
        ACTION.DELETE,
        ACTION.BULK_UPDATE
      ].includes(params.action)
    ) {
      errorArray.push({
        field: 'action',
        message: `Action must be Create, Update, Bulk Update, or Delete if object is ${params.object}`
      });
    }
  }
  if ([OBJECT.ORDER_FULFILLMENT].includes(params.object)) {
    if (![ACTION.CREATE, ACTION.DELETE].includes(params.action)) {
      errorArray.push({
        field: 'action',
        message: `Action must be Create or Delete if object is ${params.object}`
      });
    }
  }
  if ([OBJECT.TRANSACTION_EMAIL].includes(params.object)) {
    if (![ACTION.SEND].includes(params.action)) {
      errorArray.push({
        field: 'action',
        message: `Action must be Send if object is ${params.object}`
      });
    }
  }
  if ([OBJECT.QUERY].includes(params.object)) {
    if (![ACTION.EXECUTE].includes(params.action)) {
      errorArray.push({
        field: 'action',
        message: `Action must be Execute if object is ${params.object}`
      });
    }
  }

  return errorArray;
};
