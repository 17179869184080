import { validateForUI } from 'utils/helpers/jsonValidator';

const screenShotSchema = {
  type: 'object',
  properties: {
    image: { type: 'string', format: 'uri' },
    caption: { type: ['string', 'null'] }
  },
  required: ['image'],
  additionalProperties: false
};

const properties = {
  title: { type: 'string' },
  developerName: { type: ['string', 'null'] },
  developerEmail: { type: ['string', 'null'], format: 'email' },
  category: {
    type: 'string',
    enum: ['Reporting', 'Website', 'Shipping', 'Marketing', 'Other']
  },
  logo: { type: ['string', 'null'], format: 'uri' },
  darkModeLogo: { type: ['string', 'null'], format: 'uri' },
  salesDescription: { type: ['string', 'null'], maxLength: 255 },
  websiteUrl: { type: ['string', 'null'], format: 'uri' },
  description: { type: 'string', pattern: '.*' },
  screenShots: { type: 'array', items: screenShotSchema },
  supportEmail: { type: ['string', 'null'], format: 'email' },
  // TODO - validate phone
  supportPhone: { type: 'string' },
  documentationUrl: { type: ['string', 'null'], format: 'uri' },
  instructions: { type: 'string', pattern: '.*' },
  isSubscriptionToAnotherSoftwareVendorRequired: { type: 'boolean' }
};

const appTempListingUpdateSchema = {
  type: 'object',
  properties,
  required: ['title', 'category'],
  additionalProperties: false
};

// eslint-disable-next-line import/prefer-default-export
export const validateUpdate = (params) =>
  validateForUI(appTempListingUpdateSchema, params);
