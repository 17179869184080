import { validateForUI } from 'utils/helpers/jsonValidator';

import { DATA_TYPE } from './enums';

const properties = {
  title: { type: 'string' },
  code: {
    type: 'string'
  },
  dataType: {
    type: 'string',
    enum: Object.keys(DATA_TYPE)
  },
  isRequired: {
    type: 'boolean'
  },
  sortOrder: {
    type: 'integer',
    minimum: 0
  },
  options: {
    type: 'array',
    items: { type: ['string', 'null'] },
    uniqueItems: true,
    minItems: 0
  }
};

const versionSettingCreateSchema = {
  type: 'object',
  properties,
  required: ['title', 'dataType', 'isRequired', 'sortOrder'],
  additionalProperties: false
};

export const validateCreate = (params) =>
  validateForUI(versionSettingCreateSchema, params, customValidate(params));

const customValidate = (params) => {
  const errorArray = [];
  switch (params.dataType) {
    case DATA_TYPE.SELECT:
      if (!params.options || params.options.length === 0)
        errorArray.push({
          field: 'dataType',
          message: 'Requires options'
        });
      break;
    case DATA_TYPE.RADIO:
    case DATA_TYPE.CHECKBOX:
      if (!params.options || params.options.length < 2)
        errorArray.push({
          field: 'dataType',
          message: 'Requires at least 2 options'
        });
      break;
    default:
      if (params.options && params.options.length > 0)
        errorArray.push({
          field: 'dataType',
          message: "Can't have options"
        });
  }

  const uniqueArray = [];
  if (params.options?.length > 0) {
    params.options.forEach((option, idx) => {
      if (!option || option.trim().length === 0) {
        errorArray.push({
          field: `options[${idx}]`,
          message: 'Cannot be empty'
        });
      } else if (uniqueArray.includes(option)) {
        errorArray.push({
          field: `options[${idx}]`,
          message: 'Duplicate option'
        });
      }
      uniqueArray.push(option);
    });
  }
  return errorArray;
};
