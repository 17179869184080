/* globals document */
import { useEffect } from 'react';

import ReduxModalListener from 'modal/Modals';

const Page = (props) => {
  const { title, children } = props;

  useEffect(() => {
    if (title) {
      document.title = `Commerce7 | ${title}`;
    }
    return () => {
      document.title = 'Commerce7';
    };
  }, [title]);

  return (
    <>
      {children}
      <ReduxModalListener />
    </>
  );
};

export default Page;
