import { addFlashMessage } from 'flashMessage/redux/flashMessageSlice';
import { removeAllApiErrors } from 'layout/redux/layoutSlice';

const listAppXAccounts = (build) =>
  build.query({
    query: () => ({
      url: `/app-x-account`,
      method: 'GET'
    }),
    providesTags: () => {
      const tags = [{ type: 'AppXAccount', id: 'LIST' }];
      return tags;
    }
  });

const inviteAccountToApp = (build) =>
  build.mutation({
    query: (data) => ({
      url: `/app-x-account/invite`,
      method: 'POST',
      data
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({
          message: 'Account Invited!',
          type: 'success'
        })
      );
    }
  });

const updateAppAccount = (build) =>
  build.mutation({
    query: ({ id, data }) => ({
      url: `/app-x-account/${id}/role`,
      method: 'PUT',
      data
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({
          message: 'Account Updated!',
          type: 'success'
        })
      );
    },
    invalidatesTags: () => [{ type: 'AppXAccount', id: 'LIST' }]
  });

const deleteAppAccount = (build) =>
  build.mutation({
    query: (id) => ({
      url: `/app-x-account/${id}`,
      method: 'DELETE'
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({
          message: 'Account Deleted!',
          type: 'success'
        })
      );
    },
    invalidatesTags: () => [{ type: 'AppXAccount', id: 'LIST' }]
  });

const appXAccountApi = {
  listAppXAccounts,
  inviteAccountToApp,
  updateAppAccount,
  deleteAppAccount
};
export default appXAccountApi;
