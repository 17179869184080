import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Icon from 'components/icon';
import { toggleLeftNavigation } from 'layout/redux/layoutSlice';

const CloseNavButton = () => {
  const dispatch = useDispatch();

  const handleCloseNav = () => {
    dispatch(toggleLeftNavigation(false));
  };
  return (
    <StyledCloseNavButton>
      <Icon icon="close" onClick={handleCloseNav} />
    </StyledCloseNavButton>
  );
};

const StyledCloseNavButton = styled.div`
  padding: 14px;
  border-bottom: 1px solid ${({ theme }) => theme.c7__ui.borderColor};
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;

  @media ${({ theme }) => theme.c7__ui.breakpoints.largeUp} {
    display: none;
  }
`;

export default CloseNavButton;
