import styled from 'styled-components';

import useQuery from 'utils/hooks/useQuery';

import Tag from './components/Tag';

const Tags = (props) => {
  const query = useQuery();
  const { filters, onFilter } = props;

  const handleRemoveFilter = (filterKey) => {
    onFilter({ key: filterKey, value: '' });
  };

  if (!filters) {
    return null;
  }

  const activeFilters = [];

  Object.keys(query).forEach((queryKey) => {
    const filter = filters[queryKey];
    const value = query[queryKey];

    if (filter && value) {
      activeFilters.push({
        ...filter,
        valueType: filter.value?.type,
        value
      });
    }
  });

  if (!activeFilters?.length) {
    return null;
  }

  return (
    <TagsStyles>
      {activeFilters.map((filter) => (
        <Tag
          key={filter.key.key}
          filter={filter}
          handleRemoveFilter={handleRemoveFilter}
        />
      ))}
    </TagsStyles>
  );
};

const TagsStyles = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
`;

export default Tags;
