import { addFlashMessage } from 'flashMessage/redux/flashMessageSlice';
import { removeAllApiErrors } from 'layout/redux/layoutSlice';

const listVersionExtensions = (build) =>
  build.query({
    query: (versionId) => ({
      url: `/version/${versionId}/extension`,
      method: 'GET'
    }),
    providesTags: () => {
      const tags = [{ type: 'VersionExtension', id: 'LIST' }];
      return tags;
    }
  });

const createVersionExtension = (build) =>
  build.mutation({
    query: ({ data, versionId }) => ({
      url: `/version/${versionId}/extension`,
      method: 'POST',
      data
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({
          message: 'Version Extension Created.',
          type: 'success'
        })
      );
    },
    invalidatesTags: () => [{ type: 'VersionExtension', id: 'LIST' }]
  });

const updateVersionExtension = (build) =>
  build.mutation({
    query: ({ data, versionId, extensionId }) => ({
      url: `/version/${versionId}/extension/${extensionId}`,
      method: 'PUT',
      data
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({
          message: 'Version Extension Updated.',
          type: 'success'
        })
      );
    },
    invalidatesTags: () => [{ type: 'VersionExtension', id: 'LIST' }]
  });

const deleteVersionExtension = (build) =>
  build.mutation({
    query: ({ versionId, extensionId }) => ({
      url: `/version/${versionId}/extension/${extensionId}`,
      method: 'DELETE'
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({
          message: 'Version Extension Deleted.',
          type: 'success'
        })
      );
    },
    invalidatesTags: () => [{ type: 'VersionExtension', id: 'LIST' }]
  });

const versionExtensionApi = {
  listVersionExtensions,
  createVersionExtension,
  updateVersionExtension,
  deleteVersionExtension
};
export default versionExtensionApi;
