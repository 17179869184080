import { useParams } from 'react-router-dom';

import { useListVersionsQuery, useGetVersionQuery } from 'api';
import { APP_STATUS } from 'app/utils/enums';
import { useGetApp } from 'app/utils/hooks';
import { STATUS } from 'version/utils/enums';

export const useListVersions = () => {
  const { data: versions, isLoading, error } = useListVersionsQuery();
  return {
    data: versions?.data,
    total: versions?.total,
    error,
    isLoading
  };
};

export const useListSortedVersions = () => {
  const { data, isLoading } = useListVersions();
  let sortedVersions;
  if (data) {
    const versions = [...data];
    sortedVersions = versions.sort((a, b) => b.versionNumber - a.versionNumber);
  }
  return { data: sortedVersions, isLoading };
};

export const useGetVersion = () => {
  const params = useParams();
  const versionId = params.id;
  const { data: version, isLoading } = useGetVersionQuery(versionId);
  const isApproved = version?.status === STATUS.APPROVED;
  return { version, isLoading, isApproved };
};

export const useGetLatestVersion = () => {
  const { data, isLoading } = useListSortedVersions();

  if (!data) return { latestVersion: null, isLoading };

  const [latestVersion] = data;
  return { latestVersion, isLoading };
};

export const useCanAddVersion = () => {
  const { data, isLoading } = useListVersions();
  let canAddVersion = true;
  if (data) {
    data.forEach((version) => {
      if (version.status !== STATUS.APPROVED) {
        canAddVersion = false;
      }
    });
  }
  return { canAddVersion, isLoading };
};

export const useHasApprovedVersion = () => {
  const { data, isLoading } = useListVersions();
  let hasApprovedVersion = false;
  if (data) {
    data.forEach((version) => {
      if (version.status === STATUS.APPROVED) {
        hasApprovedVersion = true;
      }
    });
  }
  return { hasApprovedVersion, isLoading };
};

export const useCanEditCurrency = () => {
  const { app, isLoading: appLoading } = useGetApp();
  const { version, isLoading: versionLoading } = useGetVersion();

  let canEditCurrency = false;

  if (
    !appLoading &&
    !versionLoading &&
    app.status !== APP_STATUS.LIVE &&
    version.versionNumber === 1 &&
    version.status === STATUS.PENDING
  ) {
    canEditCurrency = true;
  }

  return { canEditCurrency };
};
