import { useGetApp } from 'app/utils/hooks';
import Text from 'components/text';
import { currencyFormat } from 'utils/helpers/numberHelper';

const Total = (props) => {
  const { billing } = props;
  const { app, isLoading } = useGetApp();

  if (isLoading) return null;

  const { currency } = app;
  const { convertedPrice } = billing;

  return (
    <Text>
      {currency} {currencyFormat(convertedPrice, currency)}
    </Text>
  );
};

export default Total;
