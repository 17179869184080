import { createApi } from '@reduxjs/toolkit/query/react';

import App from 'app/redux/appApi';
import AppTempListing from 'appTempListing/redux/appTempListingApi';
import AppXAccount from 'appXAccount/redux/appXAccountApi';
import AppXTenant from 'appXTenant/redux/appXTenantApi';
import Billing from 'billing/redux/billingApi';
import ExchangeRate from 'exchangeRate/redux/exchangeRateApi';
import PrivateTenant from 'privateTenant/redux/privateTenantApi';
import Stats from 'stats/redux/statsApi';
import { axiosBaseQuery, getRootUrl } from 'utils/helpers/apiHelper';
// eslint-disable-next-line import/no-cycle
import Version from 'version/redux/versionApi';
import VersionExtension from 'versionExtension/redux/versionExtensionApi';
import VersionSecurity from 'versionSecurity/redux/versionSecurityApi';
import VersionSetting from 'versionSetting/redux/versionSettingApi';
import VersionTestTenant from 'versionTestTenant/redux/versionTestTenantApi';
import VersionWebHook from 'versionWebHook/redux/versionWebHookApi';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({
    baseUrl: `${getRootUrl()}`
  }),
  tagTypes: [
    'App',
    'AppTempListing',
    'AppXTenant',
    'AppXAccount',
    'Billing',
    'ExchangeRate',
    'PrivateTenant',
    'Stats',
    'Version',
    'VersionExtension',
    'VersionSecurity',
    'VersionSetting',
    'VersionTestTenant',
    'VersionWebHook'
  ],
  endpoints: (build) => ({
    /* App */
    listApps: App.listApps(build),
    getApp: App.getApp(build),
    getAppStats: App.getAppStats(build),
    createApp: App.createApp(build),
    updateApp: App.updateApp(build),
    deleteApp: App.deleteApp(build),
    updateAppSecret: App.updateAppSecret(build),

    /* AppTempListing */
    getAppTempListing: AppTempListing.getAppTempListing(build),
    updateAppTempListing: AppTempListing.updateAppTempListing(build),
    submitAppTempListing: AppTempListing.submitAppTempListing(build),

    /* AppXAccount */
    listAppXAccounts: AppXAccount.listAppXAccounts(build),
    inviteAccountToApp: AppXAccount.inviteAccountToApp(build),
    updateAppAccount: AppXAccount.updateAppAccount(build),
    deleteAppAccount: AppXAccount.deleteAppAccount(build),

    /* AppXTenant */
    listAppXTenants: AppXTenant.listAppXTenants(build),
    updateTenantMonthlyFee: AppXTenant.updateTenantMonthlyFee(build),

    /* Billing */
    listBillings: Billing.listBillings(build),

    /* Exchange Rate */
    listExchangeRates: ExchangeRate.listExchangeRates(build),

    /* Private Tenant */
    listPrivateTenants: PrivateTenant.listPrivateTenants(build),
    createPrivateTenant: PrivateTenant.createPrivateTenant(build),
    deletePrivateTenant: PrivateTenant.deletePrivateTenant(build),

    /* Stats */
    getStats: Stats.getStats(build),

    /* Version */
    listVersions: Version.listVersions(build),
    getVersion: Version.getVersion(build),
    duplicateVersion: Version.duplicateVersion(build),
    submitVersion: Version.submitVersion(build),
    updateVersion: Version.updateVersion(build),
    deleteVersion: Version.deleteVersion(build),

    /* Version Extension */
    listVersionExtensions: VersionExtension.listVersionExtensions(build),
    createVersionExtension: VersionExtension.createVersionExtension(build),
    updateVersionExtension: VersionExtension.updateVersionExtension(build),
    deleteVersionExtension: VersionExtension.deleteVersionExtension(build),

    /* Version Security */
    listVersionSecurities: VersionSecurity.listVersionSecurities(build),
    updateVersionSecurityBulk: VersionSecurity.updateVersionSecurityBulk(build),

    /* Version Setting */
    listVersionSettings: VersionSetting.listVersionSettings(build),
    createVersionSetting: VersionSetting.createVersionSetting(build),
    updateVersionSetting: VersionSetting.updateVersionSetting(build),
    deleteVersionSetting: VersionSetting.deleteVersionSetting(build),
    reorderVersionSettings: VersionSetting.reorderVersionSettings(build),

    /* Version Test Tenant */
    listVersionTestTenants: VersionTestTenant.listVersionTestTenants(build),
    createVersionTestTenant: VersionTestTenant.createVersionTestTenant(build),
    deleteVersionTestTenant: VersionTestTenant.deleteVersionTestTenant(build),

    /* Version Web Hook */
    listVersionWebHooks: VersionWebHook.listVersionWebHooks(build),
    createVersionWebHook: VersionWebHook.createVersionWebHook(build),
    updateVersionWebHook: VersionWebHook.updateVersionWebHook(build),
    deleteVersionWebHook: VersionWebHook.deleteVersionWebHook(build)
  })
});

export const {
  /* App */
  useListAppsQuery,
  useGetAppQuery,
  useGetAppStatsQuery,
  useCreateAppMutation,
  useUpdateAppMutation,
  useDeleteAppMutation,
  useUpdateAppSecretMutation,

  /* AppTempListing */
  useGetAppTempListingQuery,
  useUpdateAppTempListingMutation,
  useSubmitAppTempListingMutation,

  /* AppXAccount */
  useListAppXAccountsQuery,
  useInviteAccountToAppMutation,
  useUpdateAppAccountMutation,
  useDeleteAppAccountMutation,

  /* AppXTenant */
  useListAppXTenantsQuery,
  useUpdateTenantMonthlyFeeMutation,

  /* Billing */
  useListBillingsQuery,

  /* Exchange Rate */
  useListExchangeRatesQuery,

  /* Private Tenant */
  useListPrivateTenantsQuery,
  useCreatePrivateTenantMutation,
  useDeletePrivateTenantMutation,

  /* Stats */
  useGetStatsQuery,

  /* Version */
  useListVersionsQuery,
  useGetVersionQuery,
  useDuplicateVersionMutation,
  useSubmitVersionMutation,
  useUpdateVersionMutation,
  useDeleteVersionMutation,

  /* Version Extension */
  useListVersionExtensionsQuery,
  useCreateVersionExtensionMutation,
  useUpdateVersionExtensionMutation,
  useDeleteVersionExtensionMutation,

  /* Version Security */
  useListVersionSecuritiesQuery,
  useUpdateVersionSecurityBulkMutation,

  /* Version Setting */
  useListVersionSettingsQuery,
  useCreateVersionSettingMutation,
  useUpdateVersionSettingMutation,
  useDeleteVersionSettingMutation,
  useReorderVersionSettingsMutation,

  /* Version Test Tenant */
  useListVersionTestTenantsQuery,
  useCreateVersionTestTenantMutation,
  useDeleteVersionTestTenantMutation,

  /* Version Web Hook */
  useListVersionWebHooksQuery,
  useCreateVersionWebHookMutation,
  useUpdateVersionWebHookMutation,
  useDeleteVersionWebHookMutation
} = api;
