import styled from 'styled-components';

import { InfoCard, InfoCardGrid } from 'components/infoCard';
import { currencyFormat } from 'utils/helpers/numberHelper';
import { useHasApprovedVersion } from 'version/utils/hooks';

import { useGetAppStats, useGetApp } from '../../utils/hooks';

const AppStats = () => {
  const { data, isLoading } = useGetAppStats();
  const { app, isLoading: appIsLoading } = useGetApp();
  const { hasApprovedVersion, isLoading: versionLoading } =
    useHasApprovedVersion();

  if (isLoading || appIsLoading || versionLoading) return null;
  if (!hasApprovedVersion) return null;

  const { total, monthlyRevenue } = data;
  const { currency } = app;

  return (
    <StyledInfoCardGrid>
      <InfoCard
        icon="download"
        label="Installs"
        title={total}
        card="installs"
        variant="warning"
      />
      <InfoCard
        icon="dollar"
        label="Monthly Revenue"
        title={`${currency} ${currencyFormat(monthlyRevenue, currency)}`}
        card="revenue"
        variant="success"
      />
    </StyledInfoCardGrid>
  );
};

const StyledInfoCardGrid = styled(InfoCardGrid)`
  margin-top: 20px;
`;

export default AppStats;
