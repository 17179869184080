import { isRejectedWithValue } from '@reduxjs/toolkit';

// export const rtkQueryErrorLogger = (_api) => (next) => (action) => {
export const rtkQueryErrorLogger = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    // console.warn(action);
  }

  return next(action);
};
