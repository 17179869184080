import { useGetApp } from 'app/utils/hooks';
import { SearchTools } from 'components/grid';
import Heading from 'components/heading';
import { Page, PageContent, PageSidebar } from 'components/page';
import { Pagination } from 'components/pagination';
import TitleAction from 'components/titleAction';
import ExchangeRates from 'exchangeRate/View';

import Grid from './components/list/Grid';
import { filters } from './utils/filters';
import { useListBillings } from './utils/hooks';

const List = () => {
  const { isLoading } = useGetApp();

  return (
    <Page title="Billing">
      <PageContent>
        <TitleAction>
          <Heading level={1}>Billing</Heading>
        </TitleAction>
        <SearchTools label="by tenant" filters={filters} />
        <Grid />
        <Pagination listHook={useListBillings} />
      </PageContent>
      <PageSidebar>{!isLoading && <ExchangeRates />}</PageSidebar>
    </Page>
  );
};

export default List;
