import { Field, Input } from 'components/formik';

const FormGeneral = () => (
  <>
    <Field
      autoFocus
      name="email"
      label="Email"
      type="email"
      placeholder="name@gmail.com"
      component={Input}
    />
    <Field name="password" label="Password" type="password" component={Input} />
  </>
);

export default FormGeneral;
