import { Grid } from 'components/grid';
import NoRecords from 'components/noRecords';
import { dateFormat } from 'utils/helpers/dateHelper';

import { useListBillings } from '../../utils/hooks';

import Price from './grid/Price';
import Total from './grid/Total';

const BillingsGrid = () => {
  const rowHeaders = [
    { label: 'Invoice Date' },
    { label: 'Tenant' },
    { label: 'Version', align: 'right' },
    { label: 'Price', align: 'right' },
    { label: 'Total', align: 'right' }
  ];

  const rowBody = [
    {
      data: 'invoiceDate',
      type: 'function',
      func: dateFormat
    },
    {
      data: 'tenantName'
    },
    {
      data: 'versionNumber',
      align: 'right'
    },
    {
      data: 'price',
      props: 'billing',
      type: 'component',
      component: Price,
      align: 'right'
    },
    {
      data: 'total',
      props: 'billing',
      type: 'component',
      component: Total,
      align: 'right'
    }
  ];

  const customNoRecords = <NoRecords title="There aren't any invoices yet." />;

  return (
    <Grid
      rowHeaders={rowHeaders}
      rowBody={rowBody}
      listHook={useListBillings}
      customNoRecords={customNoRecords}
    />
  );
};

export default BillingsGrid;
