import { useGetAppTempListingQuery } from 'api';

export const useGetAppTempListing = () => {
  const { data: appTempListing, isLoading } = useGetAppTempListingQuery();

  let created = false;
  let complete = false;
  if (appTempListing) {
    const {
      logo,
      salesDescription,
      description,
      websiteUrl,
      supportEmail,
      screenShots,
      darkModeLogo,
      documentationUrl,
      instructions,
      supportPhone
    } = appTempListing;
    if (
      logo ||
      darkModeLogo ||
      description ||
      salesDescription ||
      documentationUrl ||
      instructions ||
      websiteUrl ||
      supportEmail ||
      supportPhone ||
      screenShots?.length > 0
    ) {
      created = true;
    }
    if (
      logo &&
      salesDescription &&
      description &&
      websiteUrl &&
      supportEmail &&
      screenShots?.length >= 3
    ) {
      complete = true;
    }
  }

  return { appTempListing, isLoading, created, complete };
};
