import { Nav } from '@commerce7/admin-ui';
import { NavLink as RouterNavLink, useLocation } from 'react-router-dom';

const { SubNavLink: UISubNavLink } = Nav;

const SubNavLink = (props) => {
  const {
    children,
    to,
    isActive,
    hideSidebarOnClick,
    onClick,
    className,
    ...rest
  } = props;

  const location = useLocation();

  const customProps = {};
  if (to) {
    customProps.component = RouterNavLink;
    customProps.to = to;
  }

  let newClassName = className;
  if (isActive) {
    if (typeof isActive === 'boolean') {
      newClassName = `${newClassName} active`;
    }
    if (typeof isActive === 'function') {
      if (isActive(location)) {
        newClassName = `${newClassName} active`;
      }
    }
  }

  return (
    <UISubNavLink
      onClick={onClick}
      className={newClassName}
      {...customProps} // eslint-disable-line
      {...rest} // eslint-disable-line
    >
      {children}
    </UISubNavLink>
  );
};

export default SubNavLink;
