import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useUpdateVersionMutation } from 'api';
import { useGetApp } from 'app/utils/hooks';
import { FormFooter, Formik } from 'components/formik';
import { addFlashMessage } from 'flashMessage/redux/flashMessageSlice';
import {
  convertCurrencyStringToCents,
  decimalFormat
} from 'utils/helpers/numberHelper';

import { useCanEditCurrency, useGetVersion } from '../../utils/hooks';
import { validateUpdate } from '../../utils/validators';

import FormGeneral from './FormGeneral';

const InstallationForm = () => {
  const { version } = useGetVersion();
  const { app, isLoading, isFetching } = useGetApp(true);
  const [updateVersion] = useUpdateVersionMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { canEditCurrency } = useCanEditCurrency();

  // If the installUsername has changed, or edit password button pressed,
  // we want to resubmit install password and username credentials. updateInstallCredentials
  // gets passed into the transform data to do this.
  const [updateInstallCredentials, setUpdateInstallCredentials] =
    useState(false);

  if (isLoading || isFetching) {
    return null;
  }

  const handleSubmit = async (values, formik) => {
    const data = transformData(
      values,
      updateInstallCredentials,
      canEditCurrency
    );
    const { error } = await updateVersion({ data, versionId: version.id });
    formik.setSubmitting(false);
    if (!error) {
      dispatch(
        addFlashMessage({ message: 'Version Updated!', type: 'success' })
      );
    }
  };

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={(values) =>
        validate(values, updateInstallCredentials, canEditCurrency)
      }
      initialValues={getInitialValues(version, app)}
    >
      <FormGeneral setUpdateInstallCredentials={setUpdateInstallCredentials} />
      <FormFooter
        saveButtonText="Save Installation Settings"
        onCancel={handleCancel}
      />
    </Formik>
  );
};

const transformData = (values, updateInstallCredentials, canEditCurrency) => {
  const data = {
    monthlyFee: convertCurrencyStringToCents(values.monthlyFee),
    monthlyFeeAUD: convertCurrencyStringToCents(values.monthlyFeeAUD),
    monthlyFeeCAD: convertCurrencyStringToCents(values.monthlyFeeCAD),
    monthlyFeeEUR: convertCurrencyStringToCents(values.monthlyFeeEUR),
    monthlyFeeGBP: convertCurrencyStringToCents(values.monthlyFeeGBP),
    monthlyFeeNZD: convertCurrencyStringToCents(values.monthlyFeeNZD),
    monthlyFeeZAR: convertCurrencyStringToCents(values.monthlyFeeZAR),
    setupFee: convertCurrencyStringToCents(values.setupFee),
    paymentHandledBy: values.paymentHandledBy,
    currency: values.currency,
    installUrl: values.installUrl || null,
    uninstallUrl: values.uninstallUrl || null,
    settingUrl: values.settingUrl || null,
    isDeveloperRequiredForInstallation:
      values.isDeveloperRequiredForInstallation
  };
  if (values.installUsername && updateInstallCredentials) {
    data.installUsername = values.installUsername;
    data.installPassword = values.installPassword;
  }
  if (!values.installUsername) {
    data.installUsername = null;
    data.installPassword = null;
  }
  if (!canEditCurrency) {
    delete data.currency;
    delete data.paymentHandledBy;
  }
  return data;
};

const validate = (values, updateInstallCredentials, canEditCurrency) => {
  const data = transformData(values, updateInstallCredentials, canEditCurrency);
  const errors = validateUpdate(data, values);
  return errors;
};

const getInitialValues = (version, app) => {
  const initialValues = {
    monthlyFee: decimalFormat(version.monthlyFee) || decimalFormat(0),
    monthlyFeeAUD: decimalFormat(version.monthlyFeeAUD) || decimalFormat(0),
    monthlyFeeCAD: decimalFormat(version.monthlyFeeCAD) || decimalFormat(0),
    monthlyFeeEUR: decimalFormat(version.monthlyFeeEUR) || decimalFormat(0),
    monthlyFeeGBP: decimalFormat(version.monthlyFeeGBP) || decimalFormat(0),
    monthlyFeeNZD: decimalFormat(version.monthlyFeeNZD) || decimalFormat(0),
    monthlyFeeZAR: decimalFormat(version.monthlyFeeZAR) || decimalFormat(0),
    setupFee: decimalFormat(version.setupFee) || decimalFormat(0),
    currency: app.currency || '',
    installUrl: version.installUrl || '',
    installUsername: version.installUsername || '',
    installPassword: '',
    uninstallUrl: version.uninstallUrl || '',
    settingUrl: version.settingUrl || '',
    paymentHandledBy: app.paymentHandledBy,
    isDeveloperRequiredForInstallation:
      version.isDeveloperRequiredForInstallation
  };
  return initialValues;
};

export default InstallationForm;
