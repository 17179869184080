import { Link } from 'react-router-dom';

import Icon from 'components/icon';

import UserDropdownMenuItem from './UserDropdownMenuItem';

const SignOut = () => (
  <UserDropdownMenuItem>
    <Link to="/logout">
      <Icon icon="logOut" />
      Sign Out
    </Link>
  </UserDropdownMenuItem>
);

export default SignOut;
