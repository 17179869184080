import { useEffect } from 'react';
import styled from 'styled-components';

import { useFormikContext } from 'components/formik';

import ScreenshotUploader from './screenshotsFieldArray/ScreenshotUploader';

const ScreenshotsFieldArray = (props) => {
  const { replace, push, remove } = props;
  const { values } = useFormikContext();
  const { screenShots } = values;

  // populate at least 3 uploaders.
  useEffect(() => {
    if (screenShots.length < 3) {
      push({ image: '', caption: '' });
    }
  }, [screenShots.length, push]);

  // if all uploaders have images add another one
  useEffect(() => {
    let addAnotherUploader = true;
    screenShots.forEach((screenShot) => {
      if (!screenShot.image) {
        addAnotherUploader = false;
      }
    });
    if (addAnotherUploader) {
      push({ image: '', caption: '' });
    }
  }, [screenShots, push]);

  return (
    <StyledScreenshotFieldArray>
      {screenShots.map((screenShot, index) => (
        <ScreenshotUploader
          index={index}
          remove={remove}
          replace={replace}
          key={`${screenShot.image}-${index.toString()}`}
        />
      ))}
    </StyledScreenshotFieldArray>
  );
};

const StyledScreenshotFieldArray = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export default ScreenshotsFieldArray;
