import Tag from 'components/tag';

import { STATUS_TRANSLATED_FOR_DB } from '../../../utils/enums';

const TenantStatus = (props) => {
  const { appXTenant } = props;
  const { tenantStatus } = appXTenant;
  return <Tag>{STATUS_TRANSLATED_FOR_DB[tenantStatus]}</Tag>;
};

export default TenantStatus;
