import { Icon } from '@commerce7/admin-ui';
import styled from 'styled-components';

import { Button } from 'components/button';

const FiltersToggleButton = styled(Button).attrs(() => ({
  variant: 'text'
}))`
  width: 100%;
  height: 44px;
  min-width: 225px;
  padding: 0 15px;
  border: none;
  font-weight: ${({ theme }) => theme.c7__ui.fontWeightBase};
  > span {
    justify-content: space-between;
  }
`;

const FilterStyles = styled.div`
  position: relative;
  width: 100%;
  background: transparent;
  &:not(:last-of-type) {
    border-right: 1px solid ${({ theme }) => theme.c7__ui.borderColor};
  }
`;

const FilterDropdownStyles = styled.div`
  background: ${({ theme }) => theme.c7__ui.backgroundColor};
  border: 1px solid ${({ theme }) => theme.c7__ui.borderColor};
  box-shadow: ${({ theme }) => theme.c7__ui.boxShadow};
  box-sizing: border-box;
  min-width: calc(100% + 2px);
  max-width: calc(100% + 2px);
  z-index: 998;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  position: absolute;
  top: 100%;
  left: -1px;
  padding: 15px;
  transition: all 0.2s ease-in-out;

  ${({ visible }) => `
    opacity: ${visible ? '1' : '0'};
    visibility: ${visible ? 'visible' : 'hidden'};
    transform: ${visible ? `translateY(0px)` : `translateY(-10px)`};
  `}
`;

const HiddenFilterButtonStyles = styled(Button)`
  height: auto;
  padding: 10px 0;
  width: 100%;
  margin: 0 !important;
  text-align: left;
  justify-content: space-between;
  border: none;
  > span {
    justify-content: flex-start;
  }
`;

const HiddenFilterDropdownItemStyles = styled.div``;

const DropdownIcon = styled(Icon)`
  transition: all 0.3s ease-in-out;
  ${({ rotate }) => `
    transform: ${rotate ? 'rotate(180deg)' : 'rotate(0deg)'};
  `}
`;

export {
  FilterStyles,
  FiltersToggleButton,
  FilterDropdownStyles,
  HiddenFilterDropdownItemStyles,
  HiddenFilterButtonStyles,
  DropdownIcon
};
