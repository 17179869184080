import { Html } from 'ui';

const FormikHtml = (props) => {
  const {
    label,
    placeholder,
    field,
    form,
    autoFocus,
    onChange,
    onBlur,
    height,
    tinymceConfig
  } = props;
  const { onBlur: onFieldBlur, onChange: onFieldChange, value, name } = field;
  const { errors, touched } = form;

  const handleOnChange = (e) => {
    if (onChange) {
      onChange(e);
    }
    return onFieldChange(e);
  };

  const handleOnBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
    return onFieldBlur(e);
  };

  const errorMessage = errors[name] && touched[name] ? errors[name] : null;

  return (
    <Html
      label={label}
      placeholder={placeholder}
      onBlur={handleOnBlur}
      height={height}
      onChange={handleOnChange}
      value={value || ''}
      id={name}
      errorMessage={errorMessage}
      autoFocus={autoFocus}
      tinymceConfig={tinymceConfig}
    />
  );
};

export default FormikHtml;
