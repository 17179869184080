import { useSelector, useDispatch } from 'react-redux';

import FlashMessage from './components/FlashMessage';
import { hideFlashMessage } from './redux/flashMessageSlice';

const FlashMessageWrapper = () => {
  const dispatch = useDispatch();
  const flashMessage = useSelector((state) => state.flashMessage);

  const handleHide = () => {
    dispatch(hideFlashMessage());
  };

  return (
    <FlashMessage
      message={flashMessage.message}
      variant={flashMessage.type}
      isVisible={flashMessage.visible === 'on'}
      onHide={handleHide}
    />
  );
};

export default FlashMessageWrapper;
