import { Input } from '@commerce7/admin-ui';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

import Icon from 'components/icon';
import { srOnly } from 'utils/helpers/cssHelper';
import useQuery from 'utils/hooks/useQuery';
import useSearch from 'utils/hooks/useSearch';

const SearchInput = (props) => {
  const { autoFocus = true, label, onSearch } = props;

  const query = useQuery();

  const [searchString, setSearchString] = useState(query.q || '');

  useSearch(searchString, (q) => onSearch(q));

  useEffect(() => {
    if (!query.q) {
      setSearchString('');
    }
  }, [query.q]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setSearchString(value);
  };

  const handleClearSearch = (e) => {
    e.preventDefault();
    setSearchString('');
  };

  return (
    <InputWrapperStyles>
      <SearchInputStyles
        autoFocus={autoFocus}
        label={`Search ${label}`}
        placeholder={`Search ${label}`}
        onChange={handleInputChange}
        value={searchString}
        startIcon="search"
        id="search"
        dataTestId="search-tools-input"
      />
      {searchString.length > 4 && (
        <ClearIconStyles onClick={handleClearSearch} type="button">
          <Icon icon="close" />
        </ClearIconStyles>
      )}
    </InputWrapperStyles>
  );
};

const InputWrapperStyles = styled.div`
  flex: auto;
  position: relative;
`;

const SearchInputStyles = styled(Input)`
  margin-bottom: 0px;
  input {
    padding-right: 40px;
  }
  label {
    ${srOnly()}
  }
`;

const ClearIconStyles = styled.button`
  background: none;
  outline: none;
  border: none;
  position: absolute;
  top: 17px;
  z-index: 1;
  right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
  }
`;

export default SearchInput;
