import { useDispatch } from 'react-redux';

import { useInviteAccountToAppMutation } from 'api';
import { Formik, ModalFormFooter } from 'components/formik';
import { Modal, ModalBody } from 'components/modal';
import { setModal } from 'modal/redux/modalSlice';

import { ROLE } from '../../utils/enums';
import { validateInvite } from '../../utils/validators';

import FormGeneral from './common/FormGeneral';

const InviteUserModal = () => {
  const dispatch = useDispatch();
  const [inviteAccountToApp] = useInviteAccountToAppMutation();

  const handleClose = () => {
    dispatch(setModal(null));
  };

  const handleSubmit = async (values, formik) => {
    const data = transformData(values);
    const { error } = await inviteAccountToApp(data);
    formik.setSubmitting(false);
    if (!error) {
      handleClose();
    }
  };

  return (
    <Modal title="Invite User" visible onClose={handleClose}>
      <Formik
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={getInitialValues()}
      >
        <ModalBody>
          <FormGeneral />
        </ModalBody>
        <ModalFormFooter saveButtonText="Send Invite" />
      </Formik>
    </Modal>
  );
};

const transformData = (values) => {
  const data = {
    email: values.email,
    role: values.role
  };
  return data;
};

const validate = (values) => {
  const data = transformData(values);
  const errors = validateInvite(data);
  return errors;
};

const getInitialValues = () => {
  const initialValues = {
    email: '',
    role: ROLE.ADMIN_OWNER
  };
  return initialValues;
};

export default InviteUserModal;
