import { Icon as UIIcon } from '@commerce7/admin-ui';
import styled from 'styled-components';

const IconCustom = (props) => {
  const { iconSize, color, ...rest } = props;

  if (iconSize || color)
    return (
      <StyledIcon
        colorLight={color?.light}
        colorDark={color?.dark}
        iconSize={iconSize}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...rest}
      />
    );

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <UIIcon {...rest} />;
};

const StyledIcon = styled(UIIcon)`
  ${({ theme, iconSize, colorLight, colorDark }) =>
    `
        width: ${iconSize};
        min-width: ${iconSize};
        height: auto;
        min-height: auto;

        path {
            fill: ${theme.mode === 'light' ? colorLight : colorDark};
        }
    `}
`;

export default IconCustom;
