import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Icon from 'components/icon';
import { toggleLeftNavigation } from 'layout/redux/layoutSlice';

const MobileMenuToggle = () => {
  const dispatch = useDispatch();
  const isLeftNavigationVisible = useSelector(
    (state) => state.layout.navigation.isLeftNavigationVisible
  );

  const onMobileMenuClick = () => {
    dispatch(toggleLeftNavigation(!isLeftNavigationVisible));
  };

  return (
    <StyleIcon
      icon="menu"
      label="Open navigation menu"
      onClick={onMobileMenuClick}
    />
  );
};

const StyleIcon = styled(Icon)`
  margin-right: 24px;
  @media ${({ theme }) => theme.c7__ui.breakpoints.largeUp} {
    display: none;
  }
`;

export default MobileMenuToggle;
