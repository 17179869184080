import { useParams } from 'react-router-dom';

import { useListVersionSecuritiesQuery } from 'api';

export const useListVersionSecurities = () => {
  const params = useParams();
  const versionId = params.id;
  const { data: versionSecurities, isLoading } =
    useListVersionSecuritiesQuery(versionId);
  return {
    data: versionSecurities?.data,
    total: versionSecurities?.total,
    isLoading
  };
};
