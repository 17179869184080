import { Page, PageContent, PageSidebar } from 'components/page';
import TestTenants from 'versionTestTenant/TestTenants';

import VersionHeader from './components/common/Header';
import ExtensionsList from './components/extensions/ExtensionsList';

const Extensions = () => (
  <Page title="Version Extensions">
    <PageContent>
      <VersionHeader />
      <ExtensionsList />
    </PageContent>
    <PageSidebar>
      <TestTenants />
    </PageSidebar>
  </Page>
);

export default Extensions;
