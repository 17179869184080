import { Field, Input } from 'components/formik';

const PrintReceiptForm = () => (
  <>
    <Field
      label="Title"
      name="hookData.title"
      component={Input}
      description="Displays as a selectable print format under Settings > POS Profile"
    />
    <Field
      label="URL"
      name="hookData.url"
      component={Input}
      placeholder="https://"
    />
  </>
);

export default PrintReceiptForm;
