import { addFlashMessage } from 'flashMessage/redux/flashMessageSlice';
import { removeAllApiErrors } from 'layout/redux/layoutSlice';

const listVersionTestTenants = (build) =>
  build.query({
    query: (versionId) => ({
      url: `/version/${versionId}/test-tenant`,
      method: 'GET'
    }),
    providesTags: () => {
      const tags = [{ type: 'VersionTestTenant', id: 'LIST' }];
      return tags;
    }
  });

const createVersionTestTenant = (build) =>
  build.mutation({
    query: ({ data, versionId }) => ({
      url: `/version/${versionId}/test-tenant`,
      method: 'POST',
      data
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({ message: 'Test Tenant Added!', type: 'success' })
      );
    },
    invalidatesTags: () => [{ type: 'VersionTestTenant', id: 'LIST' }]
  });

const deleteVersionTestTenant = (build) =>
  build.mutation({
    query: ({ tenantId, versionId }) => ({
      url: `/version/${versionId}/test-tenant/${tenantId}`,
      method: 'DELETE'
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({ message: 'Test Tenant Deleted!', type: 'success' })
      );
    },
    invalidatesTags: () => [{ type: 'VersionTestTenant', id: 'LIST' }]
  });

const versionTestTenantApi = {
  listVersionTestTenants,
  createVersionTestTenant,
  deleteVersionTestTenant
};
export default versionTestTenantApi;
