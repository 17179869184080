import { validateForUI } from 'utils/helpers/jsonValidator';

const submitAppAgreementSchema = {
  type: 'object',
  properties: {
    securityStandards: {
      type: 'boolean'
    },
    termsAndConditions: {
      type: 'boolean'
    }
  },
  required: ['securityStandards', 'termsAndConditions'],
  additionalProperties: false
};

export const validateAgree = (params) =>
  validateForUI(submitAppAgreementSchema, params);
