/* globals window */
import { useListApps } from 'app/utils/hooks';
import { Grid } from 'components/grid';
import NoRecords from 'components/noRecords';
import { appDomain } from 'utils/helpers/routingHelper';

import MonthlyRevenue from './components/grid/MonthlyRevenue';
import Status from './components/grid/Status';
import { TYPE_TRANSLATED } from './utils/enums';

const AppGrid = () => {
  const rowHeaders = [
    { label: 'Title' },
    { label: 'Type' },
    { label: 'Versions', align: 'right' },
    { label: 'Installs', align: 'right' },
    { label: 'Monthly Revenue', align: 'right' },
    { label: '' }
  ];
  const rowBody = [
    {
      data: 'title'
    },
    {
      data: 'type',
      type: 'function',
      func: (type) => TYPE_TRANSLATED[type]
    },
    {
      data: 'versionCount',
      align: 'right'
    },
    {
      data: 'installCount',
      align: 'right'
    },
    {
      data: 'monthlyRevenue',
      type: 'component',
      component: MonthlyRevenue,
      align: 'right',
      props: 'app'
    },
    {
      data: 'status',
      type: 'component',
      component: Status,
      props: 'app'
    }
  ];

  const handleRowClick = (object) => {
    window.location.href = `${appDomain(object.id)}`;
  };

  const customNoRecords = <NoRecords title="No Apps Found." />;

  return (
    <Grid
      rowHeaders={rowHeaders}
      rowBody={rowBody}
      listHook={useListApps}
      onRowClick={handleRowClick}
      customNoRecords={customNoRecords}
    />
  );
};

export default AppGrid;
