import FilterByCheckbox from './components/FilterByCheckbox';
import FilterByDate from './components/FilterByDate';
import FilterByRadio from './components/FilterByRadio';
import FilterBySelect from './components/FilterBySelect';
import FilterByText from './components/FilterByText';
import { FilterItemTitleStyles } from './components/styles';

const FilterItem = (props) => {
  const { filter, value, onChange } = props;
  const { label } = filter.key;
  return (
    <>
      <FilterItemTitleStyles>Filter by {label}</FilterItemTitleStyles>
      {filter.type === 'checkbox' && (
        <FilterByCheckbox onChange={onChange} value={value} filter={filter} />
      )}
      {filter.type === 'date' && (
        <FilterByDate onChange={onChange} value={value} filter={filter} />
      )}
      {filter.type === 'radio' && (
        <FilterByRadio onChange={onChange} value={value} filter={filter} />
      )}
      {filter.type === 'text' && (
        <FilterByText onChange={onChange} value={value} filter={filter} />
      )}
      {filter.type === 'select' && (
        <FilterBySelect onChange={onChange} value={value} filter={filter} />
      )}
    </>
  );
};

export default FilterItem;
