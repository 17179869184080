import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useListAllApps } from 'app/utils/hooks';
import { LinkButton } from 'components/button';
import Text from 'components/text';

const NoAppsMessage = () => {
  const { hasApps } = useListAllApps();

  if (hasApps) return null;

  return (
    <>
      <StyledText block>
        Let&apos;s get the show on the road. Get started by creating your first
        application!
      </StyledText>
      <LinkButton startIcon="add" to="/add" component={Link}>
        Add App
      </LinkButton>
    </>
  );
};

const StyledText = styled(Text)`
  margin-bottom: 30px;
`;

export default NoAppsMessage;
