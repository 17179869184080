import { Breadcrumbs as UIBreadcrumbs } from '@commerce7/admin-ui';
import { Link } from 'react-router-dom';

const { Breadcrumb: UIBreadcrumb } = UIBreadcrumbs;

const Breadcrumb = (props) => {
  const { breadcrumbs, className, dataTestId } = props;
  return (
    <UIBreadcrumbs className={className} dataTestId={dataTestId}>
      {breadcrumbs.map((breadcrumb, index) => {
        const { to, label, ...rest } = breadcrumb;

        const customProps = {};
        if (to) {
          customProps.component = Link;
          customProps.to = to;
        }
        return (
          <UIBreadcrumb
            // eslint-disable-next-line
            {...customProps}
            // eslint-disable-next-line
            {...rest}
            key={`${String(index)}-${breadcrumb.label}`}
          >
            {label}
          </UIBreadcrumb>
        );
      })}
    </UIBreadcrumbs>
  );
};

export default Breadcrumb;
