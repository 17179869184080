import Heading from 'components/heading';
import { Page, PageContent } from 'components/page';
import Region from 'components/region';

import SupportItems from './components/SupportItems';

const Support = () => (
  <Page title="Support">
    <PageContent>
      <Heading level={1}>Support</Heading>
      <Region>
        <SupportItems />
      </Region>
    </PageContent>
  </Page>
);

export default Support;
