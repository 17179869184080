import { useDispatch } from 'react-redux';

import { Grid } from 'components/grid';
import NoRecords from 'components/noRecords';
import { setModal } from 'modal/redux/modalSlice';
import { useGetVersion } from 'version/utils/hooks';

import EditModal from './EditModal';
import Details from './components/grid/Details';
import Title from './components/grid/Title';
import { OBJECT_TYPE_TRANSLATED, HOOK_TYPE_TRANSLATED } from './utils/enums';
import { useListVersionExtensions } from './utils/hooks';

const VersionExtensionGrid = () => {
  const dispatch = useDispatch();
  const { isApproved } = useGetVersion();

  const rowHeaders = [
    { label: 'Location' },
    { label: 'Type' },
    { label: 'Title' },
    { label: 'Details' }
  ];

  const rowBody = [
    {
      data: 'objectType',
      type: 'function',
      func: (objectType) => OBJECT_TYPE_TRANSLATED[objectType]
    },
    {
      data: 'hookType',
      type: 'function',
      func: (hookType) => HOOK_TYPE_TRANSLATED[hookType]
    },
    {
      data: 'title',
      props: 'versionExtension',
      type: 'component',
      component: Title
    },
    {
      data: 'details',
      props: 'versionExtension',
      type: 'component',
      component: Details
    }
  ];

  const customNoRecords = (
    <NoRecords
      title="No extensions found"
      description="For your application to appear in the Admin, POS, or Frontend, add an extension to indicate how and where it should appear."
    />
  );

  const handleRowClick = (versionExtension) => {
    dispatch(setModal(<EditModal versionExtension={versionExtension} />));
  };

  return (
    <Grid
      rowHeaders={rowHeaders}
      rowBody={rowBody}
      listHook={useListVersionExtensions}
      customNoRecords={customNoRecords}
      onRowClick={isApproved ? null : handleRowClick}
    />
  );
};

export default VersionExtensionGrid;
