const Title = (props) => {
  const { versionExtension } = props;
  const { title } = versionExtension.hookData;

  if (!title) return <div>--</div>;

  return <div>{title}</div>;
};

export default Title;
