const listExchangeRates = (build) =>
  build.query({
    query: (query) => ({
      url: `/exchange-rate`,
      method: 'GET',
      params: query
    }),
    providesTags: () => {
      const tags = [{ type: 'ExchangeRate', id: 'LIST' }];
      return tags;
    }
  });

const exchangeRateApi = {
  listExchangeRates
};
export default exchangeRateApi;
