import { useEscKeydown, useOnClickOutside } from '@commerce7/frontend-utils';
import { useState, useRef } from 'react';

import FilterItem from 'components/filterItem';

import {
  FilterStyles,
  FilterDropdownStyles,
  FiltersToggleButton,
  DropdownIcon
} from './styles';

const Filter = (props) => {
  const [open, setOpen] = useState(false);
  const filterRef = useRef();

  useOnClickOutside(filterRef, () => setOpen(false));
  useEscKeydown(() => setOpen(false));

  const handleButtonClick = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  const handleFilterChange = (filterKey, filters) => {
    const { onChange } = props;
    setOpen(false);
    onChange(filterKey, filters);
  };
  const { filter, value } = props;

  return (
    <FilterStyles ref={filterRef}>
      <FiltersToggleButton onClick={handleButtonClick}>
        {filter.key.label}{' '}
        <DropdownIcon icon="chevronDown" rotate={open} size={12} />
      </FiltersToggleButton>
      <FilterDropdownStyles visible={open}>
        {open && (
          <FilterItem
            filter={filter}
            value={value}
            onChange={handleFilterChange}
          />
        )}
      </FilterDropdownStyles>
    </FilterStyles>
  );
};

export default Filter;
