export const onGridRowClick = (e, navigate, onRowClick, linkUrl, object) => {
  // Are we going to redirect by a TR push - or are we redirecting because a link was clicked
  const tr = e.currentTarget;
  if (onRowClick) {
    let requiresClick = true;
    let el = e.target;
    while (el !== tr) {
      if (['LABEL'].includes(el.tagName)) {
        requiresClick = false;
        break;
      }
      el = el.parentElement;
    }
    if (requiresClick) {
      onRowClick(object);
    }
  } else {
    let requiresPush = true;
    let el = e.target;
    while (el !== tr) {
      if (['LABEL', 'A'].includes(el.tagName)) {
        requiresPush = false;
        break;
      }
      el = el.parentElement;
    }
    if (requiresPush) {
      navigate(`${linkUrl}/${object.id}`);
    }
  }
};
