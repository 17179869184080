import { Routes, Route } from 'react-router-dom';

import Extensions from 'version/Extensions';
import Installation from 'version/Installation';
import Settings from 'version/Settings';
import WebHooks from 'version/WebHooks';

const DepartmentRoutes = () => (
  <Routes>
    <Route path="/" element={<Installation />} />
    <Route path="/web-hooks" element={<WebHooks />} />
    <Route path="/settings" element={<Settings />} />
    <Route path="/extensions" element={<Extensions />} />
  </Routes>
);

export default DepartmentRoutes;
