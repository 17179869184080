import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import Icon from 'components/icon';
import NoRecords from 'components/noRecords';
import Picture from 'components/picture';
import { IMAGE_SIZES } from 'utils/helpers/imageHelper';

import { useGetAppTempListing } from '../../utils/hooks';

import AppListingChecklist from './appListing/AppListingChecklist';

const AppListing = () => {
  const { appTempListing, isLoading, created } = useGetAppTempListing();
  const theme = useContext(ThemeContext);

  if (isLoading) return null;

  if (!created) {
    return (
      <NoRecords
        icon="document"
        title="You don't have an app listing yet"
        description="What does your app do and why should customers install it? Add details about your app to display in the App Store."
      />
    );
  }

  let logoSrc;
  if (appTempListing.logo) {
    logoSrc = appTempListing.logo;
  }
  if (
    theme.c7__ui.mode === 'dark' &&
    appTempListing.logo &&
    appTempListing.darkModeLogo
  ) {
    logoSrc = appTempListing.darkModeLogo;
  }

  return (
    <StyledAppListing>
      <StyledImageWrapper>
        {logoSrc && (
          <Picture src={logoSrc} alt="app logo" size={IMAGE_SIZES.THUMB} />
        )}
        {!logoSrc && <Icon icon="imagePlaceholder" size={60} />}
      </StyledImageWrapper>
      <AppListingChecklist />
    </StyledAppListing>
  );
};

const StyledAppListing = styled.div`
  display: flex;
`;

const StyledImageWrapper = styled.div`
  height: 175px;
  width: 250px;
  border: 1px solid ${({ theme }) => theme.c7__ui.borderColor};
  border-radius: ${({ theme }) => theme.c7__ui.borderRadius};

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  picture {
    height: 100%;
    object-fit: scale-down;
    img {
      height: 100%;
      object-fit: scale-down;
    }
  }

  svg {
    path {
      fill: ${({ theme }) => theme.c7__ui.borderColor};
    }
  }
`;

export default AppListing;
