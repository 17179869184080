import { useState, useEffect } from 'react';

import {
  Field,
  Input,
  useFormikContext,
  HiddenPassword
} from 'components/formik';

import { useGetVersion } from '../../../utils/hooks';

const Password = (props) => {
  const { setUpdateInstallCredentials } = props;
  const { version } = useGetVersion();
  const formikContext = useFormikContext();

  const { values, initialValues } = formikContext;

  const hasInstallUsername = !!version.installUsername;
  const [showPasswordInput, setShowPasswordInput] = useState(
    !hasInstallUsername
  );

  const installUsernameChanged =
    initialValues.installUsername !== values.installUsername;

  useEffect(() => {
    if (installUsernameChanged || showPasswordInput) {
      setUpdateInstallCredentials(true);
    }
  }, [installUsernameChanged, showPasswordInput, setUpdateInstallCredentials]);

  return (
    <>
      {(showPasswordInput || installUsernameChanged) && (
        <Field
          name="installPassword"
          label="Password (Optional)"
          component={Input}
          type="password"
        />
      )}
      {!showPasswordInput && !installUsernameChanged && (
        <HiddenPassword onEdit={() => setShowPasswordInput(true)} />
      )}
    </>
  );
};

export default Password;
