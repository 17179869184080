import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { TYPE } from 'app/utils/enums';
import { useGetApp } from 'app/utils/hooks';
import { Stepper, Step } from 'components/stepper';

import { useGetVersion } from '../../../utils/hooks';

const VersionStepper = () => {
  const { version } = useGetVersion();
  const { app } = useGetApp();
  const location = useLocation();

  const showExtensions = [TYPE.APP, TYPE.PRIVATE].includes(app.type);
  const installStepCount = showExtensions ? 4 : 3;

  return (
    <StyledStepper>
      <Step
        step={1}
        description="APIs & Webhooks"
        to={`/version/${version.id}/web-hooks`}
        icon="transfer"
      />
      <Step
        step={2}
        description="Client Settings"
        to={`/version/${version.id}/settings`}
        icon="clipboardText"
      />
      {showExtensions && (
        <Step
          step={3}
          description="Extensions"
          to={`/version/${version.id}/extensions`}
          icon="pos"
        />
      )}
      <Step
        step={installStepCount}
        description="Installation"
        to={`/version/${version.id}`}
        icon="download"
        activeClassName="customActive"
        className={isActive(location) ? 'customActive' : ''}
      />
    </StyledStepper>
  );
};

const isActive = (location) => {
  if (location.pathname.split('/').length === 3) {
    return true;
  }
  return false;
};

const StyledStepper = styled(Stepper)`
  padding: 30px 0 60px 0;
  border-bottom: 1px solid ${({ theme }) => theme.c7__ui.borderColor};
`;

export default VersionStepper;
