import styled from 'styled-components';

const PageBody = (props) => {
  const { children } = props;
  return <StyledPageBody>{children}</StyledPageBody>;
};

const StyledPageBody = styled.div`
  padding: 20px 0;

  @media ${({ theme }) => theme.c7__ui.breakpoints.largeUp} {
    padding: 30px 0;
  }
`;

export default PageBody;
