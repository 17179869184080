import { useParams } from 'react-router-dom';

import { useListVersionTestTenantsQuery } from 'api';

export const useListVersionTestTenants = () => {
  const params = useParams();
  const {
    data: versionTestTenants,
    isLoading,
    error
  } = useListVersionTestTenantsQuery(params.id);
  return {
    data: versionTestTenants?.data,
    isLoading,
    error
  };
};
