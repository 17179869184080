import { Columns, Column } from 'components/columns';
import { FormDescription, LinkToDocs } from 'components/formInstructions';
import { Field, Select, Input, useFormikContext } from 'components/formik';

import {
  IFRAME_MESSAGE,
  IFRAME_MESSAGE_DOCS,
  ACTIONS_MESSAGE,
  ACTIONS_MESSAGE_DOCS
} from '../../../../utils/constants';

import IconUploader from './common/IconUploader';

const ContextMenuForm = () => {
  const { values } = useFormikContext();
  const { contextMenuType } = values.hookData;

  let message;
  let docLink;
  let urlLabel = 'URL';
  if (contextMenuType === 'Action') {
    message = ACTIONS_MESSAGE;
    docLink = ACTIONS_MESSAGE_DOCS;
  } else if (contextMenuType === 'Modal') {
    message = IFRAME_MESSAGE;
    docLink = IFRAME_MESSAGE_DOCS;
    urlLabel = 'iFrame URL';
  }

  return (
    <>
      <Field
        label="Format"
        name="hookData.contextMenuType"
        options={getContextMenuTypeOptions()}
        component={Select}
      />
      <Columns>
        <Column span={6}>
          <IconUploader />
        </Column>
        <Column span={6}>
          <Field label="Label" name="hookData.title" component={Input} />
        </Column>
      </Columns>
      <Field
        label={urlLabel}
        name="hookData.url"
        component={Input}
        placeholder="https://"
      />
      {message && (
        <FormDescription>
          {message} <LinkToDocs url={docLink} />
        </FormDescription>
      )}
    </>
  );
};

const getContextMenuTypeOptions = () => {
  const options = [
    { value: '', label: 'Select Format' },
    { value: 'Action', label: 'Action' },
    { value: 'Link', label: 'Link' },
    { value: 'Modal', label: 'Modal' }
  ];
  return options;
};

export default ContextMenuForm;
