import { Input } from '@commerce7/admin-ui';
import { useState } from 'react';

import { useFormikContext } from 'components/formik';

import AccessObject from './formGeneral/AccessObject';

const FormGeneral = () => {
  const [filterStr, setFilterStr] = useState('');
  const { values } = useFormikContext();

  const allKeys = Object.keys(values);
  const filteredKeys = allKeys.filter((key) =>
    key.toLowerCase().includes(filterStr)
  );

  return (
    <>
      <Input
        autoFocus
        onChange={(e) => setFilterStr(e.target.value)}
        startIcon="search"
        value={filterStr}
        placeholder="Search by endpoint"
      />
      {filteredKeys.map((key) => (
        <AccessObject key={key} objectType={key} value={values[key]} />
      ))}
    </>
  );
};

export default FormGeneral;
