import PropTypes from 'prop-types';

import { Button } from 'components/button';

import useFormikContext from '../useFormikContext';

const SaveButton = (props) => {
  const formikContext = useFormikContext();

  const { label, onClick, className } = props;

  const handleButtonClick = (e) => {
    if (typeof onClick === 'function') {
      e.preventDefault();
      onClick(e);
    }
  };

  return (
    <Button
      type="submit"
      className={className}
      onClick={handleButtonClick}
      loading={formikContext.isSubmitting}
      dataTestId="formik-submit"
    >
      {label}
    </Button>
  );
};

SaveButton.defaultProps = {
  label: 'Save',
  onClick: null
};

SaveButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func
};

export default SaveButton;
