import { useDispatch } from 'react-redux';

import { Grid } from 'components/grid';
import { setModal } from 'modal/redux/modalSlice';

import { useListAppXAccounts } from '../../utils/hooks';

import EditAccountModal from './EditAccountModal';
import Avatar from './grid/Avatar';
import Name from './grid/Name';
import Role from './grid/Role';

const AppXAccountGrid = () => {
  const dispatch = useDispatch();

  const rowHeaders = [
    { label: '' },
    { label: 'Name' },
    { label: 'Email' },
    { label: 'Role' }
  ];

  const rowBody = [
    {
      data: 'avatar',
      type: 'component',
      component: Avatar,
      props: 'appXAccount'
    },
    {
      data: 'name',
      type: 'component',
      component: Name,
      props: 'appXAccount'
    },
    {
      data: 'email'
    },
    {
      data: 'role',
      type: 'component',
      component: Role,
      props: 'appXAccount'
    }
  ];

  const handleRowClick = (appXAccount) => {
    dispatch(setModal(<EditAccountModal appXAccount={appXAccount} />));
  };

  return (
    <Grid
      rowHeaders={rowHeaders}
      rowBody={rowBody}
      listHook={useListAppXAccounts}
      onRowClick={handleRowClick}
    />
  );
};

export default AppXAccountGrid;
