import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

import { LoadingLayout } from 'components/layout';
import { getSelf } from 'user/redux/userSlice';

const AuthenticatedRoute = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);

  useEffect(() => {
    if (Cookies.get('token') && !user) {
      dispatch(getSelf());
    }
  }, [dispatch, user]);

  if (!Cookies.get('token')) {
    return <Navigate to="/login" />;
  }

  if (!user) {
    return <LoadingLayout />;
  }

  return <Outlet />;
};

export default AuthenticatedRoute;
