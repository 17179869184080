import styled from 'styled-components';

const UserDropdownMenu = (props) => {
  const { children, visible } = props;
  return (
    <StyledDropdown
      visible={visible}
      id="navigationUserDropdown"
      role="menu"
      aria-labelledby="navigationUserButton"
    >
      {children}
    </StyledDropdown>
  );
};

const StyledDropdown = styled.ul`
  background: ${({ theme }) => theme.c7__ui.backgroundColor};
  border: 1px solid ${({ theme }) => theme.c7__ui.borderColor};
  box-shadow: ${({ theme }) => theme.c7__ui.boxShadow};
  border-radius: ${({ theme }) => theme.c7__ui.borderRadius};
  font-size: ${({ theme }) => theme.c7__ui.fontSizeBase};
  box-sizing: border-box;
  overflow: hidden;
  min-width: 100%;
  z-index: 999;
  position: absolute;
  top: 100%;
  right: 0;
  padding: 6px;
  transition: all 0.2s ease-in-out;
  list-style-type: none;
  ${({ visible }) => `
    opacity: ${visible ? '1' : '0'};
    visibility: ${visible ? 'visible' : 'hidden'};
    transform: ${visible ? `translateY(0px)` : `translateY(-10px)`};
  `}
`;

export default UserDropdownMenu;
