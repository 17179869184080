import { Formik as FormikCore, Form as FormCore } from 'formik';

const Formik = (props) => {
  const { children, ...rest } = props;

  return (
    // eslint-disable-next-line
    <FormikCore {...rest}>
      <FormCore>{children}</FormCore>
    </FormikCore>
  );
};

export default Formik;
