import styled from 'styled-components';

import { Columns, Column } from 'components/columns';
import { FormDescription } from 'components/formInstructions';
import { Field, Input, Html } from 'components/formik';
import Heading from 'components/heading';
import Region from 'components/region';

const SetupAndSupport = () => (
  <Region>
    <Heading>Setup & Support</Heading>
    <StyledFormDescription>
      If there is any setup involved with installing your app, we strongly
      recommend adding steps for customers so that they know what to do to
      complete installation, or, if you complete setup on their behalf, let them
      know what to expect.
    </StyledFormDescription>
    <Columns>
      <Column span={3}>
        <Field
          name="supportEmail"
          component={Input}
          label="Support Email"
          description="Clients will be able to reach you here for support"
        />
      </Column>
      <Column span={3}>
        <Field
          name="supportPhone"
          component={Input}
          label="Support Phone (Optional)"
        />
      </Column>
      <Column span={6}>
        <Field
          name="documentationUrl"
          component={Input}
          label="Documentation URL (Optional)"
          placeholder="https://"
        />
      </Column>
    </Columns>
    <Field
      name="instructions"
      component={Html}
      label="Install Steps (Optional)"
      height={184}
    />
  </Region>
);

const StyledFormDescription = styled(FormDescription)`
  margin-bottom: 20px;
`;

export default SetupAndSupport;
