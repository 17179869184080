import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  message: null,
  type: null,
  visible: 'hide' // values are:  on, off, hide
};

const flashMessageSlice = createSlice({
  name: 'flashMessage',
  initialState,
  reducers: {
    addFlashMessage: (state, action) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.visible = 'on';
    },
    removeFlashMessage: (state) => {
      state.visible = 'off';
    },
    hideFlashMessage: (state) => {
      state.message = null;
      state.type = null;
      state.visible = 'hide';
    }
  }
});

export const { addFlashMessage, removeFlashMessage, hideFlashMessage } =
  flashMessageSlice.actions;

export default flashMessageSlice.reducer;
