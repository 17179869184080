/* globals window */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { LoadingLayout } from 'components/layout';
import { loginLink } from 'utils/helpers/routingHelper';

import { logout } from './redux/loginSlice';

const Logout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const onSuccess = () => {
      window.location.href = loginLink();
    };
    dispatch(logout(onSuccess));
  }, [dispatch]);

  return <LoadingLayout />;
};

export default Logout;
