import { Stepper as StepperUI } from '@commerce7/admin-ui';
import { Children } from 'react';
import styled from 'styled-components';

const Stepper = (props) => {
  const { children, className } = props;
  const numberOfSteps = Children.toArray(children).length;

  return (
    <StyledStepperWrapper numberOfSteps={numberOfSteps}>
      <StepperUI className={className}>{children}</StepperUI>
    </StyledStepperWrapper>
  );
};

const StyledStepperWrapper = styled.div`
  display: flex;
  justify-content: center;

  > div {
    max-width: ${({ numberOfSteps }) => maxWidthMapping(numberOfSteps)}px;
    flex: 1;
  }
`;

const maxWidthMapping = (steps) => {
  switch (steps) {
    case 1:
      return '400';
    case 2:
      return '700';
    case 3:
      return '900';
    case 4:
      return '1500';
    default:
      return '1500';
  }
};

export default Stepper;
