import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { removeAllAxiosRequests } from 'layout/redux/layoutSlice';

import { StyledApiLoading } from './styles';

// @common?
const ApiLoading = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const apiReducer = useSelector((state) => state.layout.api);
  const modal = useSelector((state) => state.modal);

  useEffect(() => {
    dispatch(removeAllAxiosRequests());
  }, [location.pathname, dispatch]);

  const isVisible = apiReducer.activeRequests?.length > 0 && !modal.modal;

  return <StyledApiLoading isVisible={isVisible} />;
};

export default ApiLoading;
