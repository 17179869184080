import { Commerce7AdminUI } from '@commerce7/admin-ui';
import { useSelector } from 'react-redux';

const Theme = (props) => {
  const { children } = props;

  const colorMode = useSelector((state) => state.layout.style.colorMode);

  let mode = 'light';
  if (colorMode === 'dark') {
    mode = 'dark';
  }

  return <Commerce7AdminUI mode={mode}>{children}</Commerce7AdminUI>;
};

export default Theme;
