import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { removeAllApiErrors } from 'layout/redux/layoutSlice';

import { ModalApiErrorStyles } from './styles';

const ApiError = () => {
  const apiError = useSelector((state) => state.layout.api.error);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(removeAllApiErrors());
    return () => {
      dispatch(removeAllApiErrors());
    };
  }, [dispatch]);

  if (!apiError) {
    return null;
  }

  return <ModalApiErrorStyles>{apiError}</ModalApiErrorStyles>;
};

export default ApiError;
