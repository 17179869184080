// @common
export const userAvatarInfo = (user) => {
  let label = '';

  if (user?.firstName && user?.lastName) {
    label = `${user.firstName.charAt(0).toUpperCase()}${user.lastName
      .charAt(0)
      .toUpperCase()}`;
  }

  return {
    label,
    imageSrc: user?.avatar,
    imageAlt: `${user?.firstName} ${user?.lastName}`
  };
};

// @common
export const customerName = (customer) => {
  if (customer.honorific) {
    return `${customer.honorific} ${customer.firstName || ''} ${
      customer.lastName || ''
    }`;
  }
  if (customer.firstName || customer.lastName) {
    return `${customer.firstName || ''} ${customer.lastName || ''}`;
  }

  let name = '()';
  if (customer.emails[0] && customer.emails[0].email) {
    name = `${customer.emails[0] && customer.emails[0].email}`;
  }
  return name;
};

// @common
export const pad = (padString, str) => {
  if (typeof str === 'undefined') {
    return padString;
  }
  return (padString + str).slice(-padString.length);
};

// TODO - UPDATE TO REAL SETTINGS
export const settings = () => ({
  timeZone: 'Canada/Pacific'
});

export const currencyIcon = (currency) => {
  if (currency === 'EUR') {
    return 'euro';
  }
  if (currency === 'GBP') {
    return 'pound';
  }
  if (currency === 'ZAR') {
    return 'rand';
  }
  return 'dollar';
};
