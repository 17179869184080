import { useDispatch } from 'react-redux';

import { useUpdateTenantMonthlyFeeMutation } from 'api';
import { Formik, ModalFormFooter } from 'components/formik';
import { Modal, ModalBody } from 'components/modal';
import { setModal } from 'modal/redux/modalSlice';
import {
  convertCurrencyStringToCents,
  decimalFormat
} from 'utils/helpers/numberHelper';

import { validateUpdate } from '../../../utils/validators';

import FormGeneral from './updateTenantModal/FormGeneral';

const UpdateTenantModal = (props) => {
  const dispatch = useDispatch();
  const { appXTenant } = props;
  const [updateTenantMonthlyFee] = useUpdateTenantMonthlyFeeMutation();

  const handleSubmit = async (values, formik) => {
    const data = transformData(values);
    const { error } = await updateTenantMonthlyFee({
      appXTenantId: appXTenant.id,
      data
    });
    formik.setSubmitting(false);
    if (!error) {
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(setModal(null));
  };

  return (
    <Modal title="Update Tenant" visible onClose={handleClose}>
      <Formik
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={getInitialValues(appXTenant)}
      >
        <ModalBody>
          <FormGeneral appXTenant={appXTenant} />
        </ModalBody>
        <ModalFormFooter saveButtonText="Save Tenant" />
      </Formik>
    </Modal>
  );
};

const transformData = (values) => {
  const data = {
    monthlyFee: convertCurrencyStringToCents(values.monthlyFee)
  };
  return data;
};

const validate = (values) => {
  const data = transformData(values);
  const errors = validateUpdate(data);
  return errors;
};

const getInitialValues = (appXTenant) => {
  const initialValues = {
    monthlyFee: decimalFormat(appXTenant.monthlyFee)
  };
  return initialValues;
};

export default UpdateTenantModal;
