import { Draggable } from 'react-beautiful-dnd';

import SettingRow from './SettingRow';

const Setting = (props) => {
  const { setting, index } = props;

  return (
    <Draggable key={setting.id} draggableId={setting.id} index={index}>
      {(provided, snapshot) => (
        <SettingRow provided={provided} setting={setting} snapshot={snapshot} />
      )}
    </Draggable>
  );
};

export default Setting;
