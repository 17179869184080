import { Switch } from '@commerce7/admin-ui';

const FormikSwitch = (props) => {
  const { label, field, form, onChange, disabled, description, className } =
    props;
  const { onBlur, onChange: onFieldChange, value, name } = field;
  const { errors, touched } = form;

  const handleOnChange = (e) => {
    if (onChange) {
      onChange(e);
    }
    return onFieldChange(e);
  };

  const errorMessage = errors[name] && touched[name] ? errors[name] : null;
  return (
    <Switch
      label={label}
      onBlur={onBlur}
      onChange={handleOnChange}
      checked={value || false}
      id={name}
      errorMessage={errorMessage}
      disabled={disabled}
      description={description}
      className={className}
    />
  );
};

export default FormikSwitch;
