import styled from 'styled-components';

import Alert from 'components/alert';

import { useGetVersion } from '../../../utils/hooks';

const AlertBanner = () => {
  const { isApproved } = useGetVersion();

  if (!isApproved) return null;

  return (
    <StyledAlert variant="info">
      Since this version is approved, pricing, APIs & Webhooks and extensions
      cannot be edited. To make changes, duplicate or create a new version.
    </StyledAlert>
  );
};

const StyledAlert = styled(Alert)`
  margin-top: 20px;
`;

export default AlertBanner;
