import DataDisplay from 'components/dataDisplay';
import { Field, Input, Select, useFormikContext } from 'components/formik';
import LineBreak from 'components/lineBreak';

import { ROLE } from '../../../utils/enums';
import { useIsAdminOwner, useIsMyAccount } from '../../../utils/hooks';

const FormGeneral = (props) => {
  const { isEdit, appXAccount = {} } = props;
  const { values } = useFormikContext();

  const { firstName, lastName, email } = appXAccount;

  const isAdminOwner = useIsAdminOwner();
  const isMyAccount = useIsMyAccount(appXAccount);

  return (
    <>
      {!isEdit && (
        <Field label="Email" name="email" component={Input} autoFocus />
      )}
      {isEdit && (
        <DataDisplay label="Name">{`${firstName} ${lastName}`}</DataDisplay>
      )}
      {isEdit && <DataDisplay label="Email">{email}</DataDisplay>}
      {isAdminOwner && !isMyAccount ? (
        <Field
          label="Role"
          name="role"
          component={Select}
          options={getRoleOptions()}
        />
      ) : (
        <DataDisplay label="Role">{values.role}</DataDisplay>
      )}
      <LineBreak />
      <DataDisplay label={values.role}>
        {getRoleDescription(values.role)}
      </DataDisplay>
    </>
  );
};

const getRoleDescription = (role) => {
  const roleDescriptions = {
    [ROLE.ADMIN_OWNER]: 'This role has no restrictions.',
    [ROLE.ADMIN_USER]:
      'This role can do everything except invite new users or change their permissions.'
  };
  return roleDescriptions[role];
};

const getRoleOptions = () => {
  const options = [];
  Object.keys(ROLE).forEach((key) => {
    options.push({ value: ROLE[key], label: ROLE[key] });
  });
  return options;
};

export default FormGeneral;
