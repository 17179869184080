/* globals window */
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useDeleteAppMutation } from 'api';
import Alert from 'components/alert';
import { Button } from 'components/button';
import { Modal, ModalBody, ModalFooter } from 'components/modal';
import Text from 'components/text';
import { setModal } from 'modal/redux/modalSlice';
import { appFromURL } from 'utils/helpers/apiHelper';
import { mainDomain } from 'utils/helpers/routingHelper';

import { useGetApp } from '../../../utils/hooks';

const DeleteAppModal = () => {
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [deleteApp] = useDeleteAppMutation();

  const { app } = useGetApp();
  const { title } = app;

  const handleDeleteApp = async () => {
    setSubmitting(true);
    const { error } = await deleteApp(appFromURL());
    if (!error) {
      window.location.href = `${mainDomain()}`;
    }
    setSubmitting(false);
  };

  const handleClose = () => {
    dispatch(setModal(null));
  };

  return (
    <Modal title={`Delete "${title}"?`} visible onClose={handleClose}>
      <ModalBody>
        <Alert variant="warning" icon="warning">
          This will remove it completely and you will not be able to recover it.
        </Alert>
        <StyledText>Are you sure that you want to delete this app?</StyledText>
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleDeleteApp} loading={submitting}>
          Delete App
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const StyledText = styled(Text)`
  margin-bottom: 100px;
`;

export default DeleteAppModal;
