import Alert from 'components/alert';
import { STATUS as VERSION_STATUS } from 'version/utils/enums';
import { useGetLatestVersion } from 'version/utils/hooks';

import { STATUS as APP_TEMP_LISTING_STATUS } from '../../utils/enums';
import { useGetAppTempListing } from '../../utils/hooks';

const AlertBanner = () => {
  const { appTempListing, isLoading: appTempLoading } = useGetAppTempListing();
  const { latestVersion, isLoading: versionLoading } = useGetLatestVersion();

  if (appTempLoading || versionLoading) return null;

  if (
    latestVersion.status === VERSION_STATUS.SUBMITTED ||
    appTempListing?.status === APP_TEMP_LISTING_STATUS.SUBMITTED
  ) {
    return (
      <Alert variant="warning">
        Your content changes are pending approval.
      </Alert>
    );
  }

  if (appTempListing?.status === APP_TEMP_LISTING_STATUS.PENDING) {
    return (
      <Alert variant="warning">
        You&apos;ve made some changes to your content. To update your live
        listing, click Request Approval.
      </Alert>
    );
  }

  return null;
};

export default AlertBanner;
