import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useCreateVersionExtensionMutation } from 'api';
import { Formik, ModalFormFooter } from 'components/formik';
import { Modal, ModalBody } from 'components/modal';
import { setModal } from 'modal/redux/modalSlice';

import FormGeneral from './components/common/FormGeneral';
import { OBJECT_TYPE, HOOK_TYPE } from './utils/enums';
import { transformData } from './utils/helpers';
import { validateCreate } from './utils/validators';

const AddModal = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [createVersionExtension] = useCreateVersionExtensionMutation();

  const handleSubmit = async (values, formik) => {
    const data = transformData(values);
    const { error } = await createVersionExtension({
      data,
      versionId: params.id
    });
    formik.setSubmitting(false);
    if (!error) {
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(setModal(null));
  };

  return (
    <Modal title="Add Extension" visible onClose={handleClose}>
      <Formik
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={getInitialValues()}
      >
        <ModalBody>
          <FormGeneral />
        </ModalBody>
        <ModalFormFooter saveButtonText="Add Extension" />
      </Formik>
    </Modal>
  );
};

const validate = (values) => {
  const data = transformData(values);
  const errors = validateCreate(data);
  return errors;
};

const getInitialValues = () => {
  const initialValues = {
    objectType: OBJECT_TYPE.STORE_MENU,
    hookType: HOOK_TYPE.PAGE,
    hookData: {
      icon: '',
      contextMenuType: '',
      url: '',
      title: '',
      iFrameHeight: ''
    }
  };
  return initialValues;
};

export default AddModal;
