import { FieldArray } from 'formik';

import { Columns, Column } from 'components/columns';
import DataDisplay from 'components/dataDisplay';
import {
  Field,
  Select,
  Input,
  Switch,
  useFormikContext
} from 'components/formik';

import { DATA_TYPE, DATA_TYPE_TRANSLATED } from '../../utils/enums';

import OptionFormFields from './formGeneral/OptionFormFields';

const FormGeneral = (props) => {
  const { editForm } = props;
  const { values } = useFormikContext();

  const hasOptions = [
    DATA_TYPE.RADIO,
    DATA_TYPE.CHECKBOX,
    DATA_TYPE.SELECT
  ].includes(values.dataType);

  return (
    <>
      <Columns>
        <Column span={6}>
          <Field name="title" label="Title" component={Input} autoFocus />
        </Column>
        <Column span={6}>
          {!editForm && <Field name="code" label="Code" component={Input} />}
          {editForm && <DataDisplay label="Code">{values.code}</DataDisplay>}
        </Column>
      </Columns>
      <Field
        name="dataType"
        label="Data Type"
        component={Select}
        options={dataTypeOptions()}
      />
      <Field name="isRequired" label="Required" component={Switch} />
      {hasOptions && <FieldArray name="options" component={OptionFormFields} />}
    </>
  );
};

const dataTypeOptions = () => {
  const array = [];
  Object.keys(DATA_TYPE).forEach((dataType) => {
    array.push({ value: dataType, label: DATA_TYPE_TRANSLATED[dataType] });
  });
  return array;
};

export default FormGeneral;
