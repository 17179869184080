import styled from 'styled-components';

const Details = (props) => {
  const { versionExtension } = props;

  const { hookData } = versionExtension;

  const detailsList = [];
  Object.keys(hookData).forEach((key) => {
    switch (key) {
      case 'iFrameHeight':
        detailsList.push(<li key={key}>iFrameHeight: {hookData[key]}px</li>);
        break;
      case 'url':
        detailsList.push(<li key={key}>URL: {hookData[key]}</li>);
        break;
      case 'contextMenuType':
        detailsList.push(<li key={key}>Context Menu Type: {hookData[key]}</li>);
        break;
      default:
        break;
    }
  });

  return <StyledUL>{detailsList.map((item) => item)}</StyledUL>;
};

const StyledUL = styled.ul`
  padding: 0 0 0 20px;

  li {
    line-height: 1.6;
  }
`;

export default Details;
