import { Columns, Column } from 'components/columns';
import { Field, Input } from 'components/formik';

const FormGeneral = () => (
  <Columns>
    <Column span={6}>
      <Field name="developerName" label="Company Name" component={Input} />
    </Column>
    <Column span={6}>
      <Field
        name="developerEmail"
        label="Contact Email"
        type="email"
        description="Used for app notifications and billing"
        component={Input}
      />
    </Column>
  </Columns>
);

export default FormGeneral;
