import { Field, Input } from 'components/formik';

const FormGeneral = () => (
  <>
    <Field
      autoFocus
      name="token"
      component={Input}
      label="Code"
      placeholder="XXXXXX"
    />
  </>
);

export default FormGeneral;
