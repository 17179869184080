/* eslint-disable no-unused-vars */

import { appFromURL } from './apiHelper';

const isAllowedUser = (user, allowedUsers) => {
  const allowedEmails = allowedUsers.map((name) => users[name]);
  if (allowedEmails.includes(user.email)) {
    return true;
  }
  return false;
};

const users = {
  Developer: 'developer@commerce7.com',
  Andrea: 'andrea@commerce7.com',
  Jacob: 'jacob@commerce7.com',
  Josh: 'josh@commerce7.com',
  Noah: 'noah@commerce7.com'
};

export const isSettingUrl = () => {
  if (['andrews-test-app', 'vinoshipper'].includes(appFromURL())) {
    return true;
  }
  return false;
};
