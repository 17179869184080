import { useSelector } from 'react-redux';

import { useListAppXAccountsQuery } from 'api';
import { appFromURL } from 'utils/helpers/apiHelper';

import { ROLE } from './enums';

export const useListAppXAccounts = () => {
  const { data: appXAccounts, isLoading, error } = useListAppXAccountsQuery();
  return {
    data: appXAccounts?.data,
    total: appXAccounts?.total,
    error,
    isLoading
  };
};

export const useIsAdminOwner = () => {
  const { data: appXAccounts, isLoading } = useListAppXAccounts();

  const user = useSelector((state) => state.user?.data);

  if (!user) {
    return false;
  }

  const { email: userEmail } = user;

  if (isLoading) return false;

  // find users appXAccount data:
  const appXAccount = appXAccounts.find((a) => a.email === userEmail);

  // Search through the apps the user has and if THIS app has a role of Admin Owner they can Invite users.
  const isAdminOwner =
    appXAccount &&
    appXAccount.apps.find((app) => app.appId === appFromURL())?.role ===
      ROLE.ADMIN_OWNER;

  return isAdminOwner;
};

export const useIsMyAccount = (appXAccount) => {
  const user = useSelector((state) => state.user.data);
  const { email: userEmail } = user;

  if (!appXAccount) return false;
  const { email } = appXAccount;

  const isMyAccount = userEmail === email;
  return isMyAccount;
};
