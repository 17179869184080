import moment from 'moment-timezone';
import { useState, useEffect } from 'react';

import Text from 'components/text';
import {
  endOfDayToISODate,
  startOfDayToISODate
} from 'utils/helpers/dateHelper';
import {
  getOperatorOptions,
  getOperatorValueFromQueryString,
  getDateValuesFromQueryString
} from 'utils/helpers/filterHelper';

import {
  FilterApplyButtonStyles,
  FilterDatePickerStyles,
  FilterSelectStyles
} from './styles';

const FilterByDate = (props) => {
  const { filter, value = '', onChange } = props;
  const { key } = filter.key;
  const [dates, setDates] = useState(getFilterValues(value, filter));
  const [error, setError] = useState(null);

  useEffect(() => {
    setDates(getFilterValues(value, filter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleOperatorChange = (e) => {
    setDates({ ...dates, operator: e.target.value });
  };

  const onStartDateChange = (startDate) => {
    setDates({ ...dates, startDate: startDate.format('MMM D, YYYY') });
    setError(null);
  };

  const onEndDateChange = (endDate) => {
    setDates({ ...dates, endDate: endDate.format('MMM D, YYYY') });
    setError(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);

    const { startDate, endDate, operator } = dates;
    if (!startDate) {
      return setError(
        operator === 'Between' ? 'Start date is required' : 'Date is required'
      );
    }

    switch (operator) {
      case 'Less Than': {
        return onChange(key, `lt:${endOfDayToISODate(startDate)}`);
      }
      case 'Less Than or Equal To': {
        return onChange(key, `lte:${endOfDayToISODate(startDate)}`);
      }
      case 'Greater Than': {
        return onChange(key, `gt:${startOfDayToISODate(startDate)}`);
      }
      case 'Greater Than or Equal To': {
        return onChange(key, `gte:${startOfDayToISODate(startDate)}`);
      }
      case 'Between': {
        if (!endDate) {
          return setError('End date is required');
        }
        return onChange(
          key,
          `btw:${startOfDayToISODate(startDate)}|${endOfDayToISODate(endDate)}`
        );
      }
      default: {
        throw new Error('Invalid date filter operator');
      }
    }
  };

  const { operator, startDate, endDate } = dates;
  const operators = getOperatorOptions(filter.value.operators);
  const { startDateLabel, endDateLabel } = getDatePickerLabels(operator);

  const isValidStartDate = (current) => {
    if (!endDate) {
      return true;
    }
    return current.isSameOrBefore(moment(endDate, 'MMM D, YYYY'));
  };

  const isValidEndDate = (current) => {
    if (!startDate) {
      return true;
    }
    return current.isSameOrAfter(moment(startDate, 'MMM D, YYYY'), 'day');
  };

  return (
    <>
      <FilterSelectStyles
        label=""
        options={operators}
        value={operator}
        onChange={handleOperatorChange}
      />
      <FilterDatePickerStyles
        label=""
        placeholder={startDateLabel}
        value={startDate}
        onChange={onStartDateChange}
        isValidDate={isValidStartDate}
      />
      {operator === 'Between' && (
        <FilterDatePickerStyles
          label=""
          value={endDate}
          placeholder={endDateLabel}
          onChange={onEndDateChange}
          isValidDate={isValidEndDate}
        />
      )}
      {error && (
        <Text error small>
          {error}
        </Text>
      )}
      <FilterApplyButtonStyles onClick={handleSubmit}>
        Apply Filter
      </FilterApplyButtonStyles>
    </>
  );
};

const getDatePickerLabels = (operator) => {
  if (operator === 'Between') {
    return {
      startDateLabel: 'Start Date',
      endDateLabel: 'End Date'
    };
  }

  return {
    startDateLabel: 'Date'
  };
};

const getFilterValues = (value, filter) => {
  const operator = getOperatorValueFromQueryString(value, filter);
  const { startDate, endDate } = getDateValuesFromQueryString(value, filter);

  return { operator, startDate, endDate };
};

export default FilterByDate;
