import styled from 'styled-components';

import Heading from 'components/heading';
import { Page, PageContent, PageSidebar } from 'components/page';
import TestTenants from 'versionTestTenant/TestTenants';

import VersionHeader from './components/common/Header';
import InstallationForm from './components/installation/InstallationForm';
import { useGetVersion } from './utils/hooks';

const Installation = () => {
  const { isLoading } = useGetVersion();

  if (isLoading) return null;

  return (
    <Page title="Version Installation">
      <PageContent>
        <VersionHeader />
        <StyledHeading>Installation</StyledHeading>
        <InstallationForm />
      </PageContent>
      <PageSidebar>
        <TestTenants />
      </PageSidebar>
    </Page>
  );
};

const StyledHeading = styled(Heading)`
  margin-bottom: 10px;
`;

export default Installation;
