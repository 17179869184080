import { FormDescription, LinkToDocs } from 'components/formInstructions';
import { Field, Input } from 'components/formik';

import {
  IFRAME_MESSAGE,
  IFRAME_MESSAGE_DOCS
} from '../../../../utils/constants';

import IFrameField from './common/IFrameField';

const TabMenuForm = () => (
  <>
    <Field label="Label" name="hookData.title" component={Input} />
    <IFrameField urlLabel="iFrame URL" />
    <FormDescription>
      {IFRAME_MESSAGE} <LinkToDocs url={IFRAME_MESSAGE_DOCS} />
    </FormDescription>
  </>
);

export default TabMenuForm;
