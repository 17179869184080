import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { api } from 'api';
import flashMessageReducer from 'flashMessage/redux/flashMessageSlice';
import layoutReducer from 'layout/redux/layoutSlice';
import modalReducer from 'modal/redux/modalSlice';
import userReducer from 'user/redux/userSlice';
import { rtkQueryErrorLogger } from 'utils/helpers/reduxMiddleware';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    flashMessage: flashMessageReducer,
    layout: layoutReducer,
    modal: modalReducer,
    user: userReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['modal/setModal'],
        ignoreState: true
      }
    })
      .concat(api.middleware)
      .concat(rtkQueryErrorLogger)
});

setupListeners(store.dispatch);
