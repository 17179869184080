import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  useUpdateVersionSettingMutation,
  useDeleteVersionSettingMutation
} from 'api';
import { Button } from 'components/button';
import { Formik, ModalFormFooter } from 'components/formik';
import { Modal, ModalBody } from 'components/modal';
import { setModal } from 'modal/redux/modalSlice';

import FormGeneral from './components/common/FormGeneral';
import { DATA_TYPE } from './utils/enums';
import { validateCreate } from './utils/validators';

const EditModal = (props) => {
  const { setting } = props;
  const dispatch = useDispatch();
  const params = useParams();
  const [isDeleting, setDeleting] = useState(false);
  const [updateVersionSetting] = useUpdateVersionSettingMutation();
  const [deleteVersionSetting] = useDeleteVersionSettingMutation();

  const handleSubmit = async (values, formik) => {
    const data = transformData(values);
    const { error } = await updateVersionSetting({
      data,
      versionId: params.id,
      settingId: setting.id
    });
    formik.setSubmitting(false);
    if (!error) {
      handleClose();
    }
  };

  const handleDeleteField = async () => {
    setDeleting(true);
    const { error } = await deleteVersionSetting({
      versionId: params.id,
      settingId: setting.id
    });
    setDeleting(false);
    if (!error) {
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(setModal(null));
  };

  return (
    <Modal title="Edit Field" visible onClose={handleClose}>
      <Formik
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={getInitialValues(setting)}
      >
        <ModalBody>
          <FormGeneral editForm />
        </ModalBody>
        <ModalFormFooter saveButtonText="Save Field">
          <Button
            variant="secondary"
            startIcon="trash"
            onClick={handleDeleteField}
            loading={isDeleting}
          >
            Delete Field
          </Button>
        </ModalFormFooter>
      </Formik>
    </Modal>
  );
};

const transformData = (values) => {
  const data = {
    title: values.title,
    dataType: values.dataType,
    isRequired: values.isRequired,
    sortOrder: values.sortOrder
  };
  if (
    [DATA_TYPE.RADIO, DATA_TYPE.CHECKBOX, DATA_TYPE.SELECT].includes(
      values.dataType
    )
  ) {
    data.options = [];
    values.options.forEach((option) => {
      if (option) {
        data.options.push(option);
      }
    });
  }
  return data;
};

const validate = (values) => {
  const data = transformData(values);
  const errors = validateCreate(data);
  return errors;
};

const getInitialValues = (setting) => {
  const initialValues = {
    title: setting.title,
    dataType: setting.dataType,
    isRequired: setting.isRequired,
    sortOrder: setting.sortOrder,
    code: setting.code,
    options: setting.options || ['']
  };
  return initialValues;
};

export default EditModal;
