import {
  DARK_SKIN,
  LIGHT_SKIN,
  DARK_CONTENT,
  LIGHT_CONTENT
} from './constants';

export const getTinyMceStyles = (themeMode = 'light') => {
  if (themeMode === 'dark') {
    return {
      skin: DARK_SKIN,
      content: DARK_CONTENT
    };
  }

  return {
    skin: LIGHT_SKIN,
    content: LIGHT_CONTENT
  };
};
