import { setTokenCookie, removeTokenCookie } from 'utils/helpers/cookieHelper';

import { loginUser, logoutUser } from './loginApi';

export const login = (data, onSuccess, onError, twofaFunction) => async () => {
  try {
    const response = await loginUser(data);
    const { token } = response.data;
    setTokenCookie(token);
    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    if (onError) {
      if (error.response && error.response.status === 401) {
        if (error.response?.data?.key === 'tokenRequired' && twofaFunction) {
          twofaFunction();
        }
      }
    }
    if (onError) {
      onError();
    }
  }
};

export const logout = (onSuccess) => async () => {
  try {
    await logoutUser();
  } catch (error) {
    // Fail silenetly
  }
  removeTokenCookie();
  onSuccess();
};
