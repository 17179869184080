import { Route, Routes } from 'react-router-dom';

import NotFound from '404/NotFound';
import { useIsAdminOwner } from 'appXAccount/utils/hooks';
import AppXTenantList from 'appXTenant/List';
import { useIsMegaAdmin } from 'user/utils/hooks';
import { appFromURL } from 'utils/helpers/apiHelper';

const InstallRoutes = () => {
  const appId = appFromURL();
  const isMegaAdmin = useIsMegaAdmin();
  const isAdminOwner = useIsAdminOwner();

  if (!appId) {
    return null;
  }

  const showRoute = isMegaAdmin || isAdminOwner;

  if (!showRoute) {
    return (
      <Routes>
        <Route path="/" element={<NotFound />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<AppXTenantList />} />
    </Routes>
  );
};

export default InstallRoutes;
