import { useSelector } from 'react-redux';

import { ModalApiLoadingStyles } from './styles';

const ModalApiLoading = () => {
  const apiReducer = useSelector((state) => state.layout.api);

  const isVisible = apiReducer.activeRequests?.length > 0;

  return <ModalApiLoadingStyles isVisible={isVisible} />;
};

export default ModalApiLoading;
