import { FieldArray } from 'formik';
import styled from 'styled-components';

import { FormDescription } from 'components/formInstructions';
import { Field, Checkbox, Html } from 'components/formik';
import Heading from 'components/heading';
import Region from 'components/region';
import Text from 'components/text';

import ScreenshotsFieldArray from './details/ScreenshotsFieldArray';

const Details = () => (
  <Region borderBottom>
    <Heading>Details</Heading>
    <StyledFormDescription>
      Add more details on how your app works. We recommend listing at least 3
      key benefits and going into detail about everything that your app can do.
      If your app works with another software, give a summary of how they work
      together. <Text strong>Note:</Text> Do not mention other apps in your
      listing or it will be declined.
    </StyledFormDescription>
    <Field
      name="description"
      component={Html}
      label="Description"
      height={184}
    />
    <Field
      name="isSubscriptionToAnotherSoftwareVendorRequired"
      label="Subscription to another software provider is required for this app"
      component={Checkbox}
    />
    <Heading level={3}>Add at least 3 screenshots</Heading>
    <StyledFormDescription>
      Show the key pages or functionality within Commerce7 and other software
      (if applicable).
    </StyledFormDescription>
    <FieldArray name="screenShots" component={ScreenshotsFieldArray} />
  </Region>
);

const StyledFormDescription = styled(FormDescription)`
  margin-bottom: 20px;
`;

export default Details;
