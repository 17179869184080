import { TYPE } from 'app/utils/enums';
import { useGetApp } from 'app/utils/hooks';
import Alert from 'components/alert';
import { STATUS } from 'version/utils/enums';
import {
  useHasApprovedVersion,
  useGetLatestVersion
} from 'version/utils/hooks';

const AppStatus = () => {
  const { hasApprovedVersion, isLoading } = useHasApprovedVersion();
  const { latestVersion } = useGetLatestVersion();
  const { app } = useGetApp();

  if (isLoading) return null;

  if (latestVersion.status === STATUS.SUBMITTED) {
    return (
      <Alert variant="warning">
        Your app has been submitted and is pending approval by Commerce7.
      </Alert>
    );
  }

  if (hasApprovedVersion) return null;

  if (app.type === TYPE.PRIVATE) {
    return (
      <Alert variant="info">
        Configure your app through “Version 1”, then create your “App Listing”.
        Once you&apos;ve completed both, submit the app for approval. After our
        team approves it, you&apos;ll be able to grant access to specific
        clients at the bottom of this page.{' '}
      </Alert>
    );
  }

  return (
    <Alert variant="info">
      Configure your app through “Version 1”, then create your “App Listing”.
      Once you&apos;ve completed both, submit the app for approval to make it
      live on the App Store.{' '}
    </Alert>
  );
};

export default AppStatus;
