import { currencyFormat } from 'utils/helpers/numberHelper';

import { PAYMENT_HANDLED_BY } from '../../utils/enums';

const MonthlyRevenue = (props) => {
  const { app } = props;
  const { monthlyRevenue, currency, paymentHandledBy } = app;

  if (paymentHandledBy === PAYMENT_HANDLED_BY.EXTERNAL) {
    return 'Custom';
  }

  const currencyDisplay = ['ZAR', 'EUR', 'GBP'].includes(currency)
    ? ''
    : currency;

  return (
    <>
      {currencyFormat(monthlyRevenue, currency)} {currencyDisplay}
    </>
  );
};

export default MonthlyRevenue;
