import { useSelector } from 'react-redux';

import ModalErrorBoundary from './ErrorBoundary';

const Modals = () => {
  const modal = useSelector((state) => state.modal);
  if (!modal.modal || typeof modal.modal === 'string') {
    return null;
  }

  return <ModalErrorBoundary>{modal.modal}</ModalErrorBoundary>;
};

export default Modals;
