import queryString from 'query-string';
import styled from 'styled-components';

import Tags from 'components/filterTags';
import { useHandleSearch } from 'utils/hooks/useHandleSearch';
import useQuery from 'utils/hooks/useQuery';

import Filters from './components/Filters';
import SearchInput from './components/SearchInput';

const SearchTools = (props) => {
  const {
    label,
    autoFocus = true,
    onSearch,
    filters,
    hideSearch = false
  } = props;

  const query = useQuery();

  const defaultOnSearch = useHandleSearch();

  const handleFilter = (filter) => {
    if (onSearch) {
      onSearch(buildSearchQuery({ [filter.key]: filter.value, page: null }));
    } else {
      defaultOnSearch(
        buildSearchQuery({ [filter.key]: filter.value, page: null })
      );
    }
  };

  const handleSearch = (q) => {
    if (onSearch) {
      onSearch(buildSearchQuery({ q: encodeURIComponent(q), page: null }));
    } else {
      defaultOnSearch(
        buildSearchQuery({ q: encodeURIComponent(q), page: null })
      );
    }
  };

  const buildSearchQuery = (q) => {
    const newQuery = { ...query, ...q };

    return queryString.stringify(newQuery, {
      skipEmptyString: true,
      skipNull: true,
      encode: false
    });
  };

  return (
    <FormStyles>
      <FiltersStyles>
        {!hideSearch && (
          <SearchInput
            autoFocus={autoFocus}
            onSearch={handleSearch}
            label={label}
          />
        )}
        <Filters filters={filters} onFilter={handleFilter} />
      </FiltersStyles>
      <Tags filters={filters} onFilter={handleFilter} />
    </FormStyles>
  );
};

const FormStyles = styled.div`
  margin-bottom: 15px;
`;

const FiltersStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 15px;
  @media ${({ theme }) => theme.c7__ui.breakpoints.mediumUp} {
    flex-wrap: nowrap;
  }
`;

export default SearchTools;
