import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useSubmitAppTempListingMutation } from 'api';
import { LinkButton, Button } from 'components/button';
import { useGetLatestVersion } from 'version/utils/hooks';

import { STATUS as APP_TEMP_LISTING_STATUS } from '../../utils/enums';
import { useGetAppTempListing } from '../../utils/hooks';

const ListingsActionsButton = () => {
  const [submitting, setSubmitting] = useState(false);
  const { appTempListing, isLoading, created } = useGetAppTempListing();
  const { isLoading: versionLoading } = useGetLatestVersion();
  const [submitAppTempListing] = useSubmitAppTempListingMutation();

  if (isLoading || versionLoading || !appTempListing) return null;

  const showRequestApprovalButton =
    appTempListing.status === APP_TEMP_LISTING_STATUS.PENDING;

  let icon = 'add';
  let label = 'Create Listing';
  let variant = 'primary';
  if (created) {
    icon = 'edit';
    label = 'Edit Listing';
    variant = 'secondary';
  }

  const handleSubmit = async () => {
    setSubmitting(true);
    await submitAppTempListing(appTempListing.id);
    setSubmitting(false);
  };

  return (
    <StyledButtons>
      {showRequestApprovalButton && (
        <Button onClick={handleSubmit} loading={submitting}>
          Request Approval
        </Button>
      )}
      <LinkButton
        startIcon={icon}
        to={`/listing/${appTempListing.id}`}
        component={Link}
        variant={variant}
      >
        {label}
      </LinkButton>
    </StyledButtons>
  );
};

const StyledButtons = styled.div`
  display: flex;
  align-items: center;
`;

export default ListingsActionsButton;
