/* globals window */
import queryString from 'query-string';
import { Component } from 'react';
import styled from 'styled-components';

import Alert from 'components/alert';
import Heading from 'components/heading';
import { DisplayIcon } from 'components/icon';
import { Page, PageContent } from 'components/page';
import Region from 'components/region';
import Text from 'components/text';
// import logError from 'utils/rollbar';

class AppErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      settingError: false
    };
  }

  componentDidCatch(error) {
    this.setState((state) => ({
      ...state,
      hasError: true,
      settingError:
        error.message === 'Settings Failed To Load From Local Storage'
    }));
    if (error.message.includes('ChunkLoadError')) {
      // prevent an infinite reload
      const { reload } = queryString.parse(window.location.search);
      if (!reload) {
        let newUrl = window.location.href;
        if (!newUrl.includes('?')) {
          newUrl += '?';
        }
        newUrl += '&reload=1';
        window.location.assign(newUrl);
      }
    }
    // if (error.message !== 'Settings Failed To Load From Local Storage') {
    //   logError(error, info);
    // }
  }

  render() {
    const { state, props } = this;
    if (!state.hasError) {
      return props.children;
    }
    if (state.settingError) {
      return (
        <Region>
          <Alert variant="error">
            Error writing or reading from Local Storage. Do you have a privacy
            blocker on? If this error continues please contact Commerce7.
          </Alert>
        </Region>
      );
    }
    return (
      <Page title="Error">
        <PageContent>
          <Region>
            <Heading level={1}>Error</Heading>
            <ErrorStyles>
              <DisplayIcon icon="warning" variant="error" />
              <Heading>Oops!</Heading>
              <Text block>
                Something went wrong. Try refreshing the page. If it persists
                please contact support.
              </Text>
              <Text block>
                If you are the developer or a geek and in development mode - we
                are pushing the error log out to the console so check that out.
              </Text>
            </ErrorStyles>
          </Region>
        </PageContent>
      </Page>
    );
  }
}

const ErrorStyles = styled.div`
  text-align: center;
  padding: 50px 0;
`;

export default AppErrorBoundary;
