import { Columns, Column } from 'components/columns';
import { Field, Input } from 'components/formik';
import { normalizeInteger } from 'utils/helpers/formHelper';

const IFrameField = (props) => {
  const { urlLabel = 'Page iFrame URL' } = props;

  return (
    <>
      <Columns>
        <Column span={9}>
          <Field
            label={urlLabel}
            name="hookData.url"
            component={Input}
            placeholder="https://"
          />
        </Column>
        <Column span={3}>
          <Field
            label="iFrame Height (px)"
            name="hookData.iFrameHeight"
            component={Input}
            normalize={normalizeInteger}
            placeholder="500"
          />
        </Column>
      </Columns>
    </>
  );
};

export default IFrameField;
