import Text from 'components/text';

const AuthCredentials = (props) => {
  const { webhook } = props;

  if (!webhook.username) {
    return 'None';
  }
  return <Text>{webhook.username}</Text>;
};

export default AuthCredentials;
