import { addFlashMessage } from 'flashMessage/redux/flashMessageSlice';
import { removeAllApiErrors } from 'layout/redux/layoutSlice';

const listAppXTenants = (build) =>
  build.query({
    query: (query) => ({
      url: `/app-x-tenant`,
      method: 'GET',
      params: query
    }),
    providesTags: () => {
      const tags = [{ type: 'AppXTenant', id: 'LIST' }];
      return tags;
    }
  });

const updateTenantMonthlyFee = (build) =>
  build.mutation({
    query: ({ appXTenantId, data }) => ({
      url: `/app-x-tenant/${appXTenantId}`,
      method: 'PUT',
      data
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({
          message: 'Tenant Price Updated.',
          type: 'success'
        })
      );
    },
    invalidatesTags: () => [{ type: 'AppXTenant', id: 'LIST' }]
  });

const appXTenantsApi = {
  listAppXTenants,
  updateTenantMonthlyFee
};
export default appXTenantsApi;
