import { Link } from 'react-router-dom';

import { TYPE } from 'app/utils/enums';
import { useGetApp } from 'app/utils/hooks';
import Heading from 'components/heading';
import Text from 'components/text';
import { useHasApprovedVersion } from 'version/utils/hooks';

import AddForm from './components/AddForm';
import Grid from './components/Grid';

const PrivateTenants = () => {
  const { hasApprovedVersion, isLoading } = useHasApprovedVersion();
  const { app } = useGetApp();

  if (app.type !== TYPE.PRIVATE || isLoading) return null;

  if (!hasApprovedVersion) {
    return (
      <>
        <Heading>Clients</Heading>
        <Text>
          Once your app is submitted, you&apos;ll be able to grant access to
          specific clients to install it.
        </Text>
      </>
    );
  }

  return (
    <>
      <Heading>Clients</Heading>
      <Text>
        Allow clients to access and install your app by adding their TenantID
        below. Clients with access will be able to find your app in the App
        Store or you can send them the App Link to install it directly.
      </Text>
      <AddForm />
      <Grid />
      <Text>
        View more information and a full list of your installs{' '}
        <Link to="/install">here</Link>.
      </Text>
    </>
  );
};

export default PrivateTenants;
