import { useState, useEffect } from 'react';

import {
  FilterHeaderStyles,
  FilterHeaderButton,
  FilterApplyButtonStyles,
  FilterCheckboxStyles
} from './styles';

const FilterByCheckbox = (props) => {
  const { value = '', filter, onChange } = props;
  const { options } = filter.value;
  const { key } = filter.key;
  const [checkedFilters, setCheckedFilters] = useState(
    getCheckedFilters(value)
  );

  useEffect(() => {
    setCheckedFilters(getCheckedFilters(value));
  }, [value]);

  const handleDeselectAll = (e) => {
    e.preventDefault();
    setCheckedFilters([]);
  };

  const handleSelectAll = (e) => {
    e.preventDefault();
    const allFilters = options.map((option) => option.value);
    setCheckedFilters(allFilters);
  };

  const handleOnChange = (e, option) => {
    let newFilters = [...checkedFilters];
    if (checkedFilters.includes(option)) {
      newFilters = checkedFilters.filter((f) => f !== option);
    } else {
      newFilters = [...checkedFilters, option];
    }
    newFilters = newFilters.filter(Boolean);

    setCheckedFilters(newFilters);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let newSearchQuery = checkedFilters.join(',');

    if (checkedFilters.length > 1) {
      newSearchQuery = `in:${newSearchQuery}`;
    }

    onChange(key, newSearchQuery);
  };

  return (
    <>
      <FilterHeaderStyles>
        <FilterHeaderButton onClick={handleSelectAll}>
          Select all
        </FilterHeaderButton>
        <FilterHeaderButton onClick={handleDeselectAll}>
          Deselect all
        </FilterHeaderButton>
      </FilterHeaderStyles>
      {options.map((filterOption) => {
        const checked = checkedFilters.includes(filterOption.value);
        return (
          <FilterCheckboxStyles
            key={filterOption.value}
            checked={checked}
            label={filterOption.label}
            onChange={(e) => handleOnChange(e, filterOption.value)}
          />
        );
      })}
      <FilterApplyButtonStyles onClick={handleSubmit}>
        Apply Filter
      </FilterApplyButtonStyles>
    </>
  );
};

const getCheckedFilters = (value) =>
  value.replace('in:', '').split(',').filter(Boolean);

export default FilterByCheckbox;
