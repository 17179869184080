import { dateFormat } from 'utils/helpers/dateHelper';

const DateTag = (props) => {
  const { value, label } = props;

  if (!value) {
    return null;
  }
  const formattedDate = getFormattedDate(value);

  return `${label} is ${formattedDate}`;
};

const getFormattedDate = (value) => {
  if (value.includes('lt:')) {
    return `less than ${dateFormat(value.replace('lt:', ''))}`;
  }

  if (value.includes('lte:')) {
    return `less than or equal to ${dateFormat(value.replace('lte:', ''))}`;
  }

  if (value.includes('gt:')) {
    return `greater than ${dateFormat(value.replace('gt:', ''))}`;
  }

  if (value.includes('gte:')) {
    return `greater than or equal to ${dateFormat(value.replace('gte:', ''))}`;
  }

  if (value.includes('btw:')) {
    const values = value.replace('btw:', '').split('|');
    return `between ${dateFormat(values[0])} and ${dateFormat(values[1])}`;
  }

  return null;
};

export default DateTag;
