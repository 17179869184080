import styled from 'styled-components';

import { useCreatePrivateTenantMutation } from 'api';
import { Button } from 'components/button';
import { Field, Input, Formik } from 'components/formik';

import { validateAddPrivateTenant } from '../utils/validators';

const AddForm = () => {
  const [createPrivateTenant] = useCreatePrivateTenantMutation();

  const handleSubmit = async (values, formik) => {
    const data = transformData(values);
    const { error } = await createPrivateTenant({ data });
    formik.setSubmitting(false);
    if (!error) {
      formik.resetForm();
    }
  };

  return (
    <StyledClientForm>
      <Formik
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={getInitialValues()}
      >
        <StyledField>
          <Field
            name="tenantId"
            label="TenantID"
            placeholder="spectra-winery"
            component={Input}
          />
          <StyledButton type="submit" startIcon="add">
            Add
          </StyledButton>
        </StyledField>
      </Formik>
    </StyledClientForm>
  );
};

const transformData = (values) => {
  const data = {
    tenantId: values.tenantId
  };
  return data;
};

const validate = (values) => {
  const data = transformData(values);
  const errors = validateAddPrivateTenant(data);
  return errors;
};

const getInitialValues = () => {
  const initialValues = {
    tenantId: ''
  };
  return initialValues;
};

const StyledClientForm = styled.div`
  margin-top: 22px;
`;

const StyledField = styled.div`
  display: flex;
  gap: 10px;

  > div {
    flex: 1;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 24px;
`;

export default AddForm;
