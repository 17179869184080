import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Heading from 'components/heading';
import { Modal, ModalBody } from 'components/modal';
import Text from 'components/text';
import { setModal } from 'modal/redux/modalSlice';

const AboutSoftwareModal = () => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(setModal(null));
  };

  const year = new Date().getFullYear();

  //   const settings = useSelector((state) => state.setting.data);

  return (
    <Modal title="About Commerce7" visible onClose={onClose}>
      <ModalBody>
        <AboutStyles>
          <Heading>Software by Commerce7 Platform Inc</Heading>
          {/* <Database settings={settings} /> */}
          <Text block>
            A lot of fine coders spent a lot of hours making this software for
            you.
          </Text>
          <br />
          <Text block>&copy; Copyright {year} Commerce7 Platform Inc</Text>
          <br />
          <Text>
            <a
              href="https://commerce7.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            &nbsp; | &nbsp;
            <a
              href="https://commerce7.com/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service
            </a>
          </Text>
        </AboutStyles>
      </ModalBody>
    </Modal>
  );
};

const AboutStyles = styled.div`
  text-align: center;
`;

// const Database = (props) => {
//   const { settings } = props;
//   if (!settings.tenant.database) {
//     return null;
//   }
//   return (
//     <>
//       <Text block>
//         Database: <strong>{settings.tenant.database}</strong>
//       </Text>
//       <br />
//     </>
//   );
// };

export default AboutSoftwareModal;
