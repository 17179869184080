import Text from 'components/text';
import { currencyFormat } from 'utils/helpers/numberHelper';

const Price = (props) => {
  const { appXTenant } = props;
  const { currency, monthlyFee, isPriceOverride, originalMonthlyFee } =
    appXTenant;

  if (isPriceOverride) {
    return (
      <>
        <div>
          <Text strikeThrough secondary>
            {currency} {currencyFormat(originalMonthlyFee, currency)}
          </Text>{' '}
          <Text>
            {currency} {currencyFormat(monthlyFee, currency)}
          </Text>
        </div>
        <Text small secondary>
          Override
        </Text>
      </>
    );
  }

  return (
    <Text>
      {currency} {currencyFormat(monthlyFee, currency)}
    </Text>
  );
};

export default Price;
