import { useRef } from 'react';
import styled from 'styled-components';

import { ContextMenu, ContextMenuItem } from 'components/contextMenu';
import FileUploader from 'components/fileUploader';
import { useFormikContext, TextArea, Field } from 'components/formik';
import Icon from 'components/icon';
import Picture from 'components/picture';
import Text from 'components/text';
import { appFromURL } from 'utils/helpers/apiHelper';
import { IMAGE_SIZES } from 'utils/helpers/imageHelper';

import DeleteButton from '../../common/DeleteButton';

const ScreenshotUploader = (props) => {
  const { index, remove } = props;
  const fileUploaderRef = useRef();
  const { values, setFieldValue } = useFormikContext();

  const { image } = values.screenShots[index];

  const onUploadComplete = (data) => {
    const { directory, fileName } = data.find(
      (file) => file.key === IMAGE_SIZES.ORIGINAL
    );
    const iconPath = `${directory}${fileName}`;
    setFieldValue(`screenShots[${index}].image`, iconPath);
  };

  const handleUpload = () => {
    fileUploaderRef.current.handleUploadClick();
  };

  const handleDelete = () => {
    remove(index);
  };

  return (
    <StyledFileUploader
      fileSchema={fileSchema}
      onUploadComplete={onUploadComplete}
      ref={fileUploaderRef}
    >
      <StyledScreenshotUploader hasImage={!!image}>
        {image && (
          <>
            <Picture src={image} alt="screenshot" size={IMAGE_SIZES.THUMB} />
            <DeleteButton onDelete={handleDelete} />
          </>
        )}
        {!image && (
          <>
            <StyledIcon size={60} icon="imagePlaceholder" />
            <StyledContextMenu>
              <ContextMenuItem icon="upload" onClick={handleUpload}>
                Upload
              </ContextMenuItem>
            </StyledContextMenu>
            <Text small secondary>
              Ideally 1600px x 900px
            </Text>
          </>
        )}
      </StyledScreenshotUploader>
      <Field
        name={`screenShots[${index}].caption`}
        label="Caption"
        component={TextArea}
        height={75}
      />
    </StyledFileUploader>
  );
};

const fileSchema = {
  fileType: 'image',
  allowableMimeTypes: [
    'image/jpeg',
    'image/pjpeg',
    'image/png',
    'image/svg+xml',
    'image/avif',
    'image/webp'
  ],
  outputs: [
    {
      key: IMAGE_SIZES.ORIGINAL,
      destination: `${appFromURL()}/images/screenshot/${IMAGE_SIZES.ORIGINAL}/`
    },
    {
      key: IMAGE_SIZES.FULL,
      destination: `${appFromURL()}/images/screenshot/${IMAGE_SIZES.FULL}/`,
      height: 900,
      width: 1600,
      resize: 'ratio',
      convertTo: 'avif'
    },
    {
      key: IMAGE_SIZES.FULL,
      destination: `${appFromURL()}/images/screenshot/${IMAGE_SIZES.FULL}/`,
      height: 900,
      width: 1600,
      resize: 'ratio',
      convertTo: 'webp'
    },
    {
      key: IMAGE_SIZES.FULL,
      destination: `${appFromURL()}/images/screenshot/${IMAGE_SIZES.FULL}/`,
      height: 900,
      width: 1600,
      resize: 'ratio',
      convertTo: 'png'
    },
    {
      key: IMAGE_SIZES.THUMB,
      destination: `${appFromURL()}/images/screenshot/${IMAGE_SIZES.THUMB}/`,
      height: 200,
      width: 287,
      resize: 'ratio',
      convertTo: 'avif'
    },
    {
      key: IMAGE_SIZES.THUMB,
      destination: `${appFromURL()}/images/screenshot/${IMAGE_SIZES.THUMB}/`,
      height: 200,
      width: 287,
      resize: 'ratio',
      convertTo: 'webp'
    },
    {
      key: IMAGE_SIZES.THUMB,
      destination: `${appFromURL()}/images/screenshot/${IMAGE_SIZES.THUMB}/`,
      height: 200,
      width: 287,
      resize: 'ratio',
      convertTo: 'png'
    }
  ]
};

const StyledFileUploader = styled(FileUploader)`
  max-width: 287px;
`;

const StyledScreenshotUploader = styled.div`
  border: 1px dashed ${({ theme }) => theme.c7__ui.borderColor};
  border-style: ${({ hasImage }) => (hasImage ? 'solid' : 'dashed')};
  border-radius: ${({ theme }) => theme.c7__ui.borderRadius};
  overflow: hidden;
  padding: ${({ hasImage }) => (hasImage ? '0px' : '25px')};
  position: relative;
  height: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  picture {
    height: 100%;
    object-fit: scale-down;
    img {
      height: 100%;
      object-fit: scale-down;
    }
  }
`;

const StyledIcon = styled(Icon)`
  path {
    fill: ${({ theme }) =>
      theme.c7__ui.mode === 'light'
        ? theme.c7__ui.colors.gray400
        : theme.c7__ui.colors.gray800};
  }
`;

const StyledContextMenu = styled(ContextMenu)`
  display: flex;
  justify-content: center;
  margin-top: 10px;

  button {
    margin: 0;
  }
`;

export default ScreenshotUploader;
