import { useDeletePrivateTenantMutation } from 'api';
import Icon from 'components/icon';
import { Table, Thead, Tbody, Th, Tr, Td } from 'components/table';
import Text from 'components/text';
import { useListPrivateTenants } from 'privateTenant/utils/hooks';

const Grid = () => {
  const { data: privateTenants, isLoading } = useListPrivateTenants();
  const [deletePrivateTenant] = useDeletePrivateTenantMutation();

  if (isLoading) return null;

  if (privateTenants.length === 0) {
    return <Text>No clients have access.</Text>;
  }

  const handleRemove = async (tenantId) => {
    await deletePrivateTenant({ tenantId });
  };

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>Tenant ID</Th>
          <Th />
        </Tr>
      </Thead>
      <Tbody>
        {privateTenants.map((tenant) => (
          <Tr key={tenant.id}>
            <Td>{tenant.tenantId}</Td>
            <Td align="right">
              <Icon icon="trash" onClick={() => handleRemove(tenant.id)} />
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default Grid;
