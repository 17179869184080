import { useEscKeydown, useOnClickOutside } from '@commerce7/frontend-utils';
import { useState, useRef } from 'react';

import FilterItem from 'components/filterItem';
import useQuery from 'utils/hooks/useQuery';

import {
  FilterStyles,
  FilterDropdownStyles,
  FiltersToggleButton,
  HiddenFilterButtonStyles,
  HiddenFilterDropdownItemStyles,
  DropdownIcon
} from './styles';

const HiddenFilters = (props) => {
  const [open, setOpen] = useState(false);
  const [activeFilterKey, setActiveFilterKey] = useState(null);
  const query = useQuery();
  const filterRef = useRef();

  useOnClickOutside(filterRef, () => closeHiddenFilter());
  useEscKeydown(() => closeHiddenFilter());

  const handleButtonClick = (e) => {
    e.preventDefault();
    if (!open) {
      openHiddenFilter();
    } else if (activeFilterKey) {
      setActiveFilterKey(null);
    } else {
      closeHiddenFilter();
    }
  };

  const handleFilterItemButtonClick = (e, filterKey) => {
    e.preventDefault();
    setActiveFilterKey(filterKey);
  };

  const handleFilterChange = (filterKey, filters) => {
    const { onChange } = props;
    closeHiddenFilter();
    onChange(filterKey, filters);
  };

  const closeHiddenFilter = () => {
    setOpen(false);
    setTimeout(() => {
      setActiveFilterKey(null);
    }, 300);
  };

  const openHiddenFilter = () => {
    setOpen(true);
  };

  const { filters, hiddenFilterKeys, visibleFilterKeys } = props;

  let activeFilter;
  if (activeFilterKey) {
    activeFilter = filters[activeFilterKey];
  }

  return (
    <FilterStyles ref={filterRef}>
      <FiltersToggleButton onClick={handleButtonClick}>
        {visibleFilterKeys.length > 0 ? 'More' : 'All'} Filters
        <DropdownIcon icon="chevronDown" rotate={open} size={12} />
      </FiltersToggleButton>
      <FilterDropdownStyles visible={open}>
        {!activeFilter &&
          hiddenFilterKeys.map((filterKey) => {
            const filter = filters[filterKey];
            return (
              <HiddenFilterDropdownItemStyles key={filterKey}>
                <HiddenFilterButtonStyles
                  variant="text"
                  onClick={(e) => handleFilterItemButtonClick(e, filterKey)}
                >
                  {filter.key.label}
                </HiddenFilterButtonStyles>
              </HiddenFilterDropdownItemStyles>
            );
          })}
        {activeFilter && (
          <FilterItem
            filter={activeFilter}
            value={query[activeFilter.key.key] || ''}
            onChange={handleFilterChange}
          />
        )}
      </FilterDropdownStyles>
    </FilterStyles>
  );
};

export default HiddenFilters;
