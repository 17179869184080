import styled from 'styled-components';

import { useGetApp } from 'app/utils/hooks';
import Heading from 'components/heading';
import Text from 'components/text';

import { useListExchangeRates } from './utils/hooks';

const View = () => {
  const { app } = useGetApp();
  const { currency } = app;
  const { data, isLoading } = useListExchangeRates(currency);

  if (isLoading) return null;

  return (
    <>
      <Heading level={3}>Payouts</Heading>
      <StyledText>
        You&apos;ll be paid on the first of every month. Clients will be charged
        in their local currency and amount will be converted to the currency
        that you selected, based on the yearly exchange rates.
      </StyledText>
      <Heading level={3}>2022 Exchange Rates</Heading>
      <StyledGrid>
        {Object.keys(data).map((key) => (
          <div key={key}>
            <Text block>{key}</Text>
            <Text>{data[key].toFixed(4)}</Text>
          </div>
        ))}
      </StyledGrid>
    </>
  );
};

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const StyledText = styled(Text)`
  margin-bottom: 30px;
`;

export default View;
