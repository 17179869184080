import styled from 'styled-components';

import { Button } from 'components/button';
import {
  convertStringToInteger,
  numberWithCommas
} from 'utils/helpers/numberHelper';

const MAX_PAGE = 10;
const PAGE_SIZE = 50;

// @common
const CorePagination = (props) => {
  const { recordCount, page, onPaginationClick } = props;

  if (!page || !recordCount) {
    return null;
  }

  if (recordCount <= PAGE_SIZE) {
    return (
      <StyledPaginationWrapper>
        <StyledPaginationTotal>
          Total: {numberWithCommas(recordCount)}
        </StyledPaginationTotal>
      </StyledPaginationWrapper>
    );
  }

  const currentPage = convertStringToInteger(page);
  const lastPage = Math.ceil(recordCount / PAGE_SIZE);
  const paginationArray = getPaginationArray(currentPage, lastPage);

  const showNextButton =
    currentPage < MAX_PAGE - 2 && currentPage < lastPage - 1;
  const showPreviousButton = currentPage > 2;

  return (
    <>
      <StyledPaginationWrapper>
        {showPreviousButton && (
          <>
            <StyledPaginationButton
              variant="secondary"
              onClick={(e) => onPaginationClick(e, currentPage - 1)}
            >
              &lsaquo;
            </StyledPaginationButton>
            <StyledPaginationBreak>...</StyledPaginationBreak>
          </>
        )}
        {paginationArray.map((paginationItem) => (
          <StyledPaginationButton
            key={paginationItem}
            isActive={currentPage === paginationItem}
            variant={currentPage === paginationItem ? 'primary' : 'secondary'}
            onClick={(e) => onPaginationClick(e, paginationItem)}
          >
            {paginationItem}
          </StyledPaginationButton>
        ))}
        {showNextButton && (
          <>
            <StyledPaginationBreak>...</StyledPaginationBreak>
            <StyledPaginationButton
              variant="secondary"
              onClick={(e) => onPaginationClick(e, currentPage + 1)}
            >
              &rsaquo;
            </StyledPaginationButton>
          </>
        )}
        <StyledPaginationTotal>
          Total: {numberWithCommas(recordCount)}
        </StyledPaginationTotal>
      </StyledPaginationWrapper>
      {currentPage >= MAX_PAGE && (
        <StyledPaginationMaxPageError>
          Max pagination limit reached. Try updating your search for more
          results
        </StyledPaginationMaxPageError>
      )}
    </>
  );
};

const getPaginationArray = (page, lastPage) => {
  if (page === 1) {
    return [1, 2, 3].filter((p) => p <= lastPage);
  }
  if (page >= MAX_PAGE) {
    return [MAX_PAGE - 2, MAX_PAGE - 1, MAX_PAGE].filter((p) => p <= lastPage);
  }

  return [page - 1, page, page + 1].filter((p) => p <= lastPage);
};

const StyledPaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
`;

const StyledPaginationButton = styled(Button)`
  padding: 0;
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 3px !important;
  font-size: ${({ theme }) => theme.c7__ui.fontSizeSmall};
`;

const StyledPaginationBreak = styled.sub`
  display: inline-block;
  margin: 0 6px;
`;

const StyledPaginationTotal = styled.span`
  margin-left: 10px;
  font-size: ${({ theme }) => theme.c7__ui.fontSizeSmall};
`;

const StyledPaginationMaxPageError = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.c7__ui.fontSizeSmall};
  margin-top: 5px;
  color: ${({ theme }) => theme.c7__ui.errorColor};
`;

export default CorePagination;
