import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Logo = () => (
  <StyledLogo to="/">
    <svg
      width="39px"
      height="39px"
      viewBox="0 0 39 39"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Logo</title>
      <g
        id="Dev-Center-&amp;-Apps"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="App---Listing"
          transform="translate(-22.000000, -10.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <g id="Logo" transform="translate(22.000000, 10.000000)">
            <path
              d="M17.1181681,0.0954321476 C8.41284965,0.992033089 1.304546,7.95136352 0.16916832,16.7466178 C-0.71440732,23.5351612 1.93518536,29.7259783 6.43586333,33.8247537 C6.94060166,34.2516359 7.69714204,34.1663278 8.07597935,33.6539097 C8.49678366,33.1416055 8.41284965,32.4157463 7.90811132,31.9888641 C3.99610569,28.4023929 1.72535032,22.9801459 2.4818907,17.0454809 C3.44940035,9.65917712 9.25332404,3.68183523 16.5294957,2.57175924 C27.1290007,0.992033089 36.2131564,9.53108968 35.7923521,20.076823 C35.54055,26.8653663 31.0818391,32.8000314 24.815144,35.2336473 C21.492945,36.5145217 18.338614,36.5572327 15.436085,35.9167955 L26.9599984,13.8860058 C27.1709677,13.4590097 27.2968687,13.1175495 27.2968687,12.7759754 C27.2968687,12.4344013 27.1709677,12.1355382 26.9599984,11.879386 C26.7501634,11.665831 26.4132931,11.5378119 25.9924887,11.5378119 L11.4821124,11.5378119 C11.0624423,11.5378119 10.725572,11.665831 10.515737,11.879386 C10.3047677,12.0928272 10.1788667,12.3916903 10.1788667,12.8186864 C10.1788667,13.2028576 10.3047677,13.5017207 10.515737,13.7152757 C10.725572,13.9287168 11.0624423,14.0568498 11.4821124,14.0568498 L23.8056674,14.0568498 L12.7445253,35.0629172 C11.7770157,36.7279628 12.365688,37.581955 14.3846414,38.1369702 C16.4035947,38.6492745 18.590416,38.8628295 20.8192044,38.6492745 C29.8625274,37.8381071 37.222633,30.4518375 38.1062087,21.2722754 C39.2415864,9.10413912 29.147954,-1.100036 17.1181681,0.0954321476 Z"
              id="logomark-white"
            />
          </g>
        </g>
      </g>
    </svg>
  </StyledLogo>
);

const StyledLogo = styled(Link)`
  margin-right: 24px;
  display: flex;
  align-items: center;
`;

export default Logo;
