import { useDispatch } from 'react-redux';

import { Button } from 'components/button';
import Heading from 'components/heading';
import Region from 'components/region';
import TitleAction from 'components/titleAction';
import { setModal } from 'modal/redux/modalSlice';
import AddModal from 'versionSetting/AddModal';
import Grid from 'versionSetting/Grid';
import { useListVersionSettings } from 'versionSetting/utils/hooks';

const List = () => {
  const dispatch = useDispatch();
  const { isLoading } = useListVersionSettings();

  const handleAddField = () => {
    dispatch(setModal(<AddModal />));
  };
  return (
    <>
      <TitleAction>
        <Heading>Client Settings</Heading>
        <Button variant="secondary" startIcon="add" onClick={handleAddField}>
          Add Field
        </Button>
      </TitleAction>
      <Region>{!isLoading && <Grid />}</Region>
    </>
  );
};

export default List;
