import { ContextMenu, ContextMenuMoreActions } from 'components/contextMenu';

import Delete from './contextMenu/Delete';

const VersionContextMenu = () => (
  <ContextMenu>
    <ContextMenuMoreActions>
      <Delete />
    </ContextMenuMoreActions>
  </ContextMenu>
);

export default VersionContextMenu;
