import { isEmail } from 'validator';

import { validateForUI } from 'utils/helpers/jsonValidator';

const loginUISchema = {
  type: 'object',
  properties: {
    email: { type: 'string' },
    password: { type: 'string', minLength: 8 }
  },
  required: ['email', 'password'],
  additionalProperties: false
};

const twoFaUISchema = {
  type: 'object',
  properties: {
    token: { type: 'string', minLength: 6, maxLength: 6 }
  },
  required: ['token'],
  additionalProperties: false
};

export const validateLogin = (login) => {
  const errors = validateForUI(loginUISchema, login, customValidate(login));
  return errors;
};

const customValidate = (object) => {
  const errorArray = [];
  if (object.email && !isEmail(object.email)) {
    const error = { field: `email`, message: 'Invalid Email' };
    errorArray.push(error);
  }

  return errorArray;
};

export const validate2fa = (params) => {
  const errors = validateForUI(twoFaUISchema, params);
  return errors;
};
