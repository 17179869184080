/* globals window */
import { useNavigate } from 'react-router-dom';

import { useCreateAppMutation } from 'api';
import { Formik, FormFooter } from 'components/formik';
import { appDomain } from 'utils/helpers/routingHelper';

import { TYPE } from '../../utils/enums';
import { validateCreate } from '../../utils/validators';

import FormGeneral from './FormGeneral';

const AddForm = () => {
  const navigate = useNavigate();
  const [createApp] = useCreateAppMutation();

  const handleSubmit = async (values, formik) => {
    const data = transformData(values);
    const response = await createApp({ data });
    window.location.href = `${appDomain(response.data.id)}`;
    formik.setSubmitting(false);
  };

  const handleCancel = () => {
    navigate('/');
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={getInitialValues()}
    >
      <FormGeneral />
      <FormFooter saveButtonText="Add App" onCancel={handleCancel} />
    </Formik>
  );
};

const transformData = (values) => {
  const data = {
    title: values.title,
    appSecret: values.appSecret,
    developerName: values.developerName,
    developerEmail: values.developerEmail?.trim(),
    type: values.type
  };
  return data;
};

const validate = (values) => {
  const data = transformData(values);
  const errors = validateCreate(data);
  return errors;
};

const getInitialValues = () => {
  const initialValues = {
    title: '',
    appSecret: generateSecretKey(),
    developerName: '',
    developerEmail: '',
    type: TYPE.APP
  };
  return initialValues;
};

const generateSecretKey = (length = 64) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890';
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export default AddForm;
