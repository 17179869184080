import styled from 'styled-components';

import DataDisplay from 'components/dataDisplay';
import Text from 'components/text';

import { countries } from '../../../../../utils/helpers';

const CurrencyDisplay = (props) => {
  const { country } = props;

  return (
    <DataDisplay label="Currency">
      <StyledCurrency>
        {country === 'USA'}
        <StyledFlag
          src={`/assets/images/flags/${countries[country].flag}`}
          alt={countries[country].label}
        />
        <Text>{countries[country].label}</Text>
      </StyledCurrency>
    </DataDisplay>
  );
};

export default CurrencyDisplay;

const StyledFlag = styled.img`
  width: 30px;
`;

const StyledCurrency = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`;
