import { Icon as UIIcon } from '@commerce7/admin-ui';

import DisplayIcon from './DisplayIcon';
import IconCustom from './IconCustom';
import VividIcon from './VividIcon';

// eslint-disable-next-line react/jsx-props-no-spreading
const Icon = (props) => <UIIcon {...props} />;

export { IconCustom, VividIcon, DisplayIcon };
export default Icon;
