import { useDispatch } from 'react-redux';

import { ContextMenuItem } from 'components/contextMenu';
import { setModal } from 'modal/redux/modalSlice';

import { APP_STATUS } from '../../../utils/enums';
import { useGetApp } from '../../../utils/hooks';

import EditAppModal from './EditAppModal';

const Edit = () => {
  const dispatch = useDispatch();

  const { app } = useGetApp();
  const { status } = app;

  if (status === APP_STATUS.LIVE) {
    return null;
  }

  const handleShowEditModal = () => {
    dispatch(setModal(<EditAppModal />));
  };
  return (
    <ContextMenuItem icon="edit" onClick={handleShowEditModal}>
      Edit
    </ContextMenuItem>
  );
};
export default Edit;
