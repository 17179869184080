import styled from 'styled-components';

import Card from 'components/card';

const SupportItem = (props) => {
  const { children } = props;
  return <StyledSupportItem>{children}</StyledSupportItem>;
};

const StyledSupportItem = styled(Card)`
  width: 100%;
  display: flex;
  align-items: start;

  > div {
    padding-left: 20px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h3 {
      margin-bottom: 5px;
    }
    button,
    a {
      margin-top: 20px;
    }
  }
`;

export default SupportItem;
