import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useDeleteVersionMutation } from 'api';
import { ContextMenuItem } from 'components/contextMenu';

import { useGetVersion } from '../../../../utils/hooks';

const Delete = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [deleteVersion] = useDeleteVersionMutation();

  const { version, isLoading, isApproved } = useGetVersion();

  if (isLoading || isApproved) return null;
  if (version.versionNumber === 1) return null;

  const handleDelete = async () => {
    setSubmitting(true);
    const { error } = await deleteVersion(params.id);
    if (!error) {
      navigate('/');
    }
  };

  return (
    <ContextMenuItem onClick={handleDelete} icon="trash" disabled={submitting}>
      Delete
    </ContextMenuItem>
  );
};

export default Delete;
