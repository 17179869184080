import { useDispatch } from 'react-redux';

import { useUpdateVersionSecurityBulkMutation } from 'api';
import Alert from 'components/alert';
import { Formik, ModalFormFooter } from 'components/formik';
import { Modal, ModalBody } from 'components/modal';
import { setModal } from 'modal/redux/modalSlice';
import { useGetVersion } from 'version/utils/hooks';

import FormGeneral from './components/editModal/FormGeneral';
import { OBJECT_TYPE, REQUEST_TYPE } from './utils/enums';
import { useListVersionSecurities } from './utils/hooks';
import { validateBulkUpdate } from './utils/validators';

const EditModal = () => {
  const dispatch = useDispatch();
  const { version } = useGetVersion();
  const { data: versionSecurities } = useListVersionSecurities();
  const [updateVersionSecurityBulk] = useUpdateVersionSecurityBulkMutation();

  const handleClose = () => {
    dispatch(setModal(null));
  };

  const handleSubmit = async (values, formik) => {
    const data = transformData(values);
    const { error } = await updateVersionSecurityBulk({
      data,
      versionId: version.id
    });
    formik.setSubmitting(false);
    if (!error) {
      handleClose();
    }
  };

  return (
    <Modal title="API Access" visible onClose={handleClose}>
      <Formik
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={getInitialValues(versionSecurities)}
      >
        <ModalBody>
          <Alert variant="warning">
            Only select the endpoints and access that&apos;s required for your
            app. Your app will be rejected if unneeded permissions are selected.
          </Alert>
          <FormGeneral />
        </ModalBody>
        <ModalFormFooter saveButtonText="Save API Access" />
      </Formik>
    </Modal>
  );
};

const transformData = (values) => {
  const data = [];
  Object.keys(values).forEach((key) => {
    if (values[key].selected) {
      data.push({
        objectType: key,
        requestType: values[key].requestType
      });
    }
  });
  return data;
};

const validate = (values) => {
  const data = transformData(values);
  const errors = validateBulkUpdate(data);
  return errors;
};

const getInitialValues = (versionSecurities) => {
  const initialValues = {};
  Object.keys(OBJECT_TYPE).forEach((key) => {
    const existingSecurity = versionSecurities.find(
      (security) => security.objectType === key
    );
    initialValues[key] = {
      selected: !!existingSecurity,
      requestType: existingSecurity
        ? existingSecurity.requestType
        : REQUEST_TYPE.READ
    };
  });
  return initialValues;
};

export default EditModal;
