import { NoRecords as UINoRecords } from '@commerce7/admin-ui';
import styled from 'styled-components';

const NoRecords = (props) => (
  <StyledWrapper>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <UINoRecords {...props} />
  </StyledWrapper>
);

const StyledWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  > div {
    max-width: 530px;
  }
`;

export default NoRecords;
