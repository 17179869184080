import { useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button } from 'components/button';
import { ModalFooter } from 'components/modal';
import { setModal } from 'modal/redux/modalSlice';

import SaveButton from './formFooter/SaveButton';

const ModalFormFooter = (props) => {
  const {
    saveButtonText,
    cancelButtonText = 'Cancel',
    hideCancel,
    children,
    onCancel
  } = props;
  const dispatch = useDispatch();

  const formikContext = useFormikContext();
  const { isSubmitting } = formikContext;

  const handleOnClose = (e) => {
    if (onCancel) {
      onCancel(e);
    } else {
      dispatch(setModal(null));
    }
  };

  return (
    <ModalFooter>
      {children && <ChildrenStyles>{children}</ChildrenStyles>}
      {!hideCancel && (
        <Button
          variant="secondary"
          onClick={handleOnClose}
          disabled={isSubmitting}
        >
          {cancelButtonText}
        </Button>
      )}
      <SaveButton label={saveButtonText} />
    </ModalFooter>
  );
};

const ChildrenStyles = styled.div`
  width: 100%;
  flex: auto;
  button {
    width: 100%;
  }
  ${({ theme }) => `
    @media ${theme.c7__ui.breakpoints.mediumUp} {
      width: auto;
      button {
        width: auto;
      }
    }
  `}
`;

export default ModalFormFooter;
