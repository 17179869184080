import { validateForUI } from 'utils/helpers/jsonValidator';

const versionUpdateSchema = {
  type: 'object',
  properties: {
    monthlyFee: { type: 'integer', minimum: 0 }
  },
  required: ['monthlyFee'],
  additionalProperties: false
};

// eslint-disable-next-line import/prefer-default-export
export const validateUpdate = (params) =>
  validateForUI(versionUpdateSchema, params);
