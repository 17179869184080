import { useDispatch } from 'react-redux';

import Icon from 'components/icon';
import { setModal } from 'modal/redux/modalSlice';

import AboutSoftwareModal from './AboutSoftwareModal';
import UserDropdownMenuItem from './UserDropdownMenuItem';

const About = () => {
  const dispatch = useDispatch();

  const handleAboutModal = () => {
    dispatch(setModal(<AboutSoftwareModal />));
  };

  return (
    <UserDropdownMenuItem>
      <button type="button" onClick={handleAboutModal}>
        <Icon icon="support" />
        About
      </button>
    </UserDropdownMenuItem>
  );
};

export default About;
