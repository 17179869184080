import Alert from 'components/alert';
import { Field, Input } from 'components/formik';
import Text from 'components/text';

const CardForm = () => (
  <>
    <Alert variant="info">
      <Text>
        Approval is required by Commerce7 to add a Card extension. Email
        support@commerce7.com. If approved, you’ll given configuration
        instructions.
      </Text>
    </Alert>
    <Field label="URL of JSON" name="hookData.url" component={Input} />
  </>
);

export default CardForm;
