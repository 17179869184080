import {
  imageFile,
  pathForMime,
  IMAGE_SIZES,
  getFileExtension
} from 'utils/helpers/imageHelper';

const Picture = (props) => {
  const { src, alt, size = IMAGE_SIZES.ORIGINAL, className } = props;

  let useOriginal = size === IMAGE_SIZES.ORIGINAL;

  let path = src;
  if (!useOriginal) {
    // If this is a V1 App, the original image was stored in icon folder in S3.
    if (!src.includes('/icon/')) {
      path = imageFile(src, size);
    } else {
      useOriginal = true;
    }
  }

  return (
    <picture className={className}>
      {!useOriginal && (
        <source srcSet={pathForMime(path, 'avif')} type="image/avif" />
      )}
      {!useOriginal && (
        <source srcSet={pathForMime(path, 'webp')} type="image/webp" />
      )}
      <img src={pathForMime(path, getFileExtension(src))} alt={alt} />
    </picture>
  );
};

export default Picture;
