import { useSelector } from 'react-redux';

import { Column } from 'components/columns';
import Heading from 'components/heading';
import AppStatsCards from 'stats/AppStatsCards';

import Apps from './dashboard/Apps';
import NoAppsMessage from './dashboard/NoAppsMessage';

const Dashboard = () => {
  const user = useSelector((state) => state.user.data);
  const { firstName } = user;

  return (
    <Column span={8}>
      <Heading level={1}>Welcome, {firstName}</Heading>
      <NoAppsMessage />
      <AppStatsCards />
      <Apps />
    </Column>
  );
};

export default Dashboard;
