/* globals window */
import Cookies from 'js-cookie';

import { cookieDomain } from './cookieHelper';

// @common
export const setColorModeCookie = (colorMode) => {
  Cookies.set('color-mode', colorMode, {
    domain: `.${cookieDomain()}`,
    expires: 365
  });
};

// @common
export const checkUserPreferredColorMode = () => {
  // If the user has explicitly set a color mode, there will be a cookie
  const colorModeCookie = Cookies.get('color-mode');

  if (colorModeCookie) {
    return colorModeCookie;
  }

  // If there is no cookie - check the os settings
  if (window.matchMedia?.('(prefers-color-scheme: dark)')?.matches) {
    return 'dark';
  }

  return 'light';
};
