import { useDispatch } from 'react-redux';

import { Button } from 'components/button';
import Heading from 'components/heading';
import Region from 'components/region';
import TitleAction from 'components/titleAction';
import { setModal } from 'modal/redux/modalSlice';
import EditModal from 'versionSecurity/EditModal';
import List from 'versionSecurity/List';
import { useListVersionSecurities } from 'versionSecurity/utils/hooks';

import { useGetVersion } from '../../utils/hooks';

const APIAccess = () => {
  const dispatch = useDispatch();
  const { total } = useListVersionSecurities();
  const { isApproved } = useGetVersion();

  let buttonMessage = 'Add API Access';
  let buttonIcon = 'add';
  if (total > 0) {
    buttonMessage = 'Edit API Access';
    buttonIcon = 'edit';
  }

  const handleAddAPIAccess = () => {
    dispatch(setModal(<EditModal />));
  };

  return (
    <Region borderBottom>
      <TitleAction>
        <Heading>API Access</Heading>
        {!isApproved && (
          <Button
            variant="secondary"
            startIcon={buttonIcon}
            onClick={handleAddAPIAccess}
          >
            {buttonMessage}
          </Button>
        )}
      </TitleAction>
      <List />
    </Region>
  );
};

export default APIAccess;
