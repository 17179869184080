import { useDispatch } from 'react-redux';

import Icon from 'components/icon';
import { Td, DraggableTr } from 'components/table';
import { setModal } from 'modal/redux/modalSlice';

import EditModal from '../../EditModal';
import { DATA_TYPE_TRANSLATED } from '../../utils/enums';

const SettingRow = (props) => {
  const dispatch = useDispatch();
  const { provided, setting, snapshot } = props;

  const handleEditSetting = () => {
    dispatch(setModal(<EditModal setting={setting} />));
  };

  return (
    <DraggableTr
      provided={provided}
      snapshot={snapshot}
      onClick={() => handleEditSetting(setting)}
    >
      <Td>
        {/* eslint-disable-next-line */}
        <span {...provided.dragHandleProps}>
          <Icon icon="move" />
        </span>
      </Td>
      <Td>{setting.title}</Td>
      <Td>{setting.code}</Td>
      <Td>{DATA_TYPE_TRANSLATED[setting.dataType]}</Td>
      <Td>{setting.isRequired ? 'Yes' : 'No'}</Td>
    </DraggableTr>
  );
};

export default SettingRow;
