import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import { set } from 'lodash';

const excludeHTMLRegex = '^(?!.*<[^>]+>).*';

const nameRegex = "^[A-Za-zÀ-ÖØ-öø-ÿ&-/./' ]*$";

const httpsRegEx = 'https://(.*)';

// @common - whole file
export const validateForUI = (schema, data, customValidation) => {
  let errorArray = [];
  const errors = {};

  schema = addHttpsToUri(schema);
  const results = removeBlankFields(data);
  const ajv = new Ajv({ allErrors: true, useDefaults: true });
  addFormats(ajv);
  const validate = ajv.compile(schema);
  if (!validate(results)) {
    errorArray = transformErrorArray(validate.errors);
  }
  if (typeof customValidation === 'object') {
    const customErrorArray = customValidation;
    errorArray.push(...customErrorArray);
  }
  errorArray.forEach((error) => set(errors, error.field, error.message));
  return errors;
};

const removeBlankFields = (data) => {
  if (Array.isArray(data)) {
    return data.map((element) => removeBlankFields(element));
  }
  const newData = { ...data };
  const newDataArray = Object.keys(newData);
  newDataArray.forEach((element) => {
    if (newData[element] === '' || newData[element] === null) {
      delete newData[element];
    } else {
      traverse(newData[element]);
    }
  });
  return newData;
};

function traverse(x) {
  if (Array.isArray(x)) {
    traverseArray(x);
  } else if (typeof x === 'object' && x !== null) {
    traverseObject(x);
  }
}

function traverseArray(arr) {
  arr.forEach((x) => {
    traverse(x);
  });
}

function traverseObject(obj) {
  const dataArray = Object.keys(obj);
  dataArray.forEach((key) => {
    if (obj[key] === '' || obj[key] === null) {
      delete obj[key]; // eslint-disable-line no-param-reassign
    } else {
      traverse(obj[key]);
    }
  });
}

const transformErrorArray = (jsonErrorArray) => {
  const newArray = [];
  let err = {};

  jsonErrorArray.forEach((jsonError) => {
    let field = jsonError.instancePath;
    // Replace first character if it's a slash
    if (field[0] === '/') {
      field = field.substring(1);
    }
    // Replace first character if it's a period
    if (field[0] === '.') {
      field = field.substring(1);
    }
    // Convert /0/ to [0].
    field = field.replace(/\/\d+\//g, (match) => {
      let string = match;
      string = string.replace('/', '[');
      string = string.replace('/', '].');

      return `${string}`;
    });
    // Convert /0 to [0]
    field = field.replace(/\/\d+/g, (match) => {
      let string = match;
      string = string.replace('/', '[');
      return `${string}]`;
    });

    let errorMessage = jsonError.message;
    switch (jsonError.keyword) {
      case 'additionalProperties':
        if (jsonError.instancePath.length > 0) {
          err = {
            field: `${field}.${jsonError.params.additionalProperty}`,
            message: 'invalid additional property'
          };
        } else {
          err = {
            field: `${jsonError.params.additionalProperty}`,
            message: 'invalid additional property'
          };
        }
        break;
      case 'required':
        if (jsonError.instancePath.length > 0) {
          err = {
            field: `${field}.${jsonError.params.missingProperty}`,
            message: 'required'
          };
        } else {
          err = {
            field: `${jsonError.params.missingProperty}`,
            message: 'required'
          };
        }
        break;
      case 'type':
        err = {
          field: `${field}`,
          message: `must be a ${jsonError.params.type}`
        };
        break;
      case 'maxLength':
        err = {
          field: `${field}`,
          message: jsonError.message
        };
        break;
      case 'minLength':
        err = {
          field: `${field}`,
          message: jsonError.message
        };
        break;
      case 'enum':
        err = {
          field: `${field}`,
          message: jsonError.message
        };
        break;
      case 'minimum':
        err = {
          field: `${field}`,
          message: jsonError.message
        };
        break;
      case 'pattern':
        if (jsonError.params.pattern === excludeHTMLRegex) {
          errorMessage = 'contains invalid string';
        }
        if (jsonError.params.pattern === httpsRegEx) {
          errorMessage = 'Url must start off with https://';
        }
        if (jsonError.params.pattern === nameRegex) {
          errorMessage = 'contains invalid characters for a name';
        }
        err = {
          field: `${field}`,
          message: errorMessage
        };
        break;
      case 'format':
        err = {
          field: `${field}`,
          message: jsonError.message
        };
        break;
      default:
        err = {
          field: `${field}`,
          message: jsonError.message
        };
    }
    newArray.push(err);
  });

  return newArray;
};

const addHttpsToUri = (schema) => {
  if (!schema?.properties) {
    return schema;
  }

  for (const propertyKey of Object.keys(schema.properties)) {
    const property = schema.properties[propertyKey];
    if (property.format === 'uri') {
      property.pattern = httpsRegEx;
    }
    if (property.type === 'object') {
      addHttpsToUri(property);
    }
    if (property.type === 'array') {
      addHttpsToUri(property.items);
    }
  }

  return schema;
};
