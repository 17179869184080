import { getInFilterLabel } from 'utils/helpers/filterHelper';
import { currencyFormat, integerFormat } from 'utils/helpers/numberHelper';
import { constantToTitleCase } from 'utils/helpers/stringHelper';

const TextTag = (props) => {
  const { value, label, filter } = props;

  if (!value) {
    return null;
  }
  const formattedLabel = getFormattedLabel(value, filter);

  return `${label} is ${formattedLabel}`;
};

const getFormattedLabel = (value, filter) => {
  if (value.includes('in:')) {
    return getInFilterLabel(value);
  }

  if (value.includes('lt:')) {
    return `less than ${getFormattedValue(value.replace('lt:', ''), filter)}`;
  }

  if (value.includes('lte:')) {
    return `less than or equal to ${getFormattedValue(
      value.replace('lte:', ''),
      filter
    )}`;
  }

  if (value.includes('gt:')) {
    return `greater than ${getFormattedValue(
      value.replace('gt:', ''),
      filter
    )}`;
  }

  if (value.includes('gte:')) {
    return `greater than or equal to ${getFormattedValue(
      value.replace('gte:', ''),
      filter
    )}`;
  }

  if (value.includes('btw:')) {
    const values = value.replace('btw:', '').split('|');
    return `between ${getFormattedValue(
      values[0],
      filter
    )} and ${getFormattedValue(values[1], filter)}`;
  }

  if (value === 'true') {
    return 'Yes';
  }
  if (value === 'false') {
    return 'No';
  }

  return constantToTitleCase(value);
};

const getFormattedValue = (value, filter) => {
  if (filter.valueType === 'currency') {
    return currencyFormat(value);
  }

  if (filter.valueType === 'integer') {
    return integerFormat(value);
  }

  return constantToTitleCase(value);
};

export default TextTag;
