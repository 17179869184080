import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Formik } from 'components/formik';
import { removeAllApiErrors } from 'layout/redux/layoutSlice';

import { LoginLayout } from '../components/layout';

import FormErrors from './components/FormErrors';
import FormGeneral from './components/FormGeneral';
import LoginActions from './components/LoginActions';
import TwoFactorAuthentication from './components/TwoFactorAuthentication';
import { login } from './redux/loginSlice';
import { validateLogin } from './utils/validators';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isAskFor2fa, setIsAskFor2fa] = useState(false);
  const [email, setEmail] = useState(false);
  const [password, setPassword] = useState(false);

  useEffect(() => {
    dispatch(removeAllApiErrors());
  }, [dispatch]);

  const handleSubmit = (values, formik) => {
    const data = transformData(values);
    formik.setSubmitting(true);

    const onSuccess = () => {
      formik.setSubmitting(false);
      navigate('/');
    };

    const onError = () => {
      formik.setSubmitting(false);
    };

    const twofaFunction = () => {
      setEmail(data.email);
      setPassword(data.password);
      setIsAskFor2fa(true);
      formik.setSubmitting(false);
    };

    dispatch(login(data, onSuccess, onError, twofaFunction));
  };

  if (isAskFor2fa) {
    return <TwoFactorAuthentication email={email} password={password} />;
  }

  return (
    <LoginLayout title="Dev Center Login">
      <Formik
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={getInitialValues()}
      >
        <FormGeneral />
        <FormErrors />
        <LoginActions />
      </Formik>
    </LoginLayout>
  );
};

const transformData = (values) => {
  const data = {
    email: values.email?.trim(),
    password: values.password
  };
  return data;
};

const validate = (values) => {
  const data = transformData(values);
  const errors = validateLogin(data);
  return errors;
};

const getInitialValues = () => {
  const initialValues = {
    email: '',
    password: ''
  };
  return initialValues;
};

export default Login;
