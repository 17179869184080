/* globals document */
// eslint-disable-next-line import/no-extraneous-dependencies
import 'babel-polyfill';

import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import Theme from 'theme/Theme';
import { axiosWrapper } from 'utils/helpers/apiHelper';

import AppErrorBoundary from './AppErrorBoundary';
import App from './Routes';
import { store } from './store';

axiosWrapper(store.dispatch);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Theme>
      <AppErrorBoundary>
        <App />
      </AppErrorBoundary>
    </Theme>
  </Provider>
);
