import View from 'appTempListing/View';
import { Column } from 'components/columns';

const Listing = () => (
  <Column span={6}>
    <View />
  </Column>
);

export default Listing;
