import styled from 'styled-components';

import Alert from 'components/alert';
import { Button } from 'components/button';

import FormActions from './formFooter/FormActions';
import SaveButton from './formFooter/SaveButton';
import useFormikContext from './useFormikContext';

const FormFooter = (props) => {
  const { saveButtonText = 'Save', onCancel } = props;
  const formikContext = useFormikContext();

  const errors = Object.keys(formikContext.errors);

  let errorMessage;

  if (errors.length > 0 && formikContext.submitCount > 0) {
    errorMessage = (
      <StyledAlert variant="error">
        Please fix the form error{errors.length > 1 && 's'} above.
      </StyledAlert>
    );
  }

  let cancelButton;
  if (onCancel) {
    cancelButton = (
      <Button onClick={onCancel} variant="secondary">
        Cancel
      </Button>
    );
  }

  return (
    <>
      {errorMessage}
      <FormActions>
        {cancelButton}
        <SaveButton label={saveButtonText} />
      </FormActions>
    </>
  );
};

const StyledAlert = styled(Alert)`
  margin-top: 10px;
`;

export default FormFooter;
