import { useListAppXTenantsQuery } from 'api';
import useQuery from 'utils/hooks/useQuery';

export const useListAppXTenants = () => {
  const query = useQuery();
  const {
    data: appXTenants,
    isLoading,
    error
  } = useListAppXTenantsQuery(query);
  return {
    data: appXTenants?.data,
    total: appXTenants?.total,
    isLoading,
    error
  };
};
