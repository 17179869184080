import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { HiddenPassword } from 'components/formik';
import { setModal } from 'modal/redux/modalSlice';

import ChangeSecretKeyModal from './appSecretKey/ChangeSecretKeyModal';

const AppSecretKey = () => {
  const dispatch = useDispatch();

  const handleChangeSecretKey = () => {
    dispatch(setModal(<ChangeSecretKeyModal />));
  };
  return (
    <StyledHiddenPassword>
      <HiddenPassword label="App Secret Key" onEdit={handleChangeSecretKey} />
    </StyledHiddenPassword>
  );
};

const StyledHiddenPassword = styled.div`
  margin: 30px 0 60px 0;
`;

export default AppSecretKey;
