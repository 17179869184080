import styled from 'styled-components';

import { StyledApiLoading } from 'components/layout/api/styles';

const ModalApiStyles = styled.div`
  position: relative;
`;

const ModalApiLoadingStyles = styled(StyledApiLoading)`
  top: 0;
`;

const ModalApiErrorStyles = styled.div`
  margin: 0;
  color: white;
  padding: 20px;

  ${({ theme }) => `
    background: ${theme.c7__ui.errorColor};
  `}
`;

const ModalChildrenStyles = styled.div`
  label,
  [data-c7-form-item-wrapper='true'] {
    margin-bottom: 20px;
  }
  [data-c7-form-item-wrapper='true'] label {
    margin-bottom: 5px;
  }
`;

export {
  ModalApiStyles,
  ModalApiLoadingStyles,
  ModalApiErrorStyles,
  ModalChildrenStyles
};
