import { useFormikContext } from 'formik';
import styled from 'styled-components';

import Icon from 'components/icon';

const FieldArrayRow = (props) => {
  const { children, onDelete, name } = props;

  const formikContext = useFormikContext();
  const { values } = formikContext;

  const length = values[name]?.length;

  return (
    <StyledFieldArrayRow>
      <StyledFieldWrapper align="start">{children}</StyledFieldWrapper>
      {length > 1 && onDelete && (
        <StyledIcon icon="trash" onClick={onDelete} label="Delete Row" />
      )}
    </StyledFieldArrayRow>
  );
};

const StyledFieldArrayRow = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.c7__ui.borderColor};
  margin-bottom: 30px;

  @media ${({ theme }) => theme.c7__ui.breakpoints.mediumUp} {
    display: flex;
    align-items: center;
    column-gap: 15px;
    align-items: start;
    margin-bottom: 0px;
    border-bottom: 0px;
  }
`;

const StyledFieldWrapper = styled.div`
  display: block;
  flex: auto;

  > * {
    width: 100%;
  }

  @media ${({ theme }) => theme.c7__ui.breakpoints.mediumUp} {
    display: flex;
    align-items: start;
    column-gap: 15px;
    > * {
      width: auto;
      flex: 1;
    }
  }
`;

const StyledIcon = styled(Icon)`
  margin-bottom: 30px;
  @media ${({ theme }) => theme.c7__ui.breakpoints.mediumUp} {
    margin-top: 35px;
    margin-bottom: 0px;
  }
`;

export default FieldArrayRow;
