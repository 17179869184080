export const filters = (versionNumbers) => {
  const sortedVersionNumbers = versionNumbers.sort((a, b) => a - b);
  const output = {
    versionNumber: {
      type: 'radio',
      key: {
        label: 'Version',
        key: 'versionNumber'
      },
      value: {
        options: sortedVersionNumbers.map((versionNumber) => ({
          label: `Version ${versionNumber}`,
          value: versionNumber
        }))
      }
    }
  };
  return output;
};
