import Tag from 'components/tag';

import { APP_STATUS_TRANSLATED, APP_STATUS } from '../../utils/enums';

const Status = (props) => {
  const { app } = props;
  const { status } = app;

  let variant = 'default';
  if (status === APP_STATUS.LIVE) {
    variant = 'success';
  }

  return (
    <div>
      <Tag variant={variant}>{APP_STATUS_TRANSLATED[status]}</Tag>
    </div>
  );
};

export default Status;
