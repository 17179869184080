import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Switch from 'components/switch';
import { toggleColorMode } from 'layout/redux/layoutSlice';

const DarkModeToggle = () => {
  const dispatch = useDispatch();
  const colorMode = useSelector((state) => state.layout.style.colorMode);

  const handleColorModeChange = (e) => {
    const { checked } = e.target;
    const newColorMode = checked ? 'dark' : 'light';
    dispatch(toggleColorMode(newColorMode));
  };

  return (
    <DarkModeToggleWrapperStyles>
      <SwitchStyles
        checked={colorMode === 'dark'}
        onChange={handleColorModeChange}
        label="Dark Mode"
      />
    </DarkModeToggleWrapperStyles>
  );
};

const DarkModeToggleWrapperStyles = styled.li`
  border-top: 1px solid ${({ theme }) => theme.c7__ui.borderColor};
  transition: all .2s ease-in-out;
  display: block;
  margin: 5px -6px 0 -6px;
  padding: 11px 2px 2.5px 4px;
}`;

const SwitchStyles = styled(Switch)`
  margin-bottom: 0px;
`;

export default DarkModeToggle;
