import { useDispatch } from 'react-redux';

import { useCreateVersionWebHookMutation } from 'api';
import { Formik, ModalFormFooter } from 'components/formik';
import { Modal, ModalBody } from 'components/modal';
import { setModal } from 'modal/redux/modalSlice';
import { useGetVersion } from 'version/utils/hooks';

import FormGeneral from './components/common/FormGeneral';
import { OBJECT, ACTION } from './utils/enums';
import { validateCreate } from './utils/validators';

const AddModal = () => {
  const dispatch = useDispatch();
  const { version } = useGetVersion();
  const [createVersionWebHook] = useCreateVersionWebHookMutation();

  const handleSubmit = async (values, formik) => {
    const data = transformData(values);
    const { error } = await createVersionWebHook({
      data,
      versionId: version.id
    });
    formik.setSubmitting(false);
    if (!error) {
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(setModal(null));
  };

  return (
    <Modal title="Add Webhook" visible onClose={handleClose}>
      <Formik
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={getInitialValues()}
      >
        <ModalBody>
          <FormGeneral />
        </ModalBody>
        <ModalFormFooter saveButtonText="Add Webhook" />
      </Formik>
    </Modal>
  );
};

const transformData = (values) => {
  const data = {
    object: values.object,
    action: values.action,
    url: values.url,
    username: values.username,
    password: values.password
  };
  return data;
};

const validate = (values) => {
  const data = transformData(values);
  const errors = validateCreate(data);
  return errors;
};

const getInitialValues = () => {
  const initialValues = {
    object: OBJECT.CUSTOMER,
    action: ACTION.CREATE,
    url: '',
    username: '',
    password: ''
  };
  return initialValues;
};

export default AddModal;
