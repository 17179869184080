import styled from 'styled-components';

import Heading from 'components/heading';

const AppHeading = () => <StyledHeading>App Development Center</StyledHeading>;

const StyledHeading = styled(Heading)`
  color: white;
  margin: 0;
  font-size: 17px;
  font-weight: 600;
`;
export default AppHeading;
