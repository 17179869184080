import Cookies from 'js-cookie';

// @common
export const cookieDomain = () => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return `platform.commerce7.com`;
    default:
      return 'platform.dev7.io';
  }
};

export const setTokenCookie = (token) => {
  Cookies.set(`token`, token, { domain: `.${cookieDomain()}` });
};

export const removeTokenCookie = () => {
  Cookies.remove('token', { domain: `.${cookieDomain()}` });
};
