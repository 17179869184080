import { useListExchangeRatesQuery } from 'api';

export const useListExchangeRates = (currency) => {
  const query = {
    currency
  };
  const { data, isLoading, error } = useListExchangeRatesQuery(query);
  return {
    data,
    error,
    isLoading
  };
};
