export const titleCase = (str) =>
  str
    .trim()
    .toLowerCase()
    .split(' ')
    .filter((word) => word && word !== '')
    .map((word) => word.replace(word[0], word[0].toUpperCase()))
    .join(' ');

// @common
export const constantToTitleCase = (constant) => {
  const newString = constant.replace(/_/g, ' ');
  return titleCase(newString);
};
