import { useDispatch } from 'react-redux';

import { ContextMenuItem } from 'components/contextMenu';
import { setModal } from 'modal/redux/modalSlice';

import { APP_STATUS } from '../../../utils/enums';
import { useGetApp } from '../../../utils/hooks';

import DeleteAppModal from './DeleteAppModal';

const Delete = () => {
  const dispatch = useDispatch();

  const { app } = useGetApp();
  const { status } = app;

  if (status === APP_STATUS.LIVE) {
    return null;
  }

  const handleShowDeleteModal = () => {
    dispatch(setModal(<DeleteAppModal />));
  };

  return (
    <ContextMenuItem icon="trash" onClick={handleShowDeleteModal}>
      Delete
    </ContextMenuItem>
  );
};

export default Delete;
