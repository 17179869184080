import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';

import { Button } from 'components/button';
import { Field, Input, useFormikContext } from 'components/formik';

const SecretKey = (props) => {
  const { label = 'New App Secret Key (64 characters)', description } = props;
  const { values } = useFormikContext();
  const [isCopied, setCopied] = useState(false);
  const { appSecret } = values;

  const handleCopy = () => {
    setCopied(true);
  };

  useEffect(() => {
    setCopied(false);
  }, [appSecret]);

  return (
    <StyledSecretKey>
      <StyledField
        name="appSecret"
        label={label}
        component={Input}
        description={description}
      />
      <CopyToClipboard text={appSecret} onCopy={handleCopy}>
        <StyledButton
          startIcon={isCopied ? 'check' : 'copy'}
          variant="secondary"
          disabled={!appSecret || appSecret.length < 64}
        >
          {' '}
        </StyledButton>
      </CopyToClipboard>
    </StyledSecretKey>
  );
};

const StyledSecretKey = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  margin-top: 21px;

  span {
    svg {
      margin: 0;
    }
  }
`;

const StyledField = styled(Field)`
  width: 100%;
`;

export default SecretKey;
