import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { Button } from 'components/button';

const SaveButton = (props) => {
  const formik = useFormikContext();

  const { label } = props;

  return (
    <ButtonStyles type="submit" loading={formik.isSubmitting}>
      {label}
    </ButtonStyles>
  );
};

const ButtonStyles = styled(Button)`
  padding: 0px 18px;
`;
export default SaveButton;
