export const currencyFormat = (number, currency) => {
  if (Number.isNaN(number)) {
    throw new Error('Invalid Number');
  }
  if (
    currency !== 'CAD' &&
    currency !== 'USD' &&
    currency !== 'AUD' &&
    currency !== 'NZD' &&
    currency !== 'ZAR' &&
    currency !== 'EUR' &&
    currency !== 'GBP'
  ) {
    throw new Error('Invalid Currency');
  }
  const results = numberWithCommas(decimalFormat(number));
  if (currency === 'EUR') {
    if (number >= 0) {
      return `€${results}`;
    }
    return `€-${results.replace(/-/g, '')}`;
  }
  if (currency === 'GBP') {
    if (number >= 0) {
      return `£${results}`;
    }
    return `-£${results.replace(/-/g, '')}`;
  }
  if (currency === 'ZAR') {
    if (number >= 0) {
      return `R${results}`;
    }
    return `R(${results.replace(/-/g, '')})`;
  }
  if (number >= 0) {
    return `$${results}`;
  }
  return `$(${results.replace(/-/g, '')})`;
};

// @common
export const percentageFormat = (number) => {
  if (Number.isNaN(number)) {
    throw new Error('Invalid Number');
  }
  if (number >= 0) {
    return `${number}%`;
  }
  return `(${number.replace(/-/g, '')}%)`;
};

// @common
export const decimalFormat = (number, noScale) => {
  if (Number.isNaN(number)) {
    throw new Error('Invalid Number');
  }
  if (noScale) {
    const results = parseFloat(number);
    return results.toFixed(2);
  }
  const results = parseFloat(number / 100);
  return results.toFixed(2);
};

// @common
export const convertToDecimalNumber = (number) => {
  if (Number.isNaN(number)) {
    throw new Error('Invalid Number');
  }
  const results = parseFloat(number / 100);
  return parseFloat(results.toFixed(2));
};

// @common
export const integerFormat = (number) => {
  if (Number.isNaN(number)) {
    throw new Error('Invalid Number');
  }
  const results = Math.round(number);
  if (results >= 0) {
    return results;
  }
  return `(${results.toString().replace(/-/g, '')})`;
};

// @common
export const signedIntegerFormat = (number) => {
  if (Number.isNaN(number)) {
    throw new Error('Invalid Number');
  }
  const results = Math.round(number);
  if (results > 0) {
    return `+${results}`;
  }
  return results;
};

// @common
export const convertCurrencyStringToCents = (string, returnError = false) => {
  const number = parseFloat(string);
  if (Number.isNaN(number) && returnError) {
    throw new Error('Invalid Number');
  }
  if (Number.isNaN(number)) {
    return string;
  }
  // Cant use parseInt
  const results = Math.round(number * 100);
  return results;
};

// @common
export const convertStringToInteger = (string) => {
  if (string === '-') {
    return 0;
  }
  const number = parseFloat(string);
  if (Number.isNaN(number)) {
    throw new Error('Invalid Number');
  }
  const results = Math.round(number);
  return results;
};

// @common
export const convertStringToFloat = (string, returnError = false) => {
  const number = parseFloat(string);
  if (Number.isNaN(number) && returnError) {
    throw new Error('Invalid Number');
  }
  if (Number.isNaN(number)) {
    return string;
  }
  return number;
};

// @common
export const numberWithCommas = (x) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

// @common
export const isInteger = (n) =>
  typeof n === 'number' && n === +n && Math.round(n) === n;
