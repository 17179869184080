import moment from 'moment-timezone';

import { settings, pad } from './miscHelper';

// @common
export const timeZones = () => {
  const tz = [
    'Africa/Johannesburg',
    'America/New_York',
    'America/Chicago',
    'America/Denver',
    'America/Los_Angeles',
    'Australia/Adelaide',
    'Australia/Brisbane',
    'Australia/Canberra',
    'Australia/Darwin',
    'Australia/Hobart',
    'Australia/Melbourne',
    'Australia/Perth',
    'Australia/Sydney',
    'Canada/Atlantic',
    'Canada/Eastern',
    'Canada/Central',
    'Canada/Mountain',
    'Canada/Pacific',
    'Europe/Berlin',
    'Europe/Brussels',
    'Europe/London',
    'Europe/Madrid',
    'Europe/Nicosia',
    'Europe/Paris',
    'Europe/Zurich',
    'Pacific/Auckland'
  ];
  return tz;
};

export const isDayMonthYearADate = (day, month, year) => {
  // Used to validate b-days
  if (!day || !month || !year) {
    return false;
  }
  const dateString = `${pad('0000', year)}-${pad('00', month)}-${pad(
    '00',
    day
  )}`;
  return moment(dateString, 'YYYY-MM-DD', true).isValid();
};

export const convertDayMonthYearToDate = (day, month, year) => {
  // Used to convert b-days
  const dateString = `${pad('0000', year)}-${pad('00', month)}-${pad(
    '00',
    day
  )}`;
  return dateString;
};

export const convertISODateTimeMStringToISODate = (date, time) => {
  if (!date) {
    throw new Error('Invalid Date');
  }
  const dateTime = `${date} ${time}`;
  if (moment(dateTime, 'YYYY-MM-DD HH:mm:ss.SSS', true).isValid())
    return moment
      .tz(dateTime, 'YYYY-MM-DD HH:mm:ss.SSS', settings().timeZone)
      .toISOString();

  throw new Error('Invalid Date');
};

export const convertISODateTimeStringToISODate = (date, time) => {
  if (!date) {
    throw new Error('Invalid Date');
  }
  const dateTime = `${date} ${time}`;
  if (moment(dateTime, 'YYYY-MM-DD HH:mm:ss', true).isValid())
    return moment
      .tz(dateTime, 'YYYY-MM-DD HH:mm:ss', settings().timeZone)
      .toISOString();

  throw new Error('Invalid Date');
};

export const convertTimeStringToISOTime = (time) => {
  if (!time) {
    throw new Error('Invalid Date');
  }
  if (moment(time, 'h:mm a', true).isValid())
    return moment.tz(time, 'h:mm a', settings().timeZone).format('HH:mm:ss');

  throw new Error('Invalid Date');
};

export const convertDateTimeStringToISODate = (date, time) => {
  if (!date) {
    throw new Error('Invalid Date');
  }
  const dateTime = `${date} ${time}`;
  if (moment(dateTime, 'MMM D, YYYY h:mm a', true).isValid())
    return moment
      .tz(dateTime, 'MMM D, YYYY h:mm a', settings().timeZone)
      .toISOString();

  throw new Error('Invalid Date');
};

export const convertDateOnlyStringToISODate = (date) => {
  if (moment(date, 'MMM D, YYYY', true).isValid()) {
    return moment(date, 'MMM D, YYYY', true).format('YYYY-MM-DD');
  }
  return null;
};

export const isValidDate = (dateString) =>
  moment(dateString, 'MMM D, YYYY', true).isValid();

export const isValidTime = (timeString) =>
  moment(timeString, 'h:mm a', true).isValid();

export const dateFormat = (date, format) => {
  if (!date || !moment(date).isValid()) {
    throw new Error('Invalid Date');
  }
  switch (format) {
    case 'isoDate':
      return moment(date).tz(settings().timeZone).format('YYYY-MM-DD');
    case 'short':
      return moment(date).tz(settings().timeZone).format('MMM D');
    default:
      return moment(date).tz(settings().timeZone).format('MMM D, YYYY');
  }
};

export const dayFormat = (date) => {
  if (!date || !moment(date).isValid()) {
    throw new Error('Invalid Date');
  }
  return moment(date).format('dddd');
};

export const dateFormatDateOnly = (date, format) => {
  if (!date || !moment(date).isValid()) {
    throw new Error('Invalid Date');
  }
  switch (format) {
    case 'isoDate':
      return moment(date).format('YYYY-MM-DD');
    default:
      return moment(date).format('MMM D, YYYY');
  }
};

export const today = () => new Date();

export const todayAsMoment = () => moment();

export const firstDayOfMonth = () => {
  const thisDay = today();
  return new Date(thisDay.getFullYear(), thisDay.getMonth(), 1);
};

export const firstDayOfYear = () => {
  const thisDay = today();
  return new Date(thisDay.getFullYear(), 0, 1);
};

export const timeFormat = (date, format) => {
  if (!date || !moment(date).isValid()) {
    throw new Error('Invalid Date');
  }
  switch (format) {
    case 'isoDate':
      return moment(date).tz(settings().timeZone).format('HH:mm:ss');
    default:
      return moment(date).tz(settings().timeZone).format('h:mm a');
  }
};

export const timeOnlyFormat = (time) => {
  if (!time) {
    throw new Error('Invalid Date');
  }
  if (moment(time, 'HH:mm:ss', true).isValid())
    return moment(time, 'HH:mm:ss').format('h:mm a');

  throw new Error('Invalid Date');
};

export const dateTimeFormat = (date, format) =>
  `${dateFormat(date, format)} ${timeFormat(date, format)}`;

export const timeZone = () => settings().timeZone;

export const relativeTime = (date) => moment(date).fromNow();

export const dayCount = (date) => {
  const now = moment();
  const future = moment(date);
  return Math.round(moment.duration(future.diff(now)).asDays());
};

export const dayOfWeekAsString = (day) =>
  [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ][day];

export const dayOfWeekAsInteger = (date) => {
  const numericDayOfWeek = moment(date).tz(settings().timeZone).day();
  return numericDayOfWeek;
};

export const oneMonthAgoToISODate = () =>
  moment().subtract(1, 'months').startOf('day').toISOString();

export const createDate = (date) => {
  if (!date || !moment(date).isValid()) {
    throw new Error('Invalid Date');
  }
  return moment(date, 'YYYY-MM-DD HH:mm:ss').toDate();
};

export const endOfDayToISODate = (date) => {
  if (!date) {
    throw new Error('Invalid Date');
  }
  const time = '11:59:59.999 pm';
  const dateTime = `${date} ${time}`;

  if (moment(dateTime, 'MMM D, YYYY h:mm:ss.SSS a', true).isValid())
    return moment
      .tz(dateTime, 'MMM D, YYYY h:mm:ss.SSS a', settings().timeZone)
      .toISOString();

  throw new Error('Invalid Date');
};

export const startOfDayToISODate = (date) => {
  if (!date) {
    throw new Error('Invalid Date');
  }
  const time = '12:00:00.000 am';
  const dateTime = `${date} ${time}`;

  if (moment(dateTime, 'MMM D, YYYY h:mm:ss.SSS a', true).isValid())
    return moment
      .tz(dateTime, 'MMM D, YYYY h:mm:ss.SSS a', settings().timeZone)
      .toISOString();

  throw new Error('Invalid Date');
};

export const fromNowInMinutes = (date) => {
  if (!date || !moment(date).isValid()) {
    throw new Error('Invalid Date');
  }
  return moment(date).diff(moment(), 'minutes');
};

export const startOfWeekFromDate = (date) => {
  if (!date || !moment(date).isValid()) {
    throw new Error('Invalid Date');
  }

  return moment(date).startOf('week');
};

export const dayOfMonth = (date) => {
  if (!date || !moment(date).isValid()) {
    throw new Error('Invalid Date');
  }
  return moment(date).format('D');
};

export const dayOfWeek = (date) => {
  if (!date || !moment(date).isValid()) {
    throw new Error('Invalid Date');
  }
  return moment(date).format('ddd');
};

export const hoursDifference = (startDate, endDate) => {
  if (!startDate) {
    throw new Error('Invalid Start Date');
  }
  if (!endDate) {
    throw new Error('Invalid End Date');
  }
  const start = moment.tz(startDate, settings.timeZone);
  const end = moment.tz(endDate, settings.timeZone);
  return start.diff(end, 'hours');
};

export const minutesDifference = (startDate, endDate) => {
  if (!startDate) {
    throw new Error('Invalid Start Date');
  }
  if (!endDate) {
    throw new Error('Invalid End Date');
  }
  const start = moment.tz(startDate, settings.timeZone);
  const end = moment.tz(endDate, settings.timeZone);
  return start.diff(end, 'minutes');
};
