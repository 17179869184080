import { LinkToDocsStyles, LinkToDocsIconStyles } from './styles';

const LinkToDocs = (props) => {
  const { url, label = 'View Docs' } = props;

  if (!url) {
    return null;
  }

  return (
    <LinkToDocsStyles href={url} target="_blank" rel="noreferrer">
      {label}
      <LinkToDocsIconStyles icon="newTab" />
    </LinkToDocsStyles>
  );
};

export default LinkToDocs;
