import { useRef } from 'react';
import styled from 'styled-components';

import { ContextMenu, ContextMenuItem } from 'components/contextMenu';
import FileUploader from 'components/fileUploader';
import { useFormikContext } from 'components/formik';
import Icon from 'components/icon';
import Picture from 'components/picture';
import Text from 'components/text';
import { appFromURL } from 'utils/helpers/apiHelper';
import { IMAGE_SIZES } from 'utils/helpers/imageHelper';

import DeleteButton from '../common/DeleteButton';

const LogoUploader = (props) => {
  const { dark, name } = props;
  const fileUploaderRef = useRef();
  const { setFieldValue, values } = useFormikContext();
  const { logo, darkModeLogo } = values;

  let label = 'Logo';
  if (dark) {
    label = 'Logo for Dark Mode';
  }

  const logoPath = dark ? darkModeLogo : logo;

  const onUploadComplete = (data) => {
    const { directory, fileName } = data.find(
      (file) => file.key === IMAGE_SIZES.ORIGINAL
    );
    const iconPath = `${directory}${fileName}`;
    setFieldValue(name, iconPath);
  };

  const handleUpload = () => {
    fileUploaderRef.current.handleUploadClick();
  };

  return (
    <FileUploader
      fileSchema={fileSchema}
      onUploadComplete={onUploadComplete}
      ref={fileUploaderRef}
    >
      <StyledLabel small>{label}</StyledLabel>
      <StyledLogoUploader dark={dark} hasImage={!!logoPath}>
        {logoPath && (
          <>
            <Picture
              src={logoPath}
              alt={dark ? 'dark-mode logo' : 'logo'}
              size={IMAGE_SIZES.THUMB}
            />
            <DeleteButton name={name} />
          </>
        )}
        {!logoPath && (
          <>
            <StyledIcon size={60} icon="imagePlaceholder" dark={dark} />
            <StyledContextMenu>
              <StyledContextMenuItem
                icon="upload"
                dark={dark}
                onClick={handleUpload}
              >
                Upload
              </StyledContextMenuItem>
            </StyledContextMenu>
            <Text small secondary>
              Ideally 800px x 400px
            </Text>
          </>
        )}
      </StyledLogoUploader>
    </FileUploader>
  );
};

const fileSchema = {
  fileType: 'image',
  allowableMimeTypes: [
    'image/jpeg',
    'image/pjpeg',
    'image/png',
    'image/svg+xml',
    'image/avif',
    'image/webp'
  ],
  outputs: [
    {
      key: IMAGE_SIZES.ORIGINAL,
      destination: `${appFromURL()}/images/logo/${IMAGE_SIZES.ORIGINAL}/`
    },
    {
      key: IMAGE_SIZES.FULL,
      destination: `${appFromURL()}/images/logo/${IMAGE_SIZES.FULL}/`,
      height: 900,
      width: 1600,
      resize: 'ratio',
      convertTo: 'avif'
    },
    {
      key: IMAGE_SIZES.FULL,
      destination: `${appFromURL()}/images/logo/${IMAGE_SIZES.FULL}/`,
      height: 900,
      width: 1600,
      resize: 'ratio',
      convertTo: 'webp'
    },
    {
      key: IMAGE_SIZES.FULL,
      destination: `${appFromURL()}/images/logo/${IMAGE_SIZES.FULL}/`,
      height: 900,
      width: 1600,
      resize: 'ratio',
      convertTo: 'png'
    },
    {
      key: IMAGE_SIZES.THUMB,
      destination: `${appFromURL()}/images/logo/${IMAGE_SIZES.THUMB}/`,
      height: 200,
      width: 287,
      resize: 'ratio',
      convertTo: 'avif'
    },
    {
      key: IMAGE_SIZES.THUMB,
      destination: `${appFromURL()}/images/logo/${IMAGE_SIZES.THUMB}/`,
      height: 200,
      width: 287,
      resize: 'ratio',
      convertTo: 'webp'
    },
    {
      key: IMAGE_SIZES.THUMB,
      destination: `${appFromURL()}/images/logo/${IMAGE_SIZES.THUMB}/`,
      height: 200,
      width: 287,
      resize: 'ratio',
      convertTo: 'png'
    }
  ]
};

const StyledLabel = styled(Text)`
  line-height: 1.15;
  margin-bottom: 5px;
`;

const StyledIcon = styled(Icon)`
  path {
    fill: ${({ theme, dark }) =>
      dark ? theme.c7__ui.colors.gray800 : theme.c7__ui.colors.gray400};
  }
`;

const StyledContextMenu = styled(ContextMenu)`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const StyledContextMenuItem = styled(ContextMenuItem)`
  margin: 0;
  color: ${({ theme, dark }) =>
    dark ? `${theme.c7__ui.colors.white}` : `${theme.c7__ui.colors.slate300}`};

  svg {
    path {
      fill: ${({ theme, dark }) =>
        dark
          ? `${theme.c7__ui.colors.white}`
          : `${theme.c7__ui.colors.slate300}`};
    }
  }
`;

const StyledLogoUploader = styled.div`
  border: 1px solid ${({ theme }) => theme.c7__ui.borderColor};
  border-radius: ${({ theme }) => theme.c7__ui.borderRadius};
  overflow: hidden;
  padding: ${({ hasImage }) => (hasImage ? '0px' : '25px')};
  position: relative;
  height: 175px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme, dark }) =>
    dark ? theme.c7__ui.colors.slate300 : theme.c7__ui.colors.white};

  picture {
    height: 100%;
    object-fit: scale-down;
    img {
      height: 100%;
      object-fit: scale-down;
    }
  }
`;

export default LogoUploader;
