// @common
export const normalizeDecimal = (value) => {
  if (!value) {
    return value;
  }
  const number = value.replace(/[^0-9.]/g, '');
  return number;
};

// @common
export const normalizeInteger = (value) => {
  if (!value) {
    return value;
  }
  const number = value.replace(/[^0-9]/g, '');
  return number;
};
