import axios from 'axios';

import {
  publicAxiosHeader,
  axiosHeader,
  getGlobalRootUrl
} from 'utils/helpers/apiHelper';

export const loginUser = ({ email, password, token }) =>
  axios.post(
    `${getGlobalRootUrl()}/account/login`,
    { email, password, token },
    publicAxiosHeader()
  );
export const logoutUser = () =>
  axios.post(`${getGlobalRootUrl()}/account/logout`, {}, axiosHeader());
