import { useParams } from 'react-router-dom';

import { useListVersionWebHooksQuery } from 'api';

export const useListVersionWebHooks = () => {
  const params = useParams();
  const versionId = params.id;
  const {
    data: versionWebHooks,
    isLoading,
    error
  } = useListVersionWebHooksQuery(versionId);
  return {
    data: versionWebHooks?.data,
    total: versionWebHooks?.total,
    error,
    isLoading
  };
};
