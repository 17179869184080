import { getIn } from 'formik';

import Actions from 'components/actions';
import {
  Field,
  Input,
  useFormikContext,
  FieldArrayRow,
  FieldArrayAddButton
} from 'components/formik';

const OptionFormFields = (props) => {
  const { remove, push, name } = props;
  const formikContext = useFormikContext();
  const { values } = formikContext;

  const options = getIn(values, name);

  const handleDeleteOption = (index) => {
    remove(index);
  };

  const handleAddOption = () => {
    push('');
  };

  return (
    <>
      {options.map((option, index) => (
        <FieldArrayRow
          // eslint-disable-next-line react/no-array-index-key
          key={String(index)}
          name="options"
          onDelete={() => handleDeleteOption(index)}
        >
          <Field
            autoFocus={index === 0}
            component={Input}
            label={`Option ${index + 1}`}
            name={`${name}[${index}]`}
            placeholder="Option Name"
          />
        </FieldArrayRow>
      ))}
      <Actions>
        <FieldArrayAddButton label="Add Option" onClick={handleAddOption} />
      </Actions>
    </>
  );
};

export default OptionFormFields;
