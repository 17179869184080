import styled from 'styled-components';

import { Columns, Column } from 'components/columns';
import { FormDescription } from 'components/formInstructions';
import { Field, Input, TextArea, Select } from 'components/formik';
import Heading from 'components/heading';
import Region from 'components/region';
import { useCharactersRemaining } from 'utils/hooks/useCharactersRemaining';

import LogoUploader from './general/LogoUploader';

const General = () => {
  const charactersRemaining = useCharactersRemaining('salesDescription');

  return (
    <Region borderBottom>
      <Heading>General</Heading>
      <StyledFormDescription>
        Give an intro to your app with a logo that represents what it is and
        enter a brief summary.
      </StyledFormDescription>
      <Columns>
        <Column span={6}>
          <StyledLogoUploaders>
            <LogoUploader name="logo" />
            <LogoUploader name="darkModeLogo" dark />
          </StyledLogoUploaders>
        </Column>
        <Column span={6}>
          <Field name="title" component={Input} label="App Title" />
          <Field
            name="salesDescription"
            component={TextArea}
            label="Summary"
            height={100}
            description={charactersRemaining}
          />
          <Field
            name="category"
            component={Select}
            label="Type"
            options={getCategories()}
          />
          <Field
            name="websiteUrl"
            component={Input}
            label="Website URL"
            placeholder="https://"
            description="Your website or a marketing page for the app"
          />
        </Column>
      </Columns>
    </Region>
  );
};

const StyledLogoUploaders = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
`;

const getCategories = () => {
  const options = [
    { label: 'Other', value: 'Other' },
    { label: 'Reporting', value: 'Reporting' },
    { label: 'Website', value: 'Website' },
    { label: 'Shipping', value: 'Shipping' },
    { label: 'Marketing', value: 'Marketing' }
  ];
  return options;
};

const StyledFormDescription = styled(FormDescription)`
  margin-bottom: 20px;
`;

export default General;
