import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useListVersionExtensionsQuery } from 'api';
import { useFormikContext } from 'components/formik';

import { HOOK_TYPE } from './enums';

export const useListVersionExtensions = () => {
  const params = useParams();
  const versionId = params.id;
  const {
    data: versionExtensions,
    isLoading,
    error
  } = useListVersionExtensionsQuery(versionId);
  return {
    data: versionExtensions?.data,
    total: versionExtensions?.total,
    error,
    isLoading
  };
};

export const useClearUnusedFields = () => {
  const { values, setFieldValue } = useFormikContext();
  const { hookType } = values;

  useEffect(() => {
    getFieldsToClear(hookType).forEach((field) => {
      setFieldValue(`hookData.${field}`, '');
    });
  }, [hookType, setFieldValue]);
};

const getFieldsToClear = (hookType) => {
  switch (hookType) {
    case HOOK_TYPE.CONTEXT_MENU:
      return ['iFrameHeight'];

    case HOOK_TYPE.TAB_MENU:
      return ['icon', 'contextMenuType'];

    case HOOK_TYPE.PRINT_RECEIPT:
      return ['icon', 'contextMenuType', 'iFrameHeight'];

    case HOOK_TYPE.REPORT:
    case HOOK_TYPE.PAGE:
      return ['contextMenuType'];

    case HOOK_TYPE.CARD:
      return ['icon', 'contextMenuType', 'iFrameHeight', 'title'];

    default:
      throw new Error('Unexpected hookType');
  }
};
