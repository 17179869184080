import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Table, Tbody, Thead, Th, Tr, Td } from 'components/table';

import Empty from './Empty';
import Loading from './Loading';
import Cell from './components/Cell';
import { onGridRowClick } from './utils/helpers';

const Grid = (props) => {
  const {
    rowHeaders,
    rowBody,
    listHook,
    onRowClick,
    gridParams,
    customNoRecords
  } = props;
  const navigate = useNavigate();

  const { data, isLoading, error } = listHook();

  const handleRowClick = (e, object) =>
    onGridRowClick(e, navigate, onRowClick, gridParams?.editUrl, object);

  if (isLoading) {
    return <Loading rowHeaders={rowHeaders} />;
  }

  if (error || data?.length === 0) {
    if (customNoRecords) {
      return customNoRecords;
    }
    return <Empty rowHeaders={rowHeaders} />;
  }

  const gridRows = data;

  return (
    <Table>
      <Thead>
        <Tr>
          {rowHeaders.map((header) => (
            <Th key={header.label} align={header.align}>
              {header.label}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {gridRows.map((object) => {
          let trProps = {};
          if (onRowClick || gridParams?.editUrl) {
            trProps = { onClick: (e) => handleRowClick(e, object) };
          }

          return (
            <Fragment key={object.id}>
              <Tr dataTestId="grid-row" {...trProps} /* eslint-disable-line  */>
                {rowBody.map((element) => (
                  <GridCellStyles
                    key={element.data}
                    align={element.align}
                    maxWidth={element.maxWidth}
                  >
                    <Cell element={element} object={object} />
                  </GridCellStyles>
                ))}
              </Tr>
            </Fragment>
          );
        })}
      </Tbody>
    </Table>
  );
};

const GridCellStyles = styled(Td)`
  ${({ maxWidth }) => {
    if (!maxWidth) return '';

    return `
      max-width: ${maxWidth};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `;
  }}

  vertical-align: ${({ vAlign = 'middle' }) => vAlign};
`;

export default Grid;
