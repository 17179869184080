export const countries = {
  AU: {
    flag: 'AU.svg',
    label: 'Australian Dollar (AUD)',
    name: 'monthlyFeeAUD',
    currencyIcon: 'dollar',
    currency: 'AUD'
  },
  CA: {
    flag: 'CA.svg',
    label: 'Canadian Dollar (CAD)',
    name: 'monthlyFeeCAD',
    currencyIcon: 'dollar',
    currency: 'CAD'
  },
  EUR: {
    flag: 'EUR.svg',
    label: 'Euro (EUR)',
    name: 'monthlyFeeEUR',
    currencyIcon: 'euro',
    currency: 'EUR'
  },
  GB: {
    flag: 'GB.svg',
    label: 'Pound Sterling (GBP)',
    name: 'monthlyFeeGBP',
    currencyIcon: 'pound',
    currency: 'GBP'
  },
  NZ: {
    flag: 'NZ.svg',
    label: 'New Zealand Dollar (NZD)',
    name: 'monthlyFeeNZD',
    currencyIcon: 'dollar',
    currency: 'NZD'
  },
  USA: {
    flag: 'USA.svg',
    label: 'United States Dollar (USD)',
    name: 'monthlyFee',
    currencyIcon: 'dollar',
    currency: 'USD'
  },
  ZA: {
    flag: 'ZA.svg',
    label: 'South African Rand (ZAR)',
    name: 'monthlyFeeZAR',
    currencyIcon: 'rand',
    currency: 'ZAR'
  }
};
