import { c7Colors } from '@commerce7/admin-ui';
import styled, { keyframes } from 'styled-components';

const loading = keyframes`
from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 40%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
`;

const ApiErrorStyles = styled.div`
  margin-bottom: 15px;
`;

const StyledApiLoading = styled.div`
  height: 6px;
  width: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 1;
  top: 60px;
  left: 0;

  &:before {
    display: block;
    position: absolute;
    content: '';
    left: -200px;
    width: 200px;
    height: 6px;
    animation: ${loading} 2s linear infinite;
  }

  ${({ isVisible }) => `
    opacity: ${isVisible ? '1' : '0'};
    visibility: ${isVisible ? 'visible' : 'hidden'};
  `}

  ${({ theme }) => `
    background-color: ${apiLoading[theme.c7__ui.mode].backgroundColor.default};
    &:before {
        background-color: ${
          apiLoading[theme.c7__ui.mode].backgroundColor.active
        };
    }
  `}
`;

const apiLoading = {
  light: {
    backgroundColor: {
      default: c7Colors.gray300,
      active: c7Colors.blue400
    }
  },
  dark: {
    backgroundColor: {
      default: c7Colors.gray700,
      active: c7Colors.blue400
    }
  }
};

export { StyledApiLoading, ApiErrorStyles };
