import { useListAppsQuery, useGetAppQuery, useGetAppStatsQuery } from 'api';
import { appFromURL } from 'utils/helpers/apiHelper';
import useQuery from 'utils/hooks/useQuery';

export const useListApps = () => {
  const query = useQuery();
  const { data: apps, isLoading } = useListAppsQuery(query);
  const hasApps = apps?.total > 0;
  const total = apps?.total;
  return { data: apps?.data, hasApps, isLoading, total };
};

export const useListAllApps = () => {
  const { data: apps, isLoading } = useListAppsQuery({});
  const hasApps = apps?.total > 0;
  const total = apps?.total;
  return { data: apps?.data, hasApps, isLoading, total };
};

export const useGetApp = (force = false) => {
  const appId = appFromURL();
  const {
    data: app,
    isLoading,
    isFetching
  } = useGetAppQuery(appId, {
    refetchOnMountOrArgChange: force
  });
  return { app, isLoading, isFetching };
};

export const useGetAppStats = () => {
  const appId = appFromURL();
  const { data: app, isLoading } = useGetAppStatsQuery(appId);
  return { data: app, isLoading };
};
