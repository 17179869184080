import { Textarea } from '@commerce7/admin-ui';
import get from 'lodash.get';

const FormikTextArea = (props) => {
  const {
    label,
    placeholder,
    field,
    form,
    autoFocus,
    height,
    onChange,
    onFocus,
    description,
    className
  } = props;
  const { onBlur, onChange: onFieldChange, value, name } = field;
  const { errors, touched } = form;

  const handleOnChange = (e) => {
    if (onChange) {
      onChange(e);
    }
    return onFieldChange(e);
  };

  // Convert nested error keys delimited by slashes "/" to "." instead so
  // lodash get can resolve them
  const convertedErrors = {};
  Object.keys(errors).forEach((key) => {
    const newKey = key.split('/').join('.');
    convertedErrors[newKey] = errors[key];
  });

  let errorMessage;
  if (get(convertedErrors, name) && get(touched, name)) {
    errorMessage = get(convertedErrors, name);
  }

  return (
    <Textarea
      label={label}
      className={className}
      placeholder={placeholder}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={handleOnChange}
      value={value || ''}
      id={name}
      errorMessage={errorMessage}
      autoFocus={autoFocus}
      height={height}
      description={description}
    />
  );
};

export default FormikTextArea;
