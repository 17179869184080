import { c7Colors } from '@commerce7/admin-ui';
import { TextRow } from 'react-placeholder/lib/placeholders/';
import styled from 'styled-components';

import { Table, Tbody, Thead, Tr, Td, Th } from 'components/table';

const Loading = (props) => {
  const { rowHeaders } = props;
  const rowsArray = [...Array(5)].map((_, index) => index + 1);
  const columnsArray = [...Array(rowHeaders.length)].map(
    (_, index) => index + 1
  );

  const columns = columnsArray.map((column) => (
    <Td key={column}>
      <StyledPlaceholder showLoadingAnimation color="#E0E0E0" rows={1} />
    </Td>
  ));

  return (
    <Table>
      {rowHeaders?.length && (
        <Thead>
          <Tr>
            {rowHeaders.map((header) => (
              <Th key={header.label} align={header.align}>
                {header.label}
              </Th>
            ))}
          </Tr>
        </Thead>
      )}
      <Tbody>
        {rowsArray.map((row) => (
          <Tr key={row} dataTestId="loading-grid-row">
            {columns}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

const StyledPlaceholder = styled(TextRow)`
  margin-top: 0 !important;
  height: 20px !important;
  background-color: ${({ theme }) =>
    gridColors[theme.c7__ui.mode].loadingBackgroundColor} !important;
`;

const gridColors = {
  light: {
    loadingBackgroundColor: c7Colors.gray200,
    checkedBackgroundColor: c7Colors.blue100
  },
  dark: {
    loadingBackgroundColor: c7Colors.gray800,
    checkedBackgroundColor: c7Colors.slate100
  }
};

export default Loading;
