import styled from 'styled-components';

import Text from 'components/text';

const FormItemWrapperStyles = styled.label`
  display: block;
  color: ${({ theme }) => theme.c7__ui.fontColor};
  position: relative;
  user-select: none;
  transition: color 0.3s ease-in-out;
  margin-bottom: 30px;
  font-size: ${({ theme }) => theme.c7__ui.fontSizeBase};
`;

const FormItemLabelStyles = styled.div`
  margin-bottom: 5px;
  font-size: ${({ theme }) => theme.c7__ui.fontSizeSmall};
`;

const FormItemErrorStyles = styled(Text)`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  color: ${({ theme }) => theme.c7__ui.errorColor};
  font-size: ${({ theme }) => theme.c7__ui.fontSizeSmall};
  white-space: nowrap;
  margin: 0;
`;

const FormItemRequiredStyles = styled.sup`
  color: ${({ theme }) => theme.c7__ui.errorColor};
  &:after {
    content: '*';
    display: inline-block;
  }
`;

export {
  FormItemWrapperStyles,
  FormItemLabelStyles,
  FormItemErrorStyles,
  FormItemRequiredStyles
};
