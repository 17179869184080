import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  useUpdateVersionExtensionMutation,
  useDeleteVersionExtensionMutation
} from 'api';
import { Button } from 'components/button';
import { Formik, ModalFormFooter } from 'components/formik';
import { Modal, ModalBody } from 'components/modal';
import { setModal } from 'modal/redux/modalSlice';

import FormGeneral from './components/common/FormGeneral';
import { transformData } from './utils/helpers';
import { validateUpdate } from './utils/validators';

const EditModal = (props) => {
  const { versionExtension } = props;
  const [isDeleting, setDeleting] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const [deleteVersionExtension] = useDeleteVersionExtensionMutation();
  const [updateVersionExtension] = useUpdateVersionExtensionMutation();

  const handleSubmit = async (values, formik) => {
    const data = transformData(values);
    const { error } = await updateVersionExtension({
      data,
      versionId: params.id,
      extensionId: versionExtension.id
    });
    formik.setSubmitting(false);
    if (!error) {
      handleClose();
    }
  };

  const handleDelete = async () => {
    setDeleting(true);
    const { error } = await deleteVersionExtension({
      versionId: params.id,
      extensionId: versionExtension.id
    });
    setDeleting(false);
    if (!error) {
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(setModal(null));
  };

  return (
    <Modal title="Edit Extension" visible onClose={handleClose}>
      <Formik
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={getInitialValues(versionExtension)}
      >
        <ModalBody>
          <FormGeneral />
        </ModalBody>
        <ModalFormFooter saveButtonText="Save Extension">
          <Button
            variant="secondary"
            startIcon="trash"
            onClick={handleDelete}
            loading={isDeleting}
          >
            Delete Extension
          </Button>
        </ModalFormFooter>
      </Formik>
    </Modal>
  );
};

const validate = (values) => {
  const data = transformData(values);
  const errors = validateUpdate(data);
  return errors;
};

const getInitialValues = (versionExtension) => {
  const { objectType, hookType, hookData } = versionExtension;
  const { icon, contextMenuType, url, title, iFrameHeight } = hookData;
  const initialValues = {
    objectType,
    hookType,
    hookData: {
      icon: icon || '',
      contextMenuType: contextMenuType || '',
      url: url || '',
      title: title || '',
      iFrameHeight: iFrameHeight || ''
    }
  };
  return initialValues;
};

export default EditModal;
