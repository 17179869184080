import { ButtonStyles } from './components/fieldArray/styles';

const FieldArrayAddButton = (props) => {
  const { onClick, label = 'Add Option' } = props;

  const handleClick = (e) => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <ButtonStyles variant="text" onClick={handleClick} startIcon="add">
      {label}
    </ButtonStyles>
  );
};

export default FieldArrayAddButton;
