import { PAYMENT_HANDLED_BY } from 'app/utils/enums';
import DataDisplay from 'components/dataDisplay';
import { Field, Radio, RadioGroup, useFormikContext } from 'components/formik';

import { useCanEditCurrency } from '../../../utils/hooks';

const PaymentHandledBy = () => {
  const { canEditCurrency } = useCanEditCurrency();
  const { values } = useFormikContext();
  const { paymentHandledBy } = values;

  if (canEditCurrency) {
    return (
      <RadioGroup label="Pricing Model" name="paymentHandledBy">
        <Field
          name="paymentHandledBy"
          label="Set pricing"
          component={Radio}
          value={PAYMENT_HANDLED_BY.COMMERCE7}
        />
        <Field
          name="paymentHandledBy"
          label="Contact for pricing"
          component={Radio}
          value={PAYMENT_HANDLED_BY.EXTERNAL}
        />
      </RadioGroup>
    );
  }

  return (
    <DataDisplay label="Pricing Model">
      {getDisplayValue(paymentHandledBy)}
    </DataDisplay>
  );
};

const getDisplayValue = (paymentHandledBy) => {
  switch (paymentHandledBy) {
    case PAYMENT_HANDLED_BY.COMMERCE7:
      return 'Set pricing';
    case PAYMENT_HANDLED_BY.EXTERNAL:
      return 'Contact for pricing';
    default:
      throw new Error('Unexpected paymentHandledBy');
  }
};

export default PaymentHandledBy;
