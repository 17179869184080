import { useSelector } from 'react-redux';

import { ROLE } from 'appXAccount/utils/enums';

export const useIsMegaAdmin = () => {
  const user = useSelector((state) => state.user?.data);

  if (!user) {
    return false;
  }

  const { tenants } = user;
  if (tenants[0]?.role === ROLE.MEGA_ADMIN) {
    return true;
  }
  return false;
};
