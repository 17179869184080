/* globals window */
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useGetApp } from 'app/utils/hooks';
import { useIsAdminOwner } from 'appXAccount/utils/hooks';
import { Nav, NavLink, SubNav, SubNavLink } from 'components/nav';
import { useIsMegaAdmin } from 'user/utils/hooks';
import { mainDomain } from 'utils/helpers/routingHelper';

import CloseNavButton from './pageSidebar/CloseNavButton';

const PageSidebar = () => {
  const { app, isLoading } = useGetApp();
  const location = useLocation();

  const isOpen = useSelector(
    (state) => state.layout.navigation.isLeftNavigationVisible
  );
  const isAdminOwner = useIsAdminOwner();
  const isMegaAdmin = useIsMegaAdmin();
  const showInstallsAndBilling = isAdminOwner || isMegaAdmin;

  if (isLoading) return null;

  const handleBackToDashboard = () => {
    window.location.href = `${mainDomain()}`;
  };

  const { title } = app;

  return (
    <StyledNav isOpen={isOpen}>
      <div>
        <CloseNavButton />
        <NavLink
          icon="arrowLeft"
          isActive={() => false}
          onClick={handleBackToDashboard}
        >
          Dashboard
        </NavLink>
        <NavLink
          icon="application"
          to="/"
          isActive={() => isAppSelected(location.pathname)}
        >
          {title}
        </NavLink>
        <SubNav isOpen>
          <SubNavLink
            to="/"
            className={isBaseApp(location.pathname)}
            activeClassName="customActive"
          >
            App
          </SubNavLink>
          <SubNavLink to="/account">Accounts</SubNavLink>
          {showInstallsAndBilling && (
            <>
              <SubNavLink to="/install">Installs</SubNavLink>
              <SubNavLink to="/billing">Billing</SubNavLink>
            </>
          )}
        </SubNav>
      </div>
      <NavLink icon="support" to="/support">
        Support
      </NavLink>
    </StyledNav>
  );
};

const isBaseApp = (pathname) => {
  if (pathname === '/') {
    return 'customActive';
  }
  return '';
};

const isAppSelected = (pathname) => {
  if (['support'].includes(pathname.split('/')[1])) {
    return false;
  }
  return true;
};

const StyledNav = styled(Nav)`
  height: calc(100vh - 60px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: fixed;
  top: 0px;
  height: 100vh;
  z-index: 1000;
  overflow: auto;

  left: ${({ isOpen }) => (isOpen ? '0px' : '-260px')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};

  box-shadow: ${({ theme }) => theme.c7__ui.boxShadow};

  transition: left 0.5s ease-in-out, background 0.3s ease-in-out,
    border 0.3s ease-in-out, visibility 0.5s ease-in-out;

  @media ${({ theme }) => theme.c7__ui.breakpoints.largeUp} {
    box-shadow: none;
    left: 0px;
    top: 60px;
    height: calc(100vh - 60px);
    z-index: 100;
    visibility: visible;
  }
`;

export default PageSidebar;
