import { useEffect } from 'react';

import { PAYMENT_HANDLED_BY } from 'app/utils/enums';
import { Column, Columns } from 'components/columns';
import { useFormikContext } from 'components/formik';

import CurrencyField from './pricingFields/CurrencyField';
import MonthlyFee from './pricingFields/MonthlyFee';
import PayoutCurrency from './pricingFields/PayoutCurrency';
import SetupFee from './pricingFields/SetupFee';
import CurrencyDisplay from './pricingFields/common/CurrencyDisplay';

const PricingFields = () => {
  const { values, setFieldValue } = useFormikContext();
  const { paymentHandledBy, setupFee } = values;

  useEffect(() => {
    if (paymentHandledBy === PAYMENT_HANDLED_BY.EXTERNAL) {
      setFieldValue('monthlyFee', '0.00');
      setFieldValue('setupFee', '0.00');
      setFieldValue('currency', 'USD');
    }
  }, [paymentHandledBy, setFieldValue]);

  if (paymentHandledBy === PAYMENT_HANDLED_BY.EXTERNAL) return null;

  const showSetupFee = setupFee !== '0.00';

  return (
    <>
      <Columns>
        <Column span={3}>
          <CurrencyDisplay country="USA" />
        </Column>
        <Column span={3}>
          <MonthlyFee />
        </Column>
        {showSetupFee && (
          <Column span={3}>
            <SetupFee />
          </Column>
        )}
        <Column span={showSetupFee ? 3 : 6}>
          <PayoutCurrency />
        </Column>
      </Columns>
      <Columns>
        <Column span={6}>
          <CurrencyField country="CA" />
          <CurrencyField country="AU" />
          <CurrencyField country="NZ" />
        </Column>
        <Column span={6}>
          <CurrencyField country="GB" />
          <CurrencyField country="EUR" />
          <CurrencyField country="ZA" />
        </Column>
      </Columns>
    </>
  );
};

export default PricingFields;
