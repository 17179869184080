import {
  Input,
  Select,
  Radio,
  Checkbox,
  DatePicker
} from '@commerce7/admin-ui';
import styled from 'styled-components';

import { Button } from 'components/button';
import Text from 'components/text';

const FilterHeaderButton = styled(Button).attrs(() => ({
  variant: 'text'
}))`
  height: auto;
  border: none;
  padding: 0;
  color: ${({ theme }) =>
    theme.mode === 'dark' ? 'rgb(1, 146, 208)' : 'rgb(0, 103, 157)'};
  &:hover:not(:disabled) {
    color: ${({ theme }) =>
      theme.mode === 'dark' ? 'rgb(1, 146, 208)' : 'rgb(0, 103, 157)'};
  }
`;

const FilterApplyButtonStyles = styled(Button).attrs(() => ({
  variant: 'secondary'
}))`
  margin-top: 15px;
  width: 100%;
`;

const FilterHeaderStyles = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin: 0 0 10px;
  font-size: ${({ theme }) => theme.c7__ui.fontSizeBase};
`;

const FilterItemTitleStyles = styled(Text)`
  margin-bottom: 15px;
  display: block;
  font-size: 15px;
`;

const FilterDatePickerStyles = styled(DatePicker)`
  margin-bottom: 5px;
  width: 100%;
`;

const FilterInputStyles = styled(Input)`
  margin-bottom: 5px;
  width: 100%;
`;

const toggleStyles = `
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FilterRadioStyles = styled(Radio)`
  ${toggleStyles}
`;

const FilterCheckboxStyles = styled(Checkbox)`
  ${toggleStyles}
`;

const FilterSelectStyles = styled(Select)`
  margin-bottom: 5px;
  width: 100%;
`;

export {
  FilterHeaderButton,
  FilterHeaderStyles,
  FilterApplyButtonStyles,
  FilterItemTitleStyles,
  FilterDatePickerStyles,
  FilterInputStyles,
  FilterRadioStyles,
  FilterCheckboxStyles,
  FilterSelectStyles
};
