import styled from 'styled-components';

import ApiError from './api/ApiError';
import ApiLoading from './api/ApiLoading';
import PageHeader from './components/layout/PageHeader';

const DashboardLayout = (props) => {
  const { children } = props;
  return (
    <>
      <PageHeader hideMobileMenuToggle />
      <StyledPage>
        <ApiLoading />
        <ApiError />
        {children}
      </StyledPage>
    </>
  );
};

const StyledPage = styled.main`
  margin: 0 auto;
  max-width: 1500px;
  padding: 60px 30px;
`;

export default DashboardLayout;
