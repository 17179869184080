import { addFlashMessage } from 'flashMessage/redux/flashMessageSlice';
import { removeAllApiErrors } from 'layout/redux/layoutSlice';

const getAppTempListing = (build) =>
  build.query({
    query: () => ({
      url: `/app-temp-listing/for-admin`,
      method: 'GET'
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      try {
        await queryFulfilled;
      } catch (error) {
        // We don't care if we get a 404 - clear errors
        if (error.error.data.statusCode === 404) {
          dispatch(removeAllApiErrors());
        }
      }
    },
    providesTags: () => {
      const tags = [{ type: 'AppTempListing', id: 'GET' }];
      return tags;
    }
  });

const updateAppTempListing = (build) =>
  build.mutation({
    query: ({ appTempListingId, data }) => ({
      url: `/app-temp-listing/${appTempListingId}`,
      method: 'PUT',
      data
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({
          message: 'Listing Updated.',
          type: 'success'
        })
      );
    },
    invalidatesTags: () => [{ type: 'AppTempListing', id: 'GET' }]
  });

const submitAppTempListing = (build) =>
  build.mutation({
    query: (appTempListingId) => ({
      url: `/app-temp-listing/${appTempListingId}/submit`,
      method: 'PUT'
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({
          message: 'App Listing Submitted!',
          type: 'success'
        })
      );
    },
    invalidatesTags: () => [{ type: 'AppTempListing', id: 'GET' }]
  });

const appTempListingApi = {
  getAppTempListing,
  updateAppTempListing,
  submitAppTempListing
};
export default appTempListingApi;
