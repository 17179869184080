import { useEffect, useMemo, useRef } from 'react';

import { Field, Radio, RadioGroup, useFormikContext } from 'components/formik';

import {
  HOOK_TYPE,
  HOOK_TYPE_TRANSLATED,
  OBJECT_TYPE
} from '../../../utils/enums';

const DisplayType = () => {
  const firstUpdate = useRef(true);
  const { values, setFieldValue } = useFormikContext();

  const hookTypeOptions = useMemo(
    () => getHookTypesArray(values.objectType),
    [values.objectType]
  );

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setFieldValue('hookType', hookTypeOptions[0].value);
  }, [hookTypeOptions, setFieldValue]);

  return (
    <RadioGroup name="hookType" label="Display Type">
      {hookTypeOptions.map((option) => (
        <Field
          key={option.value}
          name="hookType"
          component={Radio}
          value={option.value}
          label={option.label}
        />
      ))}
    </RadioGroup>
  );
};

const getHookTypesArray = (objectType) => {
  const hookTypesArray = [];
  const allowedHookTypes = getHookTypesForObjectType(objectType);
  allowedHookTypes.forEach((hookType) => {
    hookTypesArray.push({
      value: hookType,
      label: HOOK_TYPE_TRANSLATED[hookType]
    });
  });
  return hookTypesArray;
};

const getHookTypesForObjectType = (objectType) => {
  switch (objectType) {
    case OBJECT_TYPE.ORDER_LIST:
    case OBJECT_TYPE.PRODUCT_LIST:
    case OBJECT_TYPE.CUSTOMER_LIST:
    case OBJECT_TYPE.POS_CART_SUMMARY:
    case OBJECT_TYPE.CART_DETAIL:
    case OBJECT_TYPE.CORPORATE_ORDER_DETAIL:
      return [HOOK_TYPE.CONTEXT_MENU];

    case OBJECT_TYPE.ORDER_DETAIL:
      return [HOOK_TYPE.CONTEXT_MENU, HOOK_TYPE.TAB_MENU, HOOK_TYPE.CARD];

    case OBJECT_TYPE.PRODUCT_DETAIL:
      return [HOOK_TYPE.CONTEXT_MENU, HOOK_TYPE.TAB_MENU];

    case OBJECT_TYPE.CLUB_MEMBERSHIP_DETAIL:
    case OBJECT_TYPE.CUSTOMER_DETAIL:
      return [HOOK_TYPE.CONTEXT_MENU, HOOK_TYPE.TAB_MENU, HOOK_TYPE.CARD];

    case OBJECT_TYPE.ORDER_REPORTS:
    case OBJECT_TYPE.FINANCE_REPORTS:
    case OBJECT_TYPE.CUSTOMER_REPORTS:
    case OBJECT_TYPE.CLUB_REPORTS:
    case OBJECT_TYPE.INVENTORY_REPORTS:
      return [HOOK_TYPE.REPORT];

    case OBJECT_TYPE.MARKETING_MENU:
    case OBJECT_TYPE.STORE_MENU:
    case OBJECT_TYPE.REPORT_MENU:
    case OBJECT_TYPE.POS_MENU:
    case OBJECT_TYPE.RESERVATION_MENU:
    case OBJECT_TYPE.FRONTEND_RECEIPT_PRIMARY:
    case OBJECT_TYPE.FRONTEND_RECEIPT_SECONDARY:
    case OBJECT_TYPE.FRONTEND_PROFILE:
    case OBJECT_TYPE.FRONTEND_DASHBOARD_PRIMARY:
    case OBJECT_TYPE.FRONTEND_DASHBOARD_SECONDARY:
      return [HOOK_TYPE.PAGE];

    case OBJECT_TYPE.POS_PRINTER_RECEIPT:
    case OBJECT_TYPE.POS_PRINT_TICKET:
      return [HOOK_TYPE.PRINT_RECEIPT];

    default:
      throw new Error('Unexpected objectType');
  }
};

export default DisplayType;
