import { useLocation } from 'react-router-dom';

import { queryParser } from 'utils/helpers/queryHelper';

// @common
const useListQuery = () => {
  const location = useLocation();

  const { queryObj, page } = queryParser(location.search);

  return { queryObj, page };
};

export default useListQuery;
