import { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Button } from 'components/button';
import Heading from 'components/heading';
import { DisplayIcon } from 'components/icon';
import { Modal, ModalBody, ModalFooter } from 'components/modal';
import Text from 'components/text';
import { setModal } from 'modal/redux/modalSlice';
// import logError from 'utils/rollbar';

class ModalErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch() {
    this.setState({
      hasError: true
    });
    // logError(error, info);
  }

  handleClose = () => {
    const { props } = this;
    props.setModal(null);
  };

  render() {
    const { state, props } = this;
    const { children } = props;

    if (state.hasError) {
      return (
        <Modal visible title="Error" onClose={this.handleClose}>
          <ModalBodyStyles>
            <DisplayIcon icon="warning" variant="error" />
            <Heading>Oops!</Heading>
            <Text block>
              Something went wrong. Try closing this modal or refreshing the
              page. If it persists please contact support.
            </Text>
          </ModalBodyStyles>
          <ModalFooter>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      );
    }

    return children;
  }
}

const ModalBodyStyles = styled(ModalBody)`
  text-align: center;
`;

export default connect(null, { setModal })(ModalErrorBoundary);
