import { useDispatch } from 'react-redux';

import { Button } from 'components/button';
import Heading from 'components/heading';
import { Page, PageContent } from 'components/page';
import TitleAction from 'components/titleAction';
import { setModal } from 'modal/redux/modalSlice';

import Grid from './components/list/Grid';
import InviteUserModal from './components/list/InviteUserModal';
import { useIsAdminOwner } from './utils/hooks';

const List = () => {
  const dispatch = useDispatch();
  const canInviteUsers = useIsAdminOwner();

  const handleInviteUser = () => {
    dispatch(setModal(<InviteUserModal />));
  };

  return (
    <Page title="Accounts">
      <PageContent>
        <TitleAction>
          <Heading level={1}>Accounts</Heading>
          {canInviteUsers && (
            <Button startIcon="add" onClick={handleInviteUser}>
              Invite User
            </Button>
          )}
        </TitleAction>
        <Grid />
      </PageContent>
    </Page>
  );
};

export default List;
