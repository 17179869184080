import styled from 'styled-components';

import { Button } from 'components/button';
import { useFormikContext } from 'components/formik';

const LoginActions = () => {
  const { isSubmitting } = useFormikContext();
  return (
    <ActionStyles>
      <a href="https://admin.platform.commerce7.com/forgot-password">
        Forgot your password?
      </a>
      <Button type="submit" loading={isSubmitting}>
        Login
      </Button>
    </ActionStyles>
  );
};

const ActionStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default LoginActions;
