import { addFlashMessage } from 'flashMessage/redux/flashMessageSlice';
import { removeAllApiErrors } from 'layout/redux/layoutSlice';

const listVersionSettings = (build) =>
  build.query({
    query: (versionId) => ({
      url: `/version/${versionId}/setting`,
      method: 'GET'
    }),
    providesTags: () => {
      const tags = [{ type: 'VersionSetting', id: 'LIST' }];
      return tags;
    }
  });

const createVersionSetting = (build) =>
  build.mutation({
    query: ({ data, versionId }) => ({
      url: `/version/${versionId}/setting`,
      method: 'POST',
      data
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({ message: 'Client Setting Added!', type: 'success' })
      );
    },
    invalidatesTags: () => [{ type: 'VersionSetting', id: 'LIST' }]
  });

const updateVersionSetting = (build) =>
  build.mutation({
    query: ({ data, versionId, settingId }) => ({
      url: `/version/${versionId}/setting/${settingId}`,
      method: 'PUT',
      data
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({ message: 'Client Setting Updated!', type: 'success' })
      );
    },
    invalidatesTags: () => [{ type: 'VersionSetting', id: 'LIST' }]
  });

const deleteVersionSetting = (build) =>
  build.mutation({
    query: ({ versionId, settingId }) => ({
      url: `/version/${versionId}/setting/${settingId}`,
      method: 'DELETE'
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({ message: 'Client Setting Deleted!', type: 'success' })
      );
    },
    invalidatesTags: () => [{ type: 'VersionSetting', id: 'LIST' }]
  });

const reorderVersionSettings = (build) =>
  build.mutation({
    query: ({ versionId, data }) => ({
      url: `/version/${versionId}/setting/reorder`,
      method: 'PUT',
      data
    }),
    async onQueryStarted(_, { dispatch }) {
      dispatch(removeAllApiErrors());
    }
  });

const versionSettingApi = {
  listVersionSettings,
  createVersionSetting,
  updateVersionSetting,
  deleteVersionSetting,
  reorderVersionSettings
};
export default versionSettingApi;
