import { createSlice } from '@reduxjs/toolkit';

import { getSelfApi } from './userApi';

const initialState = {
  data: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getSelfReducer: (state, action) => {
      state.data = action.payload;
    }
  }
});
export const { getSelfReducer } = userSlice.actions;

export const getSelf = () => async (dispatch) => {
  const response = await getSelfApi();
  dispatch(getSelfReducer(response.data));
};

export default userSlice.reducer;
