/* global window */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Icon from 'components/icon';
import { removeAllApiErrors } from 'layout/redux/layoutSlice';

const scrollBehaviour = {
  top: 0,
  left: 0,
  behavior: 'smooth'
};

const ApiError = () => {
  const dispatch = useDispatch();
  const apiError = useSelector((state) => state.layout.api.error);
  const modal = useSelector((state) => state.modal);
  const location = useLocation();

  useEffect(() => {
    if (!modal.modal && apiError) {
      window.scrollTo(scrollBehaviour);
    }
  }, [apiError, modal.modal]);

  useEffect(() => {
    dispatch(removeAllApiErrors());
  }, [location.pathname, dispatch]);

  if (!apiError || modal.modal) {
    return null;
  }

  return (
    <ApiErrorStyles data-testid="api-error">
      <ApiErrorIconStyles icon="warning" />
      <ApiErrorMessageStyles>{apiError}</ApiErrorMessageStyles>
    </ApiErrorStyles>
  );
};

const ApiErrorStyles = styled.div`
  padding: 15px 30px;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;

  ${({ theme }) => `
    background: ${theme.c7__ui.errorColor};
  `}
`;

const ApiErrorMessageStyles = styled.span`
  margin-left: 10px;
  ${({ theme }) => `
    color: ${theme.c7__ui.colors.white};
  `}
`;

const ApiErrorIconStyles = styled(Icon)`
  path {
    fill: rgb(255, 255, 255);
  }
`;

export default ApiError;
