import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useSubmitVersionMutation } from 'api';
import { Formik, ModalFormFooter, Field, Checkbox } from 'components/formik';
import Heading from 'components/heading';
import { Modal, ModalBody } from 'components/modal';
import Text from 'components/text';
import { setModal } from 'modal/redux/modalSlice';
import { useGetLatestVersion } from 'version/utils/hooks';

import { validateAgree } from './validators';

const SubmitAppModal = () => {
  const dispatch = useDispatch();
  const [submitVersion] = useSubmitVersionMutation();
  const { latestVersion } = useGetLatestVersion();

  const { id } = latestVersion;

  const handleSubmit = async (_, formik) => {
    const { error } = await submitVersion(id);
    formik.setSubmitting(false);
    if (!error) {
      handleClose();
    }
  };

  const handleClose = () => {
    dispatch(setModal(null));
  };

  const securityLabel = (
    <Text>
      I confirm that my app follows Commerce7&apos;s{' '}
      <a
        href="https://developer.commerce7.com/docs/app-security-policy"
        target="_blank"
        rel="noreferrer"
      >
        security standards
      </a>
    </Text>
  );

  const termsAndConditionsLabel = (
    <Text>
      I agree to the{' '}
      <a
        href="https://developer.commerce7.com/docs/partner-terms-conditions"
        target="_blank"
        rel="noreferrer"
      >
        Terms & Conditions
      </a>
    </Text>
  );

  return (
    <Modal title="Submit App" visible onClose={handleClose}>
      <Formik
        onSubmit={handleSubmit}
        validate={validate}
        initialValues={getInitialValues()}
      >
        <ModalBody>
          <Text>
            Submitted apps are first reviewed by Commerce7 to ensure that they
            meet quality standards and follow the terms and conditions before
            they are made live on the app store.
          </Text>
          <StyledHeading level={3}>Before Submitting</StyledHeading>
          <StyledUl>
            <StyledLi>
              Pricing, APIs, and webhooks on your app version will not be
              editable after submission, so confirm that everything is correct.
            </StyledLi>
            <StyledLi>
              Test your app if you haven&apos;t already (do this from inside the
              version).
            </StyledLi>
            <StyledLi>
              Review the listing for your app. If this is a new version, you may
              want to update screenshots or details.
            </StyledLi>
          </StyledUl>
          <StyledFormGeneral>
            <Field
              name="securityStandards"
              label={securityLabel}
              component={Checkbox}
            />
            <Field
              name="termsAndConditions"
              label={termsAndConditionsLabel}
              component={Checkbox}
            />
          </StyledFormGeneral>
        </ModalBody>
        <ModalFormFooter saveButtonText="Submit App" />
      </Formik>
    </Modal>
  );
};

const StyledFormGeneral = styled.div`
  div {
    margin-bottom: 10px !important;
  }
`;

const transformData = (values) => {
  const data = {};
  if (values.securityStandards) {
    data.securityStandards = values.securityStandards;
  }
  if (values.termsAndConditions) {
    data.termsAndConditions = values.termsAndConditions;
  }
  return data;
};

const validate = (values) => {
  const data = transformData(values);
  const errors = validateAgree(data);
  return errors;
};

const getInitialValues = () => {
  const initialValues = {
    securityStandards: false,
    termsAndConditions: false
  };
  return initialValues;
};

const StyledHeading = styled(Heading)`
  margin: 20px 0 10px 0;
`;

const StyledLi = styled.li`
  letter-spacing: 0.2;
  line-height: 1.6;
`;

const StyledUl = styled.ul`
  padding-left: 25px;
  margin-bottom: 26px;
`;

export default SubmitAppModal;
