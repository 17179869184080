import { Input } from '@commerce7/admin-ui';
import get from 'lodash.get';

// @common
const FormikInput = (props) => {
  const {
    autoFocus,
    autoComplete,
    className,
    description,
    disabled,
    endIcon,
    field,
    form,
    innerRef,
    inputMode,
    label,
    normalize,
    onChange,
    onBlur,
    placeholder,
    startIcon,
    type
  } = props;
  const { onBlur: onFieldBlur, onChange: onFieldChange, value, name } = field;
  const { errors, touched } = form;

  const handleOnChange = (e) => {
    let newEvent = e;

    if (normalize) {
      const { values } = form;
      const newValue = normalize(e.target.value, value, values);
      newEvent = { target: { value: newValue, id: name, name } };
    }

    if (onChange) {
      onChange(newEvent);
    }
    return onFieldChange(newEvent);
  };

  const handleOnBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
    return onFieldBlur(e);
  };

  // Convert nested error keys delimited by slashes "/" to "." instead so
  // lodash get can resolve them
  const convertedErrors = {};
  Object.keys(errors).forEach((key) => {
    const newKey = key.split('/').join('.');
    convertedErrors[newKey] = errors[key];
  });

  let errorMessage;
  if (get(convertedErrors, name) && get(touched, name)) {
    errorMessage = get(convertedErrors, name);
  }

  let newValue = value;
  if (typeof value === 'undefined' || value === null) {
    newValue = '';
  }

  return (
    <Input
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      className={className}
      dataTestId={`formik-${name}`}
      description={description}
      disabled={disabled}
      errorMessage={errorMessage}
      endIcon={endIcon}
      inputMode={inputMode}
      id={name}
      label={label}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      placeholder={placeholder}
      ref={innerRef}
      startIcon={startIcon}
      type={type}
      value={newValue}
    />
  );
};

export default FormikInput;
