/* globals FormData */
import axios from 'axios';
import { useRef, useState, forwardRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import Text from 'components/text';
import { axiosHeader, getRootUrl } from 'utils/helpers/apiHelper';

import { ProgressStyles } from './styles';

const UPLOAD_STATUS = {
  IDLE: 'IDLE',
  UPLOADING: 'UPLOADING'
};

const FileUploader = forwardRef((props, ref) => {
  const { fileSchema, onUploadComplete, children, className } = props;
  const [status, setStatus] = useState(UPLOAD_STATUS.IDLE);
  const fileInputRef = useRef();
  const [progress, setProgress] = useState(0);
  const [uploadErrorMessage, setErrorMessage] = useState(null);

  useImperativeHandle(ref, () => ({
    handleUploadClick() {
      fileInputRef.current.click();
    }
  }));

  const onComplete = (response) => {
    setStatus(UPLOAD_STATUS.IDLE);
    if (onUploadComplete) {
      onUploadComplete(response.data);
    }
    // Reset the file input so new files can be uploaded without refreshing
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const onError = (error) => {
    const { response } = error;
    const { data } = response;
    const { message } = data;
    setStatus(UPLOAD_STATUS.IDLE);
    setErrorMessage(message || 'An error occurred');
    setProgress(0);
  };

  const onBeforeUpload = () => {
    setStatus(UPLOAD_STATUS.UPLOADING);
    setErrorMessage(null);
    setProgress(0);
  };

  const handleFileChange = async (e) => {
    const data = new FormData();
    const files = e.target.files[0];

    if (files) {
      onBeforeUpload();

      data.append('fileSchema', JSON.stringify(fileSchema));
      data.append('file', files);

      const config = {
        onUploadProgress(progressEvent) {
          const { loaded, total } = progressEvent;
          const percentCompleted = (loaded / total) * 100;
          setProgress(percentCompleted);
        },
        headers: axiosHeader().headers
      };

      const url = `${getRootUrl()}/file`;
      try {
        const response = await axios.post(url, data, config);
        onComplete(response);
      } catch (error) {
        onError(error);
      }
    }
  };

  if (!fileSchema) {
    return null;
  }

  return (
    <StyledFileUploader className={className}>
      {children}
      {status === UPLOAD_STATUS.UPLOADING && (
        <ProgressStyles progress={progress} />
      )}
      {uploadErrorMessage && (
        <Text small error>
          {uploadErrorMessage}
        </Text>
      )}
      <input
        hidden
        type="file"
        // multiple
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </StyledFileUploader>
  );
});

const StyledFileUploader = styled.div`
  position: relative;
`;

export default FileUploader;
