import { validateForUI } from 'utils/helpers/jsonValidator';

import { HOOK_TYPE, OBJECT_TYPE } from './enums';

const hookData = (params) => ({
  type: 'object',
  properties: {
    icon: { type: ['string', 'null'] },
    contextMenuType: { type: ['string', 'null'] },
    url: { type: 'string', format: 'uri' },
    title: { type: 'string' },
    iFrameHeight: { type: ['string', 'null'] }
  },
  required: getRequiredHookData(params.hookType),
  additionalProperties: false
});

const getRequiredHookData = (hookType) => {
  const requiredFields = ['url'];
  switch (hookType) {
    case HOOK_TYPE.PAGE:
      requiredFields.push('title');
      break;
    case HOOK_TYPE.CONTEXT_MENU:
      requiredFields.push('title');
      requiredFields.push('contextMenuType');
      break;
    case HOOK_TYPE.TAB_MENU:
      requiredFields.push('title');
      break;
    case HOOK_TYPE.REPORT:
      requiredFields.push('title');
      break;
    case HOOK_TYPE.PRINT_RECEIPT:
      requiredFields.push('title');
      break;
    default:
      break;
  }
  return requiredFields;
};

const properties = (params) => ({
  objectType: {
    type: 'string',
    enum: Object.keys(OBJECT_TYPE)
  },
  hookType: {
    type: 'string',
    enum: Object.keys(HOOK_TYPE)
  },
  hookData: hookData(params)
});

const versionExtensionCreateSchema = (params) => ({
  type: 'object',
  properties: properties(params),
  required: ['objectType', 'hookType', 'hookData'],
  additionalProperties: false
});

const versionExtensionUpdateSchema = (params) => ({
  type: 'object',
  properties: properties(params),
  additionalProperties: false
});

export const validateCreate = (params) =>
  validateForUI(
    versionExtensionCreateSchema(params),
    params,
    customValidateExtension(params)
  );

export const validateUpdate = (params) =>
  validateForUI(
    versionExtensionUpdateSchema(params),
    params,
    customValidateExtension(params)
  );

const customValidateExtension = (params) => {
  const errorArray = [];

  if (
    [
      OBJECT_TYPE.ORDER_LIST,
      OBJECT_TYPE.PRODUCT_LIST,
      OBJECT_TYPE.CUSTOMER_LIST,
      OBJECT_TYPE.POS_CART_SUMMARY,
      OBJECT_TYPE.CART_DETAIL,
      OBJECT_TYPE.CORPORATE_ORDER_DETAIL
    ].includes(params.objectType) &&
    params.hookType !== HOOK_TYPE.CONTEXT_MENU
  ) {
    errorArray.push({
      field: 'hookType',
      message: 'Must select context menu as type'
    });
  }

  if (
    [
      OBJECT_TYPE.ORDER_DETAIL,
      OBJECT_TYPE.PRODUCT_DETAIL,
      OBJECT_TYPE.CUSTOMER_DETAIL,
      OBJECT_TYPE.CLUB_MEMBERSHIP_DETAIL
    ].includes(params.objectType) &&
    params.hookType !== HOOK_TYPE.CONTEXT_MENU &&
    params.hookType !== HOOK_TYPE.TAB_MENU &&
    params.hookType !== HOOK_TYPE.CARD
  ) {
    errorArray.push({
      field: 'hookType',
      message: 'Must select context menu, tab menu or card as type'
    });
  }

  if (
    (params.objectType === OBJECT_TYPE.ORDER_REPORTS ||
      params.objectType === OBJECT_TYPE.FINANCE_REPORTS ||
      params.objectType === OBJECT_TYPE.CUSTOMER_REPORTS ||
      params.objectType === OBJECT_TYPE.CLUB_REPORTS ||
      params.objectType === OBJECT_TYPE.INVENTORY_REPORTS) &&
    params.hookType !== HOOK_TYPE.REPORT
  ) {
    errorArray.push({
      field: 'hookType',
      message: 'Must select report as type'
    });
  }

  if (
    [
      OBJECT_TYPE.MARKETING_MENU,
      OBJECT_TYPE.STORE_MENU,
      OBJECT_TYPE.REPORT_MENU,
      OBJECT_TYPE.POS_MENU,
      OBJECT_TYPE.RESERVATION_MENU,
      OBJECT_TYPE.FRONTEND_RECEIPT_PRIMARY,
      OBJECT_TYPE.FRONTEND_RECEIPT_SECONDARY,
      OBJECT_TYPE.FRONTEND_PROFILE,
      OBJECT_TYPE.FRONTEND_DASHBOARD_PRIMARY,
      OBJECT_TYPE.FRONTEND_DASHBOARD_SECONDARY
    ].includes(params.objectType) &&
    params.hookType !== HOOK_TYPE.PAGE
  ) {
    errorArray.push({
      field: 'hookType',
      message: 'Must select page as type'
    });
  }

  if (
    [OBJECT_TYPE.POS_PRINTER_RECEIPT, OBJECT_TYPE.POS_PRINT_TICKET].includes(
      params.objectType
    ) &&
    params.hookType !== HOOK_TYPE.PRINT_RECEIPT
  ) {
    errorArray.push({
      field: 'hookType',
      message: 'Must select Print Receipt as type'
    });
  }

  return errorArray;
};
