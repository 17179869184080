import get from 'lodash.get';

const Cell = (props) => {
  const { element, object } = props;

  const { type } = element;

  if (type === 'function') {
    return (
      typeof get(object, element.data) !== 'undefined' &&
      get(object, element.data) !== null &&
      element.func(get(object, element.data))
    );
  }

  if (type === 'component') {
    const componentProps = {
      [element.props]: object,
      element
    };

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <element.component {...componentProps} />;
  }

  return get(object, element.data);
};

export default Cell;
