import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useDeleteVersionTestTenantMutation } from 'api';
import Icon from 'components/icon';
import Text from 'components/text';

import { useListVersionTestTenants } from '../utils/hooks';

const List = () => {
  const params = useParams();
  const { data, isLoading } = useListVersionTestTenants();
  const [deleteVersionTestTenant] = useDeleteVersionTestTenantMutation();

  if (isLoading) return null;

  const handleDeleteTestTenant = async (tenantId) => {
    await deleteVersionTestTenant({
      tenantId,
      versionId: params.id
    });
  };

  return (
    <StyledList>
      {data.map((tenant) => (
        <StyledTenant key={tenant.id}>
          <Text>{tenant.testTenantId}</Text>
          <Icon
            icon="trash"
            onClick={() => handleDeleteTestTenant(tenant.id)}
          />
        </StyledTenant>
      ))}
    </StyledList>
  );
};

const StyledList = styled.div`
  padding: 10px 0;
  max-width: 400px;
`;

const StyledTenant = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`;

export default List;
