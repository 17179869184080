import { isEmail } from 'validator';

import { validateForUI } from 'utils/helpers/jsonValidator';

import { TYPE } from './enums';

const properties = {
  title: { type: 'string' },
  type: { type: 'string', enum: Object.keys(TYPE) },
  appSecret: { type: 'string', minLength: 64 },
  developerName: { type: 'string' },
  developerEmail: { type: 'string' }
};

const appCreateSchema = {
  type: 'object',
  properties,
  required: ['title', 'type', 'appSecret', 'developerName', 'developerEmail'],
  additionalProperties: false
};

const appUpdateSchema = {
  type: 'object',
  properties,
  required: ['developerName', 'developerEmail'],
  additionalProperties: false
};

const updateAppSecreteSchema = {
  type: 'object',
  properties: {
    appSecret: { type: 'string', minLength: 64 }
  },
  required: ['appSecret'],
  additionalProperties: false
};

export const validateCreate = (params) => {
  const errors = validateForUI(appCreateSchema, params, customValidate(params));
  return errors;
};

export const validateUpdate = (params) => {
  const errors = validateForUI(appUpdateSchema, params, customValidate(params));
  return errors;
};

export const validateUpdateAppSecret = (params) => {
  const errors = validateForUI(updateAppSecreteSchema, params);
  return errors;
};

const customValidate = (params) => {
  const errorArray = [];

  if (params.developerEmail && !isEmail(params.developerEmail)) {
    const error = { field: `developerEmail`, message: 'Invalid Email' };
    errorArray.push(error);
  }

  return errorArray;
};
