import { useEffect } from 'react';

import { useFormikContext } from 'components/formik';

const CodeFill = () => {
  const { values, touched, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!touched.code) {
      setFieldValue('code', values.title.toLowerCase().replaceAll(' ', '-'));
    }
  }, [values.title, touched.code, setFieldValue]);

  return null;
};

export default CodeFill;
