import { PAYMENT_HANDLED_BY } from 'app/utils/enums';
import Alert from 'components/alert';
import { useFormikContext } from 'components/formik';

const PricingBanner = () => {
  const { values } = useFormikContext();
  const { setupFee, paymentHandledBy } = values;

  if (paymentHandledBy === PAYMENT_HANDLED_BY.EXTERNAL || setupFee !== '0.00') {
    return (
      <Alert>
        Payment for custom pricing are not handled by Commerce7. These will need
        to be handled by you.
      </Alert>
    );
  }
  return null;
};

export default PricingBanner;
