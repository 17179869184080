import { Table, c7Colors } from '@commerce7/admin-ui';
import styled from 'styled-components';

const { Tr } = Table;
const StyledTr = styled(Tr)``;

const DraggableTr = (props) => {
  const { children, provided, snapshot, onClick } = props;

  return (
    <StyledDraggableTr
      // eslint-disable-next-line
      {...provided.draggableProps}
      ref={provided.innerRef}
      className={`${StyledTr.toString().replace('.', '')} ${
        StyledTr.styledComponentId
      }`}
      isDragging={snapshot.isDragging}
      onClick={onClick}
      data-testid="grid-row"
    >
      {children}
    </StyledDraggableTr>
  );
};

const StyledDraggableTr = styled.tr`
  ${({ theme, isDragging, onClick }) => `
  border-bottom: 1px solid ${theme.c7__ui.borderColor};

    &:last-of-type {
        border-bottom: none;
    }

    background-color: ${theme.c7__ui.backgroundColor};

    ${
      isDragging
        ? `
            border: 1px solid ${theme.c7__ui.borderColor};
            box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22);
        `
        : ''
    }
    ${onClick ? 'cursor: pointer; ' : ''}

    &:hover {
      ${
        onClick
          ? `background-color: ${tr[theme.c7__ui.mode].backgroundColor.hover};`
          : ''
      }}
    }
  `}
`;

const tr = {
  light: {
    backgroundColor: {
      hover: c7Colors.blue100
    }
  },
  dark: {
    backgroundColor: {
      hover: c7Colors.slate100
    }
  }
};

export default DraggableTr;
