/* globals window */

export const loginLink = () => {
  const currentDomain = `${protocol()}${domain()}${port()}`;
  const link = `${currentDomain}/login`;
  return link;
};

// Examples:
// http://cool-new-app.dev-center.platform.dev7.io:9007 --- Development
// https://cool-new-app.dev-center.platform.commerce7.com --- Production
export const appDomain = (appId) => {
  const path = `${protocol()}${appId}.${domain()}${port()}`;
  return path;
};

export const mainDomain = () => {
  const path = `${protocol()}${domain()}${port()}`;
  return path;
};

// @common
const protocol = () => {
  switch (process.env.APP_MODE) {
    case 'production':
      return 'https://';
    case 'frontend':
      return 'http://';
    default:
      return 'http://';
  }
};

const domain = () => {
  switch (process.env.APP_MODE) {
    case 'production': {
      const { hostname } = window.location;
      if (hostname.split('.').includes('dev-center-v2')) {
        return `dev-center-v2.platform.commerce7.com`;
      }
      return `dev-center.platform.commerce7.com`;
    }
    case 'frontend':
      return `dev-center.platform.dev7.io`;
    default:
      return `dev-center.platform.dev7.io`;
  }
};

const port = () => {
  if (process.env.APP_MODE === 'production') {
    return '';
  }
  return ':9007';
};
