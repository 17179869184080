import Avatar from 'components/avatar';
import { userAvatarInfo } from 'utils/helpers/miscHelper';

const AvatarDisplay = (props) => {
  const { appXAccount } = props;
  const formattedUserAvatarInfo = userAvatarInfo(appXAccount);

  return (
    <Avatar
      label={formattedUserAvatarInfo.label}
      imageSrc={formattedUserAvatarInfo.imageSrc}
      imageAlt={formattedUserAvatarInfo.imageAlt}
      size="small"
    />
  );
};

export default AvatarDisplay;
