import Icon from 'components/icon';

import UserDropdownMenuItem from './UserDropdownMenuItem';

const Admin = () => (
  <UserDropdownMenuItem>
    <a type="button" href="https://admin.platform.commerce7.com/">
      <Icon icon="applicationSwitcher" />
      Go to Admin
    </a>
  </UserDropdownMenuItem>
);

export default Admin;
