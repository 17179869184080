import { addFlashMessage } from 'flashMessage/redux/flashMessageSlice';
import { removeAllApiErrors } from 'layout/redux/layoutSlice';

const listApps = (build) =>
  build.query({
    query: (query) => ({
      url: '/app',
      method: 'GET',
      params: query
    }),
    providesTags: () => {
      const tags = [{ type: 'App', id: 'LIST' }];
      return tags;
    }
  });

const getApp = (build) =>
  build.query({
    query: (id) => ({
      url: `/app/${id}`,
      method: 'GET'
    }),
    providesTags: (_result, _error, id) => {
      const tags = [{ type: 'App', id }];
      return tags;
    }
  });

const getAppStats = (build) =>
  build.query({
    query: (appId) => ({
      url: `/app/${appId}/stats`,
      method: 'GET'
    }),
    providesTags: () => {
      const tags = [{ type: 'App', id: 'STATS' }];
      return tags;
    }
  });

const createApp = (build) =>
  build.mutation({
    query: ({ data }) => ({
      url: '/app',
      method: 'POST',
      data: { ...data }
    }),
    async onQueryStarted(_, { dispatch }) {
      dispatch(removeAllApiErrors());
    },
    invalidatesTags: () => [{ type: 'App', id: 'LIST' }]
  });

const updateApp = (build) =>
  build.mutation({
    query: ({ data, appId }) => ({
      url: `/app/${appId}`,
      method: 'PUT',
      data: { ...data }
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({
          message: 'App Updated.',
          type: 'success'
        })
      );
    },
    invalidatesTags: (_result, _error, { appId }) => [
      { type: 'App', id: 'LIST' },
      { type: 'App', appId }
    ]
  });

const deleteApp = (build) =>
  build.mutation({
    query: (id) => ({
      url: `/app/${id}`,
      method: 'DELETE'
    }),
    async onQueryStarted(_, { dispatch }) {
      dispatch(removeAllApiErrors());
    }
  });

const updateAppSecret = (build) =>
  build.mutation({
    query: ({ id, data }) => ({
      url: `/app/${id}/secret`,
      method: 'PUT',
      data
    }),
    async onQueryStarted(_, { dispatch }) {
      dispatch(removeAllApiErrors());
    }
  });

const appApi = {
  listApps,
  getApp,
  getAppStats,
  createApp,
  updateApp,
  deleteApp,
  updateAppSecret
};
export default appApi;
