import { useListPrivateTenantsQuery } from 'api';

export const useListPrivateTenants = () => {
  const {
    data: privateTenants,
    isLoading,
    error
  } = useListPrivateTenantsQuery();
  return {
    data: privateTenants?.data,
    total: privateTenants?.total,
    error,
    isLoading
  };
};
