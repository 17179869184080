import { useState, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';

import { useReorderVersionSettingsMutation } from 'api';
import NoRecords from 'components/noRecords';
import { Table, Tbody, Thead, Th, Tr } from 'components/table';

import Setting from './components/grid/Setting';
import { useListVersionSettings } from './utils/hooks';

const VersionSettingsGrid = () => {
  const { data, total } = useListVersionSettings();
  const params = useParams();
  const [reorderVersionSettings] = useReorderVersionSettingsMutation();
  const [settingArray, setSettingArray] = useState(
    data.map((setting) => setting.id)
  );

  useEffect(() => {
    setSettingArray(data.map((setting) => setting.id));
  }, [data]);

  // If a setting is deleted, the settingArray (of setting id's) can be misaligned with the
  // data for 1 render while the useEffect takes effect and would error out.
  if (settingArray.length !== data.length) return null;

  if (total === 0)
    return (
      <NoRecords
        title="No client settings found"
        description="If your app needs additional information for installation, add settings that clients will be required to complete. Commerce7 will POST these settings to the URL under the “Installation” tab."
      />
    );

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;
    if (!destination) {
      return;
    }
    if (destination.index === source.index) {
      return;
    }

    const newSettingArray = Array.from(settingArray);
    newSettingArray.splice(source.index, 1);
    newSettingArray.splice(destination.index, 0, draggableId);
    setSettingArray(newSettingArray);

    const newSortOrder = newSettingArray.map((id, index) => ({
      id,
      sortOrder: index + 1
    }));

    if (newSortOrder.length > 0) {
      await reorderVersionSettings({
        versionId: params.id,
        data: newSortOrder
      });
    }
  };

  const settings = settingArray.map((id) =>
    data.find((setting) => setting.id === id)
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Table>
        <Thead>
          <Tr>
            <Th />
            <Th>Title</Th>
            <Th>Code</Th>
            <Th>Data Type</Th>
            <Th>Required</Th>
          </Tr>
        </Thead>
        <Droppable droppableId="versionSettingsGrid">
          {(provided) => (
            // eslint-disable-next-line
            <Tbody ref={provided.innerRef} {...provided.droppableProps}>
              {settings.map((setting, index) => (
                <Setting key={setting.id} setting={setting} index={index} />
              ))}
              {provided.placeholder}
            </Tbody>
          )}
        </Droppable>
      </Table>
    </DragDropContext>
  );
};

export default VersionSettingsGrid;
