import { Columns, Column } from 'components/columns';
import { FormDescription, LinkToDocs } from 'components/formInstructions';
import { Field, Input } from 'components/formik';

import {
  IFRAME_MESSAGE,
  IFRAME_MESSAGE_DOCS
} from '../../../../utils/constants';

import IFrameField from './common/IFrameField';
import IconUploader from './common/IconUploader';

const PageForm = () => (
  <>
    <Columns>
      <Column span={6}>
        <IconUploader />
      </Column>
      <Column span={6}>
        <Field label="Title" name="hookData.title" component={Input} />
      </Column>
    </Columns>
    <IFrameField />
    <FormDescription>
      {IFRAME_MESSAGE} <LinkToDocs url={IFRAME_MESSAGE_DOCS} />
    </FormDescription>
  </>
);

export default PageForm;
