// eslint-disable-next-line import/no-cycle
import { api } from 'api';
import { addFlashMessage } from 'flashMessage/redux/flashMessageSlice';
import { removeAllApiErrors } from 'layout/redux/layoutSlice';
import { appFromURL } from 'utils/helpers/apiHelper';

const listVersions = (build) =>
  build.query({
    query: () => ({
      url: '/version',
      method: 'GET'
    }),
    providesTags: () => {
      const tags = [{ type: 'Version', id: 'LIST' }];
      return tags;
    }
  });

const getVersion = (build) =>
  build.query({
    query: (id) => ({
      url: `/version/${id}`,
      method: 'GET'
    }),
    providesTags: (_result, _error, id) => {
      const tags = [{ type: 'Version', id }];
      return tags;
    }
  });

const duplicateVersion = (build) =>
  build.mutation({
    query: (versionId) => ({
      url: `/version/${versionId}/duplicate`,
      method: 'POST'
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({ message: 'New Version Created!', type: 'success' })
      );
    },
    invalidatesTags: () => [{ type: 'Version', id: 'LIST' }]
  });

const submitVersion = (build) =>
  build.mutation({
    query: (versionId) => ({
      url: `/version/${versionId}/submit`,
      method: 'PUT'
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({
          message: 'App Version Submitted.',
          type: 'success'
        })
      );
    },
    invalidatesTags: () => [{ type: 'Version', id: 'LIST' }]
  });

const updateVersion = (build) =>
  build.mutation({
    query: ({ data, versionId }) => ({
      url: `/version/${versionId}`,
      method: 'PUT',
      data: { ...data }
    }),
    async onQueryStarted({ versionId }, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      // Pessimistic Update
      const { data: updatedVersion } = await queryFulfilled;
      dispatch(
        api.util.updateQueryData('getVersion', versionId, (draft) => {
          Object.assign(draft, updatedVersion);
        })
      );
    },
    invalidatesTags: () => [
      { type: 'Version', id: 'LIST' },
      { type: 'App', id: appFromURL() }
    ]
  });

const deleteVersion = (build) =>
  build.mutation({
    query: (versionId) => ({
      url: `/version/${versionId}`,
      method: 'DELETE'
    }),
    async onQueryStarted(_, { dispatch, queryFulfilled }) {
      dispatch(removeAllApiErrors());
      await queryFulfilled;
      dispatch(
        addFlashMessage({
          message: 'App Version Deleted.',
          type: 'success'
        })
      );
    },
    invalidatesTags: () => [{ type: 'Version', id: 'LIST' }]
  });

const versionApi = {
  listVersions,
  getVersion,
  duplicateVersion,
  submitVersion,
  updateVersion,
  deleteVersion
};
export default versionApi;
