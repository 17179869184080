import styled from 'styled-components';

import Heading from 'components/heading';
import { Page } from 'components/page';
import Region from 'components/region';
import Text from 'components/text';

import AddForm from './components/add/AddForm';

const Add = () => (
  <Page title="Create App">
    <StyledAddPage>
      <Heading level={1}>Add App</Heading>
      <Region>
        <Text>
          Add some basic details about you and your app. You&apos;ll be able to
          change this information later if you need to.
        </Text>
        <AddForm />
      </Region>
    </StyledAddPage>
  </Page>
);

const StyledAddPage = styled.div`
  max-width: 650px;
  margin: 0 auto;
  padding-top: 60px;
`;

export default Add;
