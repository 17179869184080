import Details from './formGeneral/Details';
import General from './formGeneral/General';
import SetupAndSupport from './formGeneral/SetupAndSupport';

const FormGeneral = () => (
  <>
    <General />
    <Details />
    <SetupAndSupport />
  </>
);

export default FormGeneral;
