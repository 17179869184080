import styled from 'styled-components';

import { useGetApp } from 'app/utils/hooks';
import Breadcrumb from 'components/breadcrumb';
import Heading from 'components/heading';

import { useGetVersion } from '../../utils/hooks';

import AlertBanner from './header/AlertBanner';
import ContextMenu from './header/ContextMenu';
import Stepper from './header/Stepper';

const Header = () => {
  const { app, isLoading: appLoading } = useGetApp();
  const { version, isLoading: versionLoading } = useGetVersion();

  if (appLoading || versionLoading) return null;

  const { versionNumber } = version;

  const breadcrumbs = [
    { to: '/', label: app.title },
    { label: 'Edit Version' }
  ];

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <StyledHeading level={1}>Version {versionNumber}</StyledHeading>
      <ContextMenu />
      <AlertBanner />
      <Stepper />
    </>
  );
};

const StyledHeading = styled(Heading)`
  margin-bottom: 0px;
`;

export default Header;
