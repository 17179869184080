import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDuplicateVersionMutation } from 'api';
import { Button } from 'components/button';
import { useCanAddVersion, useGetLatestVersion } from 'version/utils/hooks';

const AddVersionButton = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);

  const { canAddVersion, isLoading } = useCanAddVersion();
  const { latestVersion } = useGetLatestVersion();
  const [duplicateVersion] = useDuplicateVersionMutation();

  if (isLoading || !canAddVersion) return null;

  const handleDuplicateVersion = async () => {
    setSubmitting(true);
    const { error, data } = await duplicateVersion(latestVersion.id);
    setSubmitting(false);
    if (!error) {
      navigate(`/version/${data.id}/web-hooks`);
    }
  };

  return (
    <Button
      variant="secondary"
      startIcon="add"
      loading={submitting}
      onClick={handleDuplicateVersion}
    >
      Add Version
    </Button>
  );
};

export default AddVersionButton;
