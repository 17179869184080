import { createSlice } from '@reduxjs/toolkit';

import {
  setColorModeCookie,
  checkUserPreferredColorMode
} from 'utils/helpers/layoutHelper';

const initialState = {
  navigation: {
    isLeftNavigationVisible: false
  },
  style: {
    colorMode: checkUserPreferredColorMode()
  },
  api: {
    activeRequests: [],
    error: ''
  }
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleColorMode: (state, action) => {
      setColorModeCookie(action.payload);
      state.style.colorMode = action.payload;
    },
    toggleLeftNavigation: (state, action) => {
      state.navigation.isLeftNavigationVisible = action.payload;
    },
    addAxiosRequest: (state, action) => {
      state.api.activeRequests = [...state.api.activeRequests, action.payload];
    },
    removeAxiosRequest: (state, action) => {
      state.api.activeRequests = state.api.activeRequests.filter(
        (uuid) => uuid !== action.payload
      );
    },
    removeAllAxiosRequests: (state) => {
      state.api.activeRequests = [];
    },
    addApiError: (state, action) => {
      state.api.error = action.payload;
    },
    removeAllApiErrors: (state) => {
      state.api.error = '';
    }
  }
});

export const {
  toggleColorMode,
  toggleLeftNavigation,
  addAxiosRequest,
  removeAxiosRequest,
  removeAllAxiosRequests,
  addApiError,
  removeAllApiErrors
} = layoutSlice.actions;
export default layoutSlice.reducer;
