import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Avatar from 'components/avatar';
import { userAvatarInfo } from 'utils/helpers/miscHelper';

const AvatarNavBar = (props) => {
  const user = useSelector((state) => state.user.data);

  const { toggleDropdownVisibility, isDropdownVisible } = props;
  const formattedUserAvatarInfo = userAvatarInfo(user);

  return (
    <StyledAvatarButton
      onClick={() => toggleDropdownVisibility(!isDropdownVisible)}
      aria-label="View user dropdown"
      type="button"
      id="navigationUserButton"
      aria-controls="navigationUserDropdown"
      aria-haspopup="true"
      aria-expanded={isDropdownVisible}
    >
      <Avatar
        label={formattedUserAvatarInfo.label}
        imageSrc={formattedUserAvatarInfo.imageSrc}
        imageAlt={formattedUserAvatarInfo.imageAlt}
        size="small"
      />
    </StyledAvatarButton>
  );
};

const StyledAvatarButton = styled.button`
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  border: none;
  display: flex;
  border-radius: 50%;
  cursor: pointer;
`;

export default AvatarNavBar;
