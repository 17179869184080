import { useDispatch } from 'react-redux';

import { Button } from 'components/button';
import Heading from 'components/heading';
import Region from 'components/region';
import TitleAction from 'components/titleAction';
import { setModal } from 'modal/redux/modalSlice';
import AddModal from 'versionWebHook/AddModal';
import Grid from 'versionWebHook/Grid';

import { useGetVersion } from '../../utils/hooks';

const WebHooks = () => {
  const dispatch = useDispatch();
  const { isApproved } = useGetVersion();

  const handleAddWebHooks = () => {
    dispatch(setModal(<AddModal />));
  };
  return (
    <Region>
      <TitleAction>
        <Heading>Webhooks</Heading>
        {!isApproved && (
          <Button
            variant="secondary"
            startIcon="add"
            onClick={handleAddWebHooks}
          >
            Add Webhook
          </Button>
        )}
      </TitleAction>
      <Grid />
    </Region>
  );
};

export default WebHooks;
