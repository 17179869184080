/* globals window */
import { useNavigate, useLocation } from 'react-router-dom';

import { urlPushQuery, concatUrlString } from 'utils/helpers/queryHelper';
import useListQuery from 'utils/hooks/useListQuery';

import CorePagination from './components/CorePagination';

const Pagination = (props) => {
  const { listHook } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { total, isLoading } = listHook();
  const { queryObj, page = 1 } = useListQuery();

  if (isLoading) return null;

  const handleSearch = (qString, pageNumber) => {
    const urlString = concatUrlString(qString, pageNumber);
    navigate(urlPushQuery(location.pathname, urlString));
  };

  const onPaginationClick = (e, pageNumber) => {
    e.preventDefault();
    let queryString;
    Object.keys(queryObj).forEach((key) => {
      let value = queryObj[key];
      if (key === 'q') {
        value = encodeURIComponent(queryObj[key]);
      }
      if (queryString) {
        queryString += `&${key}=${value}`;
      } else {
        queryString = `${key}=${value}`;
      }
    });
    window.scrollTo(0, 0);
    if (queryString) {
      handleSearch(queryString, pageNumber);
    } else {
      handleSearch(null, pageNumber);
    }
  };

  return (
    <CorePagination
      recordCount={total}
      page={page}
      onPaginationClick={onPaginationClick}
    />
  );
};

export default Pagination;
