import { useDispatch } from 'react-redux';

import { Button } from 'components/button';
import Heading from 'components/heading';
import Region from 'components/region';
import TitleAction from 'components/titleAction';
import { setModal } from 'modal/redux/modalSlice';
import AddModal from 'versionExtension/AddModal';
import Grid from 'versionExtension/Grid';

import { useGetVersion } from '../../utils/hooks';

const ExtensionsList = () => {
  const dispatch = useDispatch();
  const { isApproved } = useGetVersion();

  const handleAddExtension = () => {
    dispatch(setModal(<AddModal />));
  };
  return (
    <Region>
      <TitleAction>
        <Heading>Extensions</Heading>
        {!isApproved && (
          <Button
            variant="secondary"
            startIcon="add"
            onClick={handleAddExtension}
          >
            Add Extension
          </Button>
        )}
      </TitleAction>
      <Grid />
    </Region>
  );
};

export default ExtensionsList;
