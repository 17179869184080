import { useEffect } from 'react';

import useDebounce from 'utils/hooks/useDebounce';
import usePrevious from 'utils/hooks/usePrevious';

// @common
const useSearch = (searchString, handler) => {
  const debouncedSearchTerm = useDebounce(searchString, 250);
  const previousDebouncedSearchTerm = usePrevious(debouncedSearchTerm);

  useEffect(() => {
    if (
      debouncedSearchTerm || // If there is a debounced search term - always search
      (previousDebouncedSearchTerm && !debouncedSearchTerm) // If there is no debounced search term, check if there was previously one. (so we dont search on mount)
    ) {
      handler(debouncedSearchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);
};

export default useSearch;
