import styled from 'styled-components';

const Content = (props) => {
  const { children } = props;
  return <StyledContent>{children}</StyledContent>;
};

const StyledContent = styled.div`
  flex: auto;
  padding: 20px;
  @media ${({ theme }) => theme.c7__ui.breakpoints.largeUp} {
    padding: 30px;
    &:not(:only-child) {
      max-width: calc(100% - 380px);
    }
  }
`;

export default Content;
