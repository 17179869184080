import { PAYMENT_HANDLED_BY } from 'app/utils/enums';
import { validateForUI } from 'utils/helpers/jsonValidator';

const properties = {
  installUrl: { type: 'string', format: 'uri' },
  installUsername: { type: 'string' },
  installPassword: { type: 'string', minLength: 8 },
  uninstallUrl: { type: 'string', format: 'uri' },
  settingUrl: { type: 'string', format: 'uri' },
  monthlyFee: { type: 'integer', minimum: 0 },
  monthlyFeeAUD: { type: 'integer', minimum: 0 },
  monthlyFeeCAD: { type: 'integer', minimum: 0 },
  monthlyFeeEUR: { type: 'integer', minimum: 0 },
  monthlyFeeGBP: { type: 'integer', minimum: 0 },
  monthlyFeeNZD: { type: 'integer', minimum: 0 },
  monthlyFeeZAR: { type: 'integer', minimum: 0 },
  setupFee: { type: 'integer', minimum: 0 },
  paymentHandledBy: { type: 'string', enum: Object.keys(PAYMENT_HANDLED_BY) },
  isDeveloperRequiredForInstallation: { type: 'boolean' }
};

const versionUpdateSchema = () => {
  const schema = {
    type: 'object',
    properties,
    additionalProperties: false
  };
  schema.properties.currency = {
    type: 'string',
    enum: ['AUD', 'CAD', 'EUR', 'GBP', 'USD', 'NZD', 'ZAR']
  };

  return schema;
};

export const validateUpdate = (params, values) =>
  validateForUI(
    versionUpdateSchema(),
    params,
    customValidateUrl(params, values)
  );

const customValidateUrl = (params, values) => {
  const errorArray = [];

  if (params.installUsername) {
    if (!params.installPassword) {
      errorArray.push({
        field: 'installPassword',
        message:
          'You need to provide an install password if you provided an install username'
      });
    }
  }

  if (values.installUsername) {
    if (!params.installUrl) {
      errorArray.push({
        field: 'installUrl',
        message:
          'You must provide an install URL if username / password provided'
      });
    }
  }

  if (params.installPassword) {
    if (!params.installUsername) {
      errorArray.push({
        field: 'installUsername',
        message:
          'You need to provide an install username if you provided an install password'
      });
    }
  }

  return errorArray;
};
